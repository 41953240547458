import { createSelector } from 'reselect';

const getUserData = state => state.profile.customer;
const getIsLoading = state => state.profile.isLoading;
const getIsProcessing = state => state.profile.isProcessing;
const getPermissions = state => state.profile.permissions;
const getBalance = state => state.profile.balance;
const getBalanceFree = state => state.profile.balance.free;
const getBalancePrepaid = state => state.profile.balance.prepaid;
const getUserDataStatus = state =>
  state.profile.customer && state.profile.customer.status;

export const selectIsAuthSuccess = createSelector(
  getUserData,
  getUserDataStatus,
  (userData, userDataStatus) => !!(userData && userDataStatus === '1'),
);

export const selectIsNotAuth = createSelector(
  getUserData,
  userData => userData === null,
);

export const selectIsValidationNeeded = createSelector(
  getUserData,
  getUserDataStatus,
  (userData, userDataStatus) => !!(userData && userDataStatus === '0'),
);

export const selectIsLoading = createSelector(
  getIsLoading,
  isLoading => isLoading,
);

export const selectIsProcessing = createSelector(
  getIsProcessing,
  isProcessing => isProcessing,
);

export const selectIsBalanceZero = createSelector(
  getBalance,
  balance => parseInt(balance.free) === 0 && parseInt(balance.prepaid) === 0,
);

export const selectBalance = createSelector(
  getBalanceFree,
  getBalancePrepaid,
  (free, prepaid) => [free, prepaid],
);

export const selectUserData = createSelector(
  getUserData,
  userData => userData,
);

export const selectPermissions = createSelector(
  getPermissions,
  permissions => permissions || {},
);
