import { ROUTES_PATHS } from 'config/constants';
/*
 * Auth
 * ====
 */
const LogOut = () => import('containers/Profile/LogOut');
const SignIn = () => import('containers/Profile/SignIn');
const SignUp = () => import('containers/Profile/SignUp');
const Validation = () => import('containers/Profile/Validation');
const SignUpConfirm = () => import('containers/Profile/SignUpConfirm');
const ResetPassword = () => import('containers/Profile/ResetPassword');
const ForgotPassword = () => import('containers/Profile/ForgotPassword');
/*
 * Dashboard
 * =========
 */
const IdentityCheck = () => import('containers/Dashboard/IdentityCheck');
const IdentityCheckReport = () =>
  import('containers/Dashboard/IdentityCheckReport');

export const routes = [
  {
    component: SignUp,
    title: 'Sign Up',
    path: ROUTES_PATHS.AUTH.SIGN_UP,
    isExact: true,
    type: 'public',
    pageType: 'authForm',
  },
  {
    component: SignIn,
    title: 'Sign In',
    path: ROUTES_PATHS.AUTH.SIGN_IN,
    isExact: true,
    type: 'public',
    pageType: 'authForm',
  },
  {
    component: Validation,
    title: 'Validation',
    path: ROUTES_PATHS.AUTH.VALIDATION,
    isExact: true,
    pageType: 'authForm',
    type: 'public',
  },

  {
    component: ForgotPassword,
    title: 'Forgot Password?',
    path: ROUTES_PATHS.AUTH.FORGOT_PASSWORD,
    pageType: 'authForm',
    isExact: true,
    type: 'public',
  },
  {
    component: ResetPassword,
    title: 'Reset Password',
    path: ROUTES_PATHS.AUTH.RESET_PASSWORD,
    pageType: 'authForm',
    isExact: true,
    type: 'public',
  },
  {
    component: IdentityCheck,
    title: 'Dashboard',
    path: ROUTES_PATHS.DASHBOARD.DASHBOARD,
    isExact: true,
    type: 'private',
    pageType: 'dashboard',
  },
  {
    component: IdentityCheck,
    title: 'BankLoop',
    path: ROUTES_PATHS.DASHBOARD.IDENTITY_CHECK,
    isExact: true,
    pageType: 'dashboard',
    type: 'private',
  },
  {
    component: IdentityCheckReport,
    title: 'BankLoop Report',
    path: [
      `${ROUTES_PATHS.DASHBOARD.IDENTITY_CHECK_REPORT}/:consentId`,
      `${ROUTES_PATHS.DASHBOARD.IDENTITY_CHECK_REPORT}/:consentId/:tabName`,
    ],
    isExact: true,
    pageType: 'dashboard',
    type: 'private',
  },
  {
    component: LogOut,
    title: 'Log Out',
    path: `${ROUTES_PATHS.AUTH.LOG_OUT}`,
    isExact: true,
    pageType: 'authForm',
    type: 'common',
  },
  {
    component: SignUpConfirm,
    title: 'Sign Up Confirm',
    path: `${ROUTES_PATHS.AUTH.SIGN_UP_CONFIRM}`,
    isExact: true,
    pageType: 'authForm',
    type: 'common',
  },
];

export default routes;
