import { actionTypes } from 'store/actions/header';
import produce from 'immer';

const initialState = {
  label: null,
  path: null,
  handleOnClick: null,
  data: null,
};

const setHeaderData = produce((draft, data) => {
  draft.data = data;
});

const setHeaderButton = produce(
  (draft, { label = null, path = null, handleOnClick = null }) => {
    draft.label = label;
    draft.path = path;
    draft.handleOnClick = handleOnClick;
  },
);

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_HEADER_BUTTON:
      return setHeaderButton(state, action.data);
    case actionTypes.SET_HEADER_DATA:
      return setHeaderData(state, action.data);
    default:
      return state;
  }
};

export default reducer;
