export const ROUTES_PATHS = {
  AUTH: {
    LOG_OUT: '/log_out',
    SIGN_IN: '/sign_in',
    SIGN_UP: '/sign_up',
    VALIDATION: '/validation',
    RESET_PASSWORD: '/set_password',
    FORGOT_PASSWORD: '/forgot_password',
    SIGN_UP_CONFIRM: '/finish_registration',
  },
  DASHBOARD: {
    DASHBOARD: '/dashboard',
    IDENTITY_CHECK_REPORT: '/identity_check/report',
    IDENTITY_CHECK: '/identity_check',
  },
};

export const ENDPOINTS = {
  CONSENT_CONFIRM: 'consent/confirm',
  CONSENT_REJECT: 'consent/reject',
  CONSENT: 'consent',
  CONSENTS: 'consents',
  LOG_OUT: 'signout',
  CONSENT_ADD: 'consent/add',
  SIGN_IN: 'signin',
  SIGN_UP: 'signup',
  CONSENT_ACCOUNT_TRANSACTIONS: 'analytics/consent/account_transactions',
  CONSENT_SUMMARY: 'analytics/consent_summary',
  CONSENT_ACCOUNT_DATA: 'consent_account_data',
  FORGOT_PASSWORD: 'reset_password',
  RESET_PASSWORD: 'confirm_reset_password',
  VALIDATION_SMS: 'confirm_signup',
  GET_BALANCE: 'consent/balance',
  FETCH_USER_PROFILE_DATA: 'personal_info',
  COMPANY_EMPLOYEE_GET_REG_INFO_BY_OTP: 'company/employee/reg_info_by_otp',
  COMPANY_EMPLOYEE_UPDATE_BY_OTP: 'company/employee/update_by_otp',
  CONSENT_RESEND: 'resend',
};

export const API_TYPE = {
  PROVIDERS: 'providers',
  CUSTOMER: 'customer',
  PSU: 'psu',
};

export default {
  ROUTES_PATHS,
  ENDPOINTS,
};
