import React from 'react';
import { Button as MuiButton } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import AutoRenewIcon from '../AutoRenewIcon';

const ButtonStyled = styled(
  ({ children, className, wide = false, customColor = 'normal', ...rest }) => (
    <MuiButton className={className} {...rest}>
      {children}
    </MuiButton>
  ),
)(({ customColor = 'normal', wide = false, disabled }) => {
  let bgHover = 'rgb(97,211,238)';
  let bg = 'inherit';
  let border = 'none';
  let borderHover = 'none';
  let color = 'white';
  let colorHover = 'white';
  let addStyles = {};

  switch (customColor) {
    case 'normal':
      bgHover = '#4DCFF0';
      bg = '#5ABDD7';
      color = 'white';
      break;
    case 'warning':
      bgHover = '#ff6e88';
      bg = '#ED6C78';
      color = 'white';
      colorHover = 'white';
      break;
    case 'low':
      bgHover = 'transparent';
      bg = 'transparent';
      border = '1px solid #5ABDD7';
      borderHover = '1px solid #B8B4B2';
      color = '#5ABDD7';
      colorHover = '#B8B4B2';
      break;
    case 'veryLow':
      bgHover = 'transparent';
      bg = 'transparent';
      border = '1px solid #B8B4B2';
      borderHover = '1px solid #4DCFF0';
      color = '#B8B4B2';
      colorHover = '#4DCFF0';
      break;
    default:
      bgHover = '#4DCFF0';
      bg = '#5ABDD7';
      color = 'white';
      break;
  }

  if (wide) {
    addStyles = {
      ...addStyles,
      width: '100%',
    };
  }

  if (disabled) {
    border = 'none';
  }

  return {
    width: 'max-content',
    height: '40px' /* Buttons height */,
    paddingLeft: '2rem',
    paddingRight: '2rem',
    boxShadow: 'none',
    border: border,
    backgroundColor: bg,
    color: color,
    '&:hover': {
      border: borderHover,
      color: colorHover,
      boxShadow: 'none',
      backgroundColor: bgHover,
    },
    '&:active': {
      border: borderHover,
      backgroundColor: bgHover,
      color: colorHover,
      boxShadow: 'none',
    },
    '&:focus': {
      border: borderHover,
      backgroundColor: bgHover,
      color: colorHover,
      boxShadow: 'none',
    },
    ...addStyles,
  };
});

const Button = ({
  variant = 'contained',
  color = 'primary',
  type = 'button',
  loading = false,
  disabled = false,
  children,
  ...rest
}) => (
  <ButtonStyled
    type={type}
    size={'large'}
    variant={variant}
    color={color}
    disabled={loading || !!disabled}
    {...rest}
  >
    <>
      {loading && <AutoRenewIcon />}
      {children}
    </>
  </ButtonStyled>
);

export default Button;
