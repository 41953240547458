import React, { useEffect } from 'react';
import {
  Box,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles, ThemeProvider } from '@material-ui/styles';
import SearchIcon from '@material-ui/icons/Search';
import Container from 'components/Container';
import Button from 'components/Button';
import LayoutsPage from 'layouts/Page';
import Modal from './Modal/Modal';
import Table from './Table/Table';
import reducer, { actions, initState } from 'components/Table/reducer';
import styles, { themeOverrides } from './styles';
import { useDataFetch } from './Table/useDataFetch';
import { useSelector, useDispatch } from 'react-redux';
import { formatPeriod } from 'components/Table/utils';
import {
  selectIsBalanceZero,
  selectPermissions,
} from '../../../store/selectors/profile';
import { ErrorWrapper } from '../../../@src/components';
import { onProfileBalanceUpdate } from 'store/actions/profile';
const title = 'BankLoop';

const useStyles = makeStyles(styles);

const IdentityCheck = props => {
  const classes = useStyles();
  const permissions = useSelector(selectPermissions);
  const dispatchGlobal = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [state, dispatch] = React.useReducer(reducer, {
    ...initState,
    sortBy: 'requested',
  });
  const handleModalOpen = isOpen => {
    setOpen(isOpen);
  };
  const userData = useSelector(state => state.profile.customer);
  const { id } = userData;
  const {
    setForceUpdate,
    setIsProcessing,
    setSearch,
    setStatus,
    setPeriod,
  } = actions;
  const isBalanceZero = useSelector(selectIsBalanceZero);

  useDataFetch({
    customerId: id,
    state,
    dispatch,
  });

  useEffect(() => {
    if (!permissions.see_my_ics) {
      setError('You do not have such permission');
    }
  }, [permissions, setError]);

  return (
    <LayoutsPage {...props}>
      <div className={classes.container}>
        <Box className={'topNavRows_wrapper'}>
          <Container fixed maxWidth="lg">
            <Grid
              className="identityCheck"
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="subtitle2">{title}</Typography>
              </Grid>
              <Grid item>
                <Grid container wrap="nowrap" spacing={2}>
                  <Grid item>
                    <Button
                      loading={state.isProcessing}
                      customColor={'low'}
                      onClick={() => {
                        dispatch(setIsProcessing(true));
                        dispatchGlobal(onProfileBalanceUpdate());
                        setTimeout(() => {
                          dispatch(setForceUpdate());
                        }, 2000);
                      }}
                    >
                      Refresh BankLoop Info
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color={'primary'}
                      disabled={isBalanceZero || !permissions.create_ics}
                      onClick={() => handleModalOpen(true)}
                    >
                      Add New Check
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
          <Container fixed maxWidth="lg">
            <Grid
              className="identityCheck_row_2"
              container
              justifyContent="flex-start"
              alignItems="flex-end"
            >
              <Grid item className={classes.textField}>
                <Box className={'search_field_root'}>
                  <TextField
                    id="searh_field"
                    label={[
                      <SearchIcon key={'search-icon'} />,
                      `Search by PSU`,
                    ]}
                    value={state.search}
                    onChange={e => dispatch(setSearch(e.target.value))}
                    margin="none"
                  />
                </Box>
              </Grid>
              <ThemeProvider theme={themeOverrides}>
                <Grid item className={classes.selectField}>
                  <FormControlLabel
                    label="Status:"
                    control={
                      <TextField
                        id="select_status"
                        select
                        value={state.status}
                        onChange={e => {
                          dispatch(setStatus(e.target.value));
                        }}
                        margin="none"
                      >
                        <MenuItem
                          key={'request-expired'}
                          value={'request-expired'}
                        >
                          Request Expired
                        </MenuItem>
                        <MenuItem
                          key={'consent-expired'}
                          value={'consent-expired'}
                        >
                          Consent Expired
                        </MenuItem>
                        <MenuItem key={'pending'} value={'pending'}>
                          Pending
                        </MenuItem>
                        <MenuItem key={'data-waiting'} value={'consent-waiting-for-data'}>
                          Data-Waiting
                        </MenuItem>
                        <MenuItem key={'rejected'} value={'rejected'}>
                          Rejected
                        </MenuItem>
                        <MenuItem
                          key={'consent-data-shared'}
                          value={'consent-data-shared'}
                        >
                          Confirmed
                        </MenuItem>
                        <MenuItem key={'revoked'} value={'revoked'}>
                          Revoked
                        </MenuItem>
                        <MenuItem key={'all'} value={'all'}>
                          All
                        </MenuItem>
                      </TextField>
                    }
                    labelPlacement="start"
                  />
                </Grid>
                <Grid item className={classes.selectField}>
                  <FormControlLabel
                    label="Period:"
                    control={
                      <TextField
                        id="select_period"
                        select
                        value={state.period}
                        onChange={e =>
                          dispatch(setPeriod(formatPeriod(e.target.value)))
                        }
                        margin="none"
                      >
                        <MenuItem key={'current_month'} value={'current_month'}>
                          Current Month
                        </MenuItem>
                        <MenuItem key={'current_week'} value={'current_week'}>
                          Current Week
                        </MenuItem>
                        <MenuItem key={'past_month'} value={'past_month'}>
                          Past Month
                        </MenuItem>
                        <MenuItem key={'all'} value={'all'}>
                          All
                        </MenuItem>
                      </TextField>
                    }
                    labelPlacement="start"
                  />
                </Grid>
              </ThemeProvider>
            </Grid>
          </Container>
        </Box>

        <Box className={'table_wrapper'}>
          <Container fixed maxWidth="lg">
            <Grid container justifyContent="space-between">
              <Box flexGrow={1}>
                {error ? (
                  <ErrorWrapper>{error}</ErrorWrapper>
                ) : (
                  <Table state={state} dispatch={dispatch} />
                )}
              </Box>
            </Grid>
          </Container>
        </Box>
      </div>

      <Modal
        setIsTableProcessing={() => dispatch(setIsProcessing(true))}
        setTableForceUpdate={() => dispatch(setForceUpdate())}
        open={open}
        title = "New BankLoop"
        handleClose={() => handleModalOpen(false)}
      />
    </LayoutsPage>
  );
};

export default IdentityCheck;
