import React from 'react';
import {
  H1,
  HeaderWrapperStyled,
  MainAreaWrapperStyled,
  RowsWrapper,
  RowTitle,
} from '../../components';
import Container from '../../components/Container';
import ButtonBack from '../../components/ButtonBack';
import LayoutsDashboard from '../../layouts/Dashboard';

const Wrapper = ({ title, children }) => {
  return (
    <LayoutsDashboard bgColor={'transparent'}>
      <HeaderWrapperStyled>
        <Container>
          <RowsWrapper>
            <RowTitle>
              <ButtonBack
                variant={'round'}
              />
              <H1>{title}</H1>
            </RowTitle>
          </RowsWrapper>
        </Container>
      </HeaderWrapperStyled>
      <MainAreaWrapperStyled>{children}</MainAreaWrapperStyled>
    </LayoutsDashboard>
  );
};

export default Wrapper;
