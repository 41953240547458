import { actionTypes } from 'store/actions/profile';

const initialState = {
  customer: null,
  balance: {
    free: null,
    prepaid: null,
  },
  providers: null,
  isLoading: true,
  isProcessing: false,
  permissions: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PROFILE_IS_PROCESSING:
      return { ...state, isProcessing: action.isProcessing };
    case actionTypes.SET_PROFILE_USER_DATA:
      return { ...state, ...action.data, isLoading: false };
    case actionTypes.SET_PROFILE_LOG_OUT:
      return { ...initialState, isLoading: false };
    case actionTypes.SET_PROFILE_IS_LOADING:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.SET_PROFILE_PROVIDERS:
      return { ...state, providers: action.data };
    case actionTypes.SET_PROFILE_BALANCE:
      return {
        ...state,
        balance: {
          free: action.data.free,
          prepaid: action.data.prepaid,
        },
      };
    default:
      return state;
  }
};

export default reducer;
