export const modalInitData = {
      id: null,
      customer_id: null,
      psu_email: null,
      exp_notification: null,
      business: null,
      data_visibility_lifetime_days: null,
      psu_account: {
        salutation: null,
        first_name: null,
        last_name: null,
        postcode: null,
        address_line_1: null,
        address_line_2: null,
        town: null,
        county: null,
        birth_date: null,
        phone_number: null,
        company_name: null,
        company_number: null,
      }
};

export const constants = {
  SET_MODAL_DATA: 'SET_MODAL_DATA'
};

export const actions = {
  setModalData: (data) => ({
    type: constants.SET_MODAL_DATA,
    payload: {data},
  })
};

function reducer(state = modalInitData, action) {
  switch (action.type) {
    case constants.SET_MODAL_DATA:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export default reducer;
