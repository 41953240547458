import React from 'react';
import CommonWrapper from './Common';
import endpoints from '../../config/endpoints';

const Sandbox = () => {
  return (
    <CommonWrapper
      type={'dev'}
      title={'Sandbox'}
      apiUrl={{
        apiGenerate: endpoints.dev.sandbox.generate,
        apiDetails: endpoints.dev.sandbox.details,
        apiDelete: endpoints.dev.sandbox.delete,
      }}
    />
  );
};

export default Sandbox;
