import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { H2, ModalStyled } from './components';
import RejectSvg from 'svg/Reject';

const BillingFail = () => {
  const history = useHistory();
  const location = useLocation();

  return (
    <>
      <ModalStyled
        open={true}
        onClose={() => history.push(location.params.prevPath)}
      >
        <div className={'inner-wrapper'}>
          <RejectSvg />
          <H2>Sorry, payment rejected</H2>
        </div>
      </ModalStyled>
    </>
  );
};

export default BillingFail;
