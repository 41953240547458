export default {
  modal: {
    fontFamily: ['Archivo', 'Helvetica', 'sans-serif'].join(','),
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    position: 'absolute',
    minWidth: '800px',
    minHeight: '500px',
    maxHeight: '90vh',
    overflow: 'auto',
    backgroundColor: '#fcfcfc',
    padding: 0,
    outline: 'none',
    display: 'grid',
    gridAutoFlow: 'row',
    gridTemplateRows: 'min-content 1fr',
    alignItems: 'center',
    borderRadius: '.25rem',
  },
  modalTitle: {
    width: '100%',
    fontSize: '1.25rem',
    fontWeight: 300,
    paddingLeft: '3rem',
    paddingRight: '3rem',
    paddingBottom: '2rem',
    paddingTop: '1rem',
    borderBottom: '1px solid #ccc',
  },
  onCloseButton: {
    height: '50px',
    width: '100%',
    zIndex: '100',
  },
  innerText: {
    fontSize: '1.25rem',
    fontWeight: 300,
    padding: '3rem',
  },
  modalBody: {
    fontSize: '1rem',
    fontWeight: 400,
    padding: '24px 100px 80px 100px',
  },
  closeBtn: {
    cursor: 'pointer',
    float: 'right',
    margin: '1rem',
    width: '20px',
    height: '20px',
  },
};
