import { capitalizeFirstLetter, isFunction } from 'utils/common';

const setPluralEnding = (min, propName) => {
  if (isFunction(propName)) {
    return propName(`${min > 1 ? 's' : ''}`);
  }
  return `${propName}${min > 1 ? 's' : ''}`;
};

const messagesPlain = {
  symbol: 'symbol',
  specialSymbolsList: `!"#$%&'()*+,-./:;<=>?@[\\]^_\`{|}~`,
  shouldContainsAtLeast: `should contains at least`,
  mustBe: 'must be',
  isRequired: `is required`,
  signUpSuccess: [`sign up success`, `please pass the validation`],
  signInSuccess: `sign in success`,
  forgotPasswordSuccess: [`instructions was send`, `please check your email`],
  resetPasswordSuccess: [
    `password was changed successfully`,
    `lease sign in using new password`,
  ],
  validationSuccess: `validation success`,
  consentConfirmSuccess: `consent data verified successfully`,
  consentConfirmFail: `consent data verification fail`,
  identityCheckSendSuccess: `consent sent to PSU successfully`,
  identityCheckSendFail: `error while sending consent to PSU`,
  identityCheckResendSuccess: `consent resent to PSU successfully`,
  identityCheckResendFail: `error while resending consent to PSU`,
};

const messages = {
  ...messagesPlain,
  commonUserName: `Must be valid name`,
  minNumValue: min => `Valuer must be higher than ${min}`,
  maxNumValue: max => `Valuer must be less than ${max}`,
  isRequiredWithAddMsg: message => message,
  isMatched: ({ to }) => {
    return `Should be equal to ${to}`;
  },
  phone: `${messagesPlain.mustBe} valid phone number`,
  birthDate: 'The date is invalid',
  checkBalance: 'You should have at least 3 credits',
  numbersOnly: `${messagesPlain.mustBe} numbers only`,
  lettersOnly: `${messagesPlain.mustBe} letters only`,
  latinLetters: `${messagesPlain.mustBe} latin letters only`,
  withoutSpecialCharacters: `${messagesPlain.mustBe} without special characters, i.e. ${messagesPlain.specialSymbolsList}`,
  minLength: min =>
    `Min length is ${min} ${setPluralEnding(min, messagesPlain.symbol)}`,
  maxLength: max =>
    `Max length is ${max} ${setPluralEnding(max, messagesPlain.symbol)}`,
  exactLength: length =>
    `Should be ${length} ${setPluralEnding(length, messagesPlain.symbol)}`,
  atLeast: ({ min, propName }) =>
    `${messagesPlain.shouldContainsAtLeast} ${min} ${setPluralEnding(
      min,
      propName,
    )}`,
  email: `${messagesPlain.mustBe} valid email`,
  specialSymbol: pluralEnding =>
    `special symbol${pluralEnding}, i.e. ${messagesPlain.specialSymbolsList}`,
  url: `${messagesPlain.mustBe} valid url`,
};

export const handleMessages = (message, argsObj) => {
  if (messages[message]) {
    switch (true) {
      case !!Array.isArray(messages[message]):
        return messages[message]
          .map(sentence => capitalizeFirstLetter(sentence))
          .join('. ')
          .toString();
      case !!isFunction(messages[message]):
        return capitalizeFirstLetter(messages[message](argsObj)).toString();
      default:
        return capitalizeFirstLetter(messages[message]).toString();
    }
  } else {
    throw new Error(`No such message called '${message}' inside $messages`);
  }
};
