const fontSizeHeader = '.8rem';
const fontSizeCell = '.9rem';
const fontSizeStatus = '.8rem';

export default {
  root: {
    marginTop: '0',
    border: 'none !important',
    fontFamily: ['Archivo', 'Helvetica', 'sans-serif'].join(','),
    color: '#3C3C3B',
    '& *': {},
    '& .rt-table': {
      '& .rt-thead': {
        '& .rt-resizable-header': {
          paddingTop: '1.5rem',
          paddingBottom: '1rem',
          fontSize: fontSizeHeader,
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          letterSpacing: '0.03em',
          textTransform: 'uppercase',
          color: '#B8B4B2',
          width: '100%',
          '& .rt-resizable-header-content': {
            width: '100%',
          },
          '& .textCell': {
            marginLeft: '1rem',
            textAlign: 'left',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            position: 'relative',
          },

          '& .sortIcon': {
            paddingLeft: '.5rem',
            visibility: 'hidden',
            position: 'absolute',
          },
          '&.-sort-asc': {
            boxShadow: 'none',
            '& .sortIcon.-up': {
              visibility: 'visible',
            },
          },
          '&.-sort-desc': {
            boxShadow: 'none',
            '& .sortIcon.-down': {
              visibility: 'visible',
            },
          },
          '&:first-child': {
            '& .textCell': {
              marginLeft: '0',
            },
            '&:after': {
              display: 'none',
            },
          },
          '&:after': {
            position: 'absolute',
            content: '" "',
            left: 0,
            top: 0,
            width: 0,
            height: '100%',
            backgroundColor: '#d9d9d9',
            pointerEvents: 'none',
          },
        },
        '&.-header': {
          boxShadow: 'none',
        },
        '& .rt-th, .rt-td': {
          border: 'none',
          justifyContent: 'center',
          alignItems: 'center',
          align: 'center',
        },
      },
      '& .rt-tbody': {
        '& .displayOnHover': {
          visibility: 'hidden',
        },
        '& .rt-tr-group': {
          border: 'none',
          position: 'relative',
          // '&:after': {
          //   position: 'absolute',
          //   top: 0,
          //   left: 0,
          //   right: 0,
          //   bottom: 0,
          //   height: '100%',
          //   width: '100%',
          //   content: '" "',
          //   zIndex: 1000,
          // },
          '&:hover': {
            '&:after': {
              border: '2px solid #5ABDD7',
            },
            '& .displayOnHover': {
              visibility: 'visible',
            },
            cursor: 'pointer',
            '& .viewReport': {
              visibility: 'visible',
            },
            '& .resend': {
              visibility: 'visible',
            },
            '& .duplicate': {
              visibility: 'visible',
            },
          },
          '& .rt-tr': {
            height: '3.5rem',
            alignItems: 'center',
            '& .rt-td': {
              textOverflow: 'ellipse',
              border: 'none',
              display: 'inline-flex',
              height: '100%',
              fontSize: fontSizeCell,
              fontWeight: 500,
              color: '#3C3C3B',
              justifyContent: 'flex-start',
              alignItems: 'center',
              textAlign: 'left',
              position: 'relative',
              '& .textCell': {
                marginLeft: '1rem',
                textAlign: 'left',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
              '& .textCell:hover': {
                overflow: 'visible',
                whiteSpace: 'normal',
                height:'auto',
            },
              '& .category, & .sub_category': {
                textTransform: 'capitalize',
              },
              '& .textCellBank': {
                textTransform: 'uppercase',
              },
              '&:first-child': {
                '& .textCell': {
                  marginLeft: '0',
                },
                '&:after': {
                  display: 'none',
                },
              },
              '&:after': {
                position: 'absolute',
                content: '" "',
                left: 0,
                top: 0,
                width: 0,
                height: '100%',
                backgroundColor: '#d9d9d9',
                pointerEvents: 'none',
              },
            },
            '&.-even': {
              backgroundColor: '#FAFAFA',
            },
          },
        },
      },
    },
    '& .pagination-bottom': {
      paddingTop: '1rem',
      border: 0,
      '& .-pagination': {
        fontSize: fontSizeStatus,
        letterSpacing: '0.03em',
        textTransform: 'uppercase',
        color: '#B8B4B2',
        boxShadow: 'none',
        border: 0,
        alignItems: 'center',
        justifyContent: 'center',
        '& .-pageJump input': {
          borderRadius: 0,
        },
        '& .-next, .-previous': {
          flex: '0 1',
          '& .-btn': {
            width: '30px',
            height: '30px',
            borderRadius: 0,
            textTransform: 'uppercase',
            backgroundColor: '#5ABDD7',
            color: '#fff',
            '&[disabled]:hover': {
              backgroundColor: '#EB5757',
            },
            '&:hover': {
              backgroundColor: '#EB5757',
            },
            '& .fa': {
              marginTop: '1px',
            },
          },
        },
        '& .-next': {
          '& .fa': {
            marginLeft: '2px',
          },
        },
        '& .-previous': {
          '& .fa': {
            marginRight: '2px',
          },
        },
        '& .-center': {
          flex: '0 1',
          flexWrap: 'nowrap',
        },
      },
    },
    '& .viewReport': {
      visibility: 'hidden',
      color: '#5ABDD7',
    },
    '& .resend': {
      visibility: 'hidden',
      color: '#5ABDD7',
    },
    '& .duplicate': {
      visibility: 'hidden',
      color: '#5ABDD7',
    },
    '& .status_wrapper': {
      width: '100%',
      textAlign: 'center',
    },
    '& .status': {
      display: 'inline-flex',
      width: '9.25rem',
      height: '1.75rem',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      textTransform: 'uppercase',
      fontSize: fontSizeStatus,
      letterSpacing: '0.05em',
      borderRadius: '0',
      color: 'white',
      '&.-consent-expired': {
        backgroundColor: '#B8B4B2',
      },
      '&.-request-expired': {
        backgroundColor: '#B8B4B2',
      },
      '&.-expired': {
        backgroundColor: '#B8B4B2',
      },
      '&.-rejected': {
        backgroundColor: '#EB5757',
      },
      '&.-pending': {
        backgroundColor: '#FAB733',
      },
      '&.-data-waiting': {
        backgroundColor: '#FAB733',
      },
      '&.-confirmed': {
        backgroundColor: '#5ABDD7',
      },
      '&.-consent-data-shared': {
        backgroundColor: '#5ABDD7',
      },
      '&.-revoked': {
        backgroundColor: '#5ABDD7',
      },
    },
  },
};
