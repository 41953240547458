import React from 'react';
import styled from 'styled-components';
import Emblem from 'svg/Emblem';
import clsx from 'clsx';

export const PreloaderSmall = styled(({ className }) => {
  return (
    <div className={clsx(className, 'component_ui_Preloader')}>
      <Emblem />
    </div>
  );
})`
  @keyframes __spin {
    to {
      transform: rotate(360deg);
    }
  }

  .assets_svg svg {
    width: 1.5rem;
    height: 1.5rem;
    animation: __spin 2s linear infinite;
  }
`;

export default PreloaderSmall;
