import styled from 'styled-components';
import ModalHeadless from '../../../../components/Modal/ModalHeadless';

export const ModalStyled = styled(ModalHeadless)`
  #modal-frame {
    min-height: 300px;
    min-width: 500px;
    #modal-body {
      padding: 2rem 2rem;
      align-items: center;
      justify-content: center;
      display: flex;
      width: 100%;
      height: 100%;
      .inner-wrapper {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;

export const H2 = styled.h2`
  text-align: center;
  font-size: 1.5rem;
  font-weight: 300;
  font-family: 'Archivo', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
`;
