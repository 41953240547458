export default {
  fontFamily: ['Archivo', 'Helvetica', 'sans-serif'].join(','),
  fontWeight: 500,
  htmlFontSize: 16,
  h1: {
    fontSize: '2rem',
  },
  h2: {
    fontSize: '1.7rem',
    lineHeight: '1.75rem',
    paddingBottom: '1rem',
  },
  h3: {
    fontSize: '1.5rem',
  },
  h4: {
    fontSize: '1.25rem',
  },
  h5: {},
  h6: {},
  subtitle1: {
    fontStyle: 'normal',
    fontSize: '1.1rem',
    lineHeight: '1rem',
    textTransform: 'none',
  },
  subtitle2: {
    fontSize: '1.5rem',
    fontWeight: 500,
  },
  body1: {
    fontWeight: 500,
    fontSize: '.9rem',
    lineHeight: '1.25rem',
  },
  body2: {
    fontWeight: 500,
  },
  button: {},
  caption: {},
  overline: {},
};
