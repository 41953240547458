import React from 'react';
import crossIcon from '../../assets/images/cross.png';
import { makeStyles, Modal as MuiModal } from '@material-ui/core';
import styles from './styles';
import styled, { css } from 'styled-components';

const useStyles = makeStyles(styles);

const ModalHeadless = ({
  open,
  onClose,
  title,
  children,
  className,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <MuiModal
      className={className}
      aria-labelledby="modal-title"
      aria-describedby="modal-body"
      open={open}
      onClose={onClose}
    >
      <ModalHeadlessStyled {...rest}>
        <div className={classes.modal} id="modal-frame">
          <div className={classes.onCloseButton} onClick={onClose}>
            <img
              alt="close-icon"
              src={crossIcon}
              className={classes.closeBtn}
            />
          </div>
          <div className={classes.modalBody} id="modal-body">
            {children}
          </div>
        </div>
      </ModalHeadlessStyled>
    </MuiModal>
  );
};

export default ModalHeadless;

const ModalHeadlessStyled = styled.div`
  & #modal-frame {
    ${({ small }) =>
      small &&
      css`
        min-width: 500px;
        max-width: 500px;
        min-height: 150px;
      `};

    & #modal-body {
      ${({ small }) =>
        small &&
        css`
          padding: 50px;
        `};
    }
  }
`;
