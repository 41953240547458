import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Form as FinalForm } from 'react-final-form';
import { sleep } from 'utils/common';
import Button from 'components/Button';
import formFieldsRenderer from './formFieldsRenderer';
import styles from './styles';
import styled from 'styled-components';
import { getInitValuesByFieldType } from './utils';
import _ from 'lodash';

const useStyles = makeStyles(styles);

const onSubmitDefault = async values => {
  await sleep(1500);
  console.debug(JSON.stringify(values, 0, 2));
};

const ButtonsDefault = ({ pristine, loading, submitting, submitBtnLabel }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.buttonsGroupWrapper} item xs={12}>
      <Button
        wide
        variant="contained"
        color="primary"
        type="submit"
        loading={loading || submitting}
        disabled={submitting || loading}
      >
        {submitBtnLabel || 'Submit'}
      </Button>
    </Grid>
  );
};

const Form = ({
  submitBtnLabel = 'Submit',
  handleOnSubmit,
  loading,
  fieldsMarkup,
  className,
  initValues,
  validate,
  buttons: Buttons = ButtonsDefault,
}) => {
  const classes = useStyles();

  const [formValues, setFormValues] = React.useState({
    showPassword: false,
  });

  const handleClickShowPassword = React.useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();
      setFormValues({ ...formValues, showPassword: !formValues.showPassword });
    },
    [formValues],
  );

  const handleMouseDownPassword = React.useCallback(event => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const finalInitValue = _.isEmpty(initValues)
    ? getInitValuesByFieldType(fieldsMarkup)
    : initValues


  return (
    <div className={className}>
      <FinalForm
        onSubmit={handleOnSubmit || onSubmitDefault}
        initialValues={finalInitValue}
        validate={validate}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form className={classes.root} onSubmit={handleSubmit} noValidate>
            <Grid
              className={'fieldsWrapper'}
              container
              alignItems="flex-start"
              spacing={0}
            >
              {formFieldsRenderer(fieldsMarkup, {
                handleSubmit,
                form,
                submitting,
                pristine,
                values,
                formValues,
                handlers: {
                  password: {
                    handleClickShowPassword,
                    handleMouseDownPassword,
                  },
                },
              })}
            </Grid>
            <Buttons
              loading={loading}
              submitBtnLabel={submitBtnLabel}
              submitting={submitting}
              pristine={pristine}
              values={values}
              form={form}
            />
          </form>
        )}
      />
    </div>
  );
};

const FormStyled = styled(Form)`
  form {
    .fieldsWrapper {
      display: grid;
      grid-auto-flow: row;
      grid-gap: 1.5rem;
      margin-bottom: 3rem;
    }
  }
`;

export default FormStyled;
