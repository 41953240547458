import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import config from 'config';

const PublicRoute = ({
  component: Component,
  isExact,
  isAuthSuccess,
  ...rest
}) => (
  <Route
    {...rest}
    exact={isExact}
    render={props =>
      isAuthSuccess === false ? (
        <Component {...props} {...rest} />
      ) : (
        <Redirect
          to={{
            pathname: config.defaultRedirectPrivateRoute,
          }}
        />
      )
    }
  />
);

export default PublicRoute;
