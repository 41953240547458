import React, { useMemo } from 'react';
import { Switch, useHistory, useParams } from 'react-router-dom';
import LayoutsDashboard from '@src/layouts/Dashboard';
import { parseRoutes } from '@src/utils/routes';
import { routesPathDashboard } from '@src/config/routesPath';
import Container from '../../components/Container';
import { useSelector } from 'react-redux';
import {
  selectIsAuthSuccess,
  selectPermissions,
} from '../../../store/selectors/profile';
import ButtonBack from '../../components/ButtonBack';
import {
  H1,
  HeaderWrapperStyled,
  MainAreaWrapperStyled,
  RowTitle,
  TabsWrapperStyled,
} from '../../components';
import Tab from '../../components/Tabs/Tab';
import Tabs from '../../components/Tabs/Tabs';

const Account = ({ nestedRoutes, path }) => {
  const { tab } = useParams();
  const history = useHistory();
  const isAuthSuccess = useSelector(selectIsAuthSuccess);
  const permissions = useSelector(selectPermissions);
  const rolesVisible =
    permissions.edit_branch_admins &&
    permissions.see_branch_admins &&
    permissions.edit_company_users &&
    permissions.see_branches &&
    permissions.edit_branches;
  const affordabilityVisible =
    permissions.can_see_affordability &&
    permissions.can_edit_affordability;

  const routes = useMemo(() => parseRoutes(nestedRoutes, isAuthSuccess, path), [
    nestedRoutes,
    path,
    isAuthSuccess,
  ]);

  return (
    <LayoutsDashboard appBarNavLinks={routesPathDashboard}>
      <HeaderWrapperStyled>
        <Container>
          <RowTitle>
            <ButtonBack
              handleOnBack={() => {
                history.push('/');
              }}
              variant={'round'}
            />
            <H1>Account</H1>
          </RowTitle>
        </Container>
      </HeaderWrapperStyled>
      <TabsWrapperStyled>
        <Container>
          <Tabs
            curTab={tab}
            history={history}
            routeUrlPrefix={path}
            type="route"
            defTab="general"
          >
            <Tab className='accountab' label="General" tabName="general" path="/general" />
            {permissions.top_up_branch_balance && (
              <Tab label="Billing" tabName="billing" path="/billing" />
            )}
            {permissions.crud_branch_users && permissions.see_branches && (
              <Tab label="Employees" tabName="employees" path="/employees" />
            )}
            {permissions.see_branches && (
              <Tab label="Branches" tabName="branches" path="/branches" />
            )}
            {rolesVisible && (
              <Tab label="Roles" tabName="roles" path="/roles" />
            )}
            {affordabilityVisible && (
              <Tab label="Affordability" tabName="affordabilities" path="/affordabilities" />
            )}
          </Tabs>
        </Container>
      </TabsWrapperStyled>
      <MainAreaWrapperStyled>
        <Switch>{routes}</Switch>
      </MainAreaWrapperStyled>
    </LayoutsDashboard>
  );
};

export default Account;
