import React from 'react';
import SvgFooterBaseline from 'svg/FooterBaseline';
import Container from '@src/components/Container';
import config from '@src/config';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const SvgFooterBaselineStyled = styled(SvgFooterBaseline)`
  display: flex;
  align-content: flex-end;
  justify-content: center;
`;

const ContentWrapperStyled = styled.div`
  padding: 1rem 0;
  font-size: 0.9rem;
`;

const Footer = ({ className }) => {
  const year = new Date().getFullYear();

  return (
    <footer className={className}>
      <Container>
        <ContentWrapperStyled>
          &copy; {year} • {config.siteTitle} •{' '}
          <Link to={'/terms-of-use'}>Terms of Use</Link> •{' '}
          <Link to={'/privacy-policy'}>Privacy Policy</Link>
        </ContentWrapperStyled>
      </Container>
      <SvgFooterBaselineStyled height={5} />
    </footer>
  );
};

const FooterStyled = styled(Footer)`
  height: 100px;
  flex-grow: 1;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  text-align: center;
  color: ${({ theme }) => theme.colors.fourth};
  background-color: ${({ bgColor }) => bgColor || 'white'};
`;

export default FooterStyled;
