import { API_TYPE, ENDPOINTS, ROUTES_PATHS } from './constants';

// eslint-disable-next-line no-unused-vars,no-undef
// var __DEV__ = process.env.NODE_ENV;
// eslint-disable-next-line no-unused-vars,no-undef
var __ENV__ = process.env['REACT_APP_ENV'] || 'qa';
// eslint-disable-next-line no-unused-vars,no-undef
var __IS_LOCAL__ = !!process.env['REACT_APP_IS_LOCAL'];

console.debug({
  __ENV__: __ENV__,
  __IS_LOCAL__: __IS_LOCAL__,
});

const backendUrls = {
  qa: 'https://dev-backend.open-banking-gateway.com',
  stage: 'https://stage-backend.open-banking-gateway.com',
  prod: 'https://prod-backend.trustloop.io',
  local: 'http://localhost:8080',
};

const getBackendUrl = () => {
  if (__IS_LOCAL__) {
    return backendUrls['local'];
  }
  return backendUrls[__ENV__];
};

let appConfig = {
  APP_TYPE: API_TYPE,
  backendUrls,
  ROUTES_PATHS,
  ENDPOINTS,
  siteTitle: 'trustloop',
  defaultRedirectPrivateRoute: ROUTES_PATHS.DASHBOARD.DASHBOARD,
  defaultRedirectPublicRoute: ROUTES_PATHS.AUTH.SIGN_IN,
  apiUrl: `${getBackendUrl()}/api/v0/customer`,
  redirectOnError: __ENV__ === 'qa',
  toastAutoCloseTimeout: 3000,
  externalUrl: `${getBackendUrl()}/api/v0/external-swagger`,
};

if (__ENV__ === 'stage') {
  appConfig = {
    ...appConfig,
    ...{},
  };
}

export default appConfig;
