const MuiFontSize1 = '.9rem';

export const themeOverrides = {
  overrides: {
    MuiFormControlLabel: {
      label: {
        fontSize: MuiFontSize1,
        paddingRight: '1rem',
        color: '#B8B4B2',
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          display: 'none',
        },
      },
    },
    MuiSelect: {
      root: {
        fontSize: MuiFontSize1,
      },
    },
    MuiPopover: {
      root: {
        '& [class*="MuiList"]': {
          '& [class*="MuiListItem"]': {
            fontSize: MuiFontSize1,
          },
        },
      },
    },
  },
};

export const styles = {
  container: {
    gridArea: 'main',
    backgroundColor: 'white',
    display: 'grid',
    height: '100%',
    overflowX: 'hidden',
    gridTemplateColumns: '100%',
    gridTemplateRows: `160px 1fr`,
    gridTemplateAreas: '"top" "content"',

    '& .topNavRows_wrapper': {
      borderBottom: '1px solid #e6e6e6',
      gridArea: 'top',
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingTop: '2rem',
      backgroundColor: '#fafafa',

      '& .identityCheck': {
        paddingTop: '1rem',
      },
      '& .identityCheck_row_2': {
        flexWrap: 'nowrap',
        paddingTop: '1rem',
      },
    },
    '& .table_wrapper': {
      backgroundColor: 'white',
      gridArea: 'content',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
  },
  textField: {
    width: '200px',
    '& .MuiInputBase-input': {
      paddingBottom: '8px',
    },
    '& .MuiInput-underline:before': {
      display: 'none',
    },
    '& .MuiInputBase-input.MuiInput-input': {
      marginTop: 0,
      marginBottom: 0,
      fontSize: MuiFontSize1,
    },
    '& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl': {
      color: '#B8B4B2',
      fontSize: MuiFontSize1,
    },
    '& .search_field_root': {
      '& .MuiFormLabel-root': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bottom: '32px',
      },
    },
  },
  selectField: {
    marginTop: 0,
    marginBottom: 0,
    width: '300px',
    '& .MuiPopover-root': {
      '& .MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button': {
        fontSize: MuiFontSize1,
      },
    },
    '& .MuiInputBase-input': {},
    '& .MuiInput-underline:before': {
      display: 'none',
    },
    '& .MuiInputBase-input.MuiInput-input': {
      width: '100px',
      marginTop: 0,
      marginBottom: 0,
      fontSize: MuiFontSize1,
    },
    '& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl, .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1': {
      paddingRight: '1rem',
      fontSize: MuiFontSize1,
    },
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  renewButton: {
    '& .autoRenewButton': {
      color: '#5ABDD7',
      fill: '#5ABDD7',
    },
    cursor: 'pointer',
    border: '2px solid #5ABDD7',
    height: '50px',
    padding: '0',
    minWidth: 'auto',
    width: '50px',
    backgroundColor: 'white',
    minHeight: '50px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 'none',
    transition: '300ms all',
    '&:hover': {
      '& .autoRenewButton': {
        color: '#badee2',
        fill: '#badee2',
      },
      border: '2px solid #badee2',
    },
  },
};

export default styles;
