import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PreloaderStyled from '../../../components/Preloader';
import styled from 'styled-components';
import ModalFormCrud, {
  modalCrudInitData,
} from '../../components/ModalFormCrud';
import Button from 'components/Button';
import { toast } from 'react-toastify';
import endpoints from '../../config/endpoints';
import { get, apiCallWrapper, del, post, postFormData } from '@src/utils/fetch';
import {
  setProfileBalance,
  setProfileLogOut,
  setProfileUserData,
} from '../../../store/actions/profile';
import { mapUserData } from '../../../store/middleware/profile';
import appConfig from '../../../config';
import { selectPermissions } from '../../../store/selectors/profile';
import ModalHeadless from '../../../components/Modal/ModalHeadless';
import {
  ControlButtonsSpaceBetweenWrapper,
  ControlButtonsWrapper,
  ModalConfirmWrapper,
} from '../../components';

const { apiUrl, ENDPOINTS } = appConfig;

const userFormFields = [
  {
    name: 'first_name',
    type: 'text',
    label: 'First Name',
  },
  {
    name: 'last_name',
    type: 'text',
    label: 'Last Name',
  },
  {
    name: 'email',
    type: 'email',
    label: 'Email',
  },
  {
    name: 'phone',
    type: 'phone',
    validationGroup: 'phone_notReq',
    label: 'Phone',
  },
];

const companyFormFields = [
  {
    name: 'name',
    type: 'text',
    label: 'Company Name',
    validationGroup: 'company_name',
  },
  {
    name: 'number',
    type: 'text',
    label: 'Company Number',
    validationGroup: 'company_number',
  },
  {
    name: 'employeesNumber',
    type: 'select',
    initValue: [
      { value: '1-5', label: '1-5' },
      { value: '6-20', label: '6-20' },
      { value: '21-100', label: '21-100' },
      { value: '101+', label: '101+' },
    ],
    label: 'Employee Number',
  },
  {
    name: 'sector',
    type: 'select',
    initValue: [
      { value: 'Retailer', label: 'Retailer' },
      { value: 'Mortgage Lender', label: 'Mortgage Lender' },
      { value: 'Retail Finance Lender', label: 'Retail Finance Lender' },
      { value: 'Other Lender', label: 'Other Lender' },
      { value: 'Mortgage Broker', label: 'Mortgage Broker' },
      { value: 'Retail Finance Broker', label: 'Retail Finance Broker' },
      { value: 'Other Broker', label: 'Other Broker' },
      { value: 'Online Gambling', label: 'Online Gambling' },
      { value: 'Rental Agency', label: 'Rental Agency' },
      { value: 'Employment', label: 'Employment' },
      { value: 'Government', label: 'Government' },
      { value: 'Utilities', label: 'Utilities' },
      { value: 'Insurance', label: 'Insurance' },
      { value: 'Other', label: 'Other' },
    ],
    label: 'Sector',
  },
  {
    name: 'postcode',
    type: 'text',
    label: 'Postcode',
    validationGroup: 'company_postcode',
  },
  {
    name: 'building',
    type: 'text',
    label: 'Building name or number',
    validationGroup: 'company_address',
  },
  {
    name: 'consent_exp_notify_days',
    type: 'number',
    label: 'BankLoop expiration reminder (days)',
    validationGroup: 'consent_exp_notify_days',
  }
];
const companyLogoFormFields = [
  {
    name: 'company_logo',
    type: 'image',
    label: 'Upload company logo',
  },
];

const emailInfoFields = [
  {
    name: 'email_subject',
    type: 'text',
    label: 'Email subject',
    validationGroup: 'email_subject',
  },
  {
    name: 'reason_of_request',
    type: 'area',
    label: 'Reason of request',
    validationGroup: 'reason_of_request',
  },
]

const updateUserInfoMapper = values => ({
  first_name: values.first_name,
  last_name: values.last_name,
  phone: values.phone,
  email: values.email,
});

const updateCompanyInfoMapper = values => {
  return {
    name: values.name,
    number: values.number,
    sector: values.sector,
    employeesNumber: values.employeesNumber,
    building: values.building,
    postcode: values.postcode,
    consent_exp_notify_days: Number.parseInt(values.consent_exp_notify_days)
  };
};
const updateCompanyLogoMapper = values => {
  console.log(values)
  return {
    company_logo: values.company_logo.item(0),
  };
};

const updateEmailInfoMapper = values => ({
  email_subject: values.email_subject,
  reason_of_request: values.reason_of_request,
})

export const useGetProfileData = (update, setIsProcessing) => {
  const dispatch = useDispatch();

  const urlFetchUserProfile = ENDPOINTS.FETCH_USER_PROFILE_DATA;
  const urlGetBalance = ENDPOINTS.GET_BALANCE;

  useEffect(() => {
    setIsProcessing(true);

    Promise.all([
      get([apiUrl, urlFetchUserProfile].join('/')),
      get([apiUrl, urlGetBalance].join('/')),
    ])
      .then(resp => {

        const [userData, balance] = resp;
        if (userData) {
          dispatch(setProfileUserData(mapUserData(userData)));
          dispatch(setProfileBalance(balance));
        }
      })
      .finally(() => {
        setIsProcessing(false);
      });
  }, [update, dispatch, setIsProcessing, urlGetBalance, urlFetchUserProfile]);
};

export const modalDeleteConfirmInitData = {
  isOpen: false,
  title: 'Are you sure?',
};

const ModalDeleteConfirm = styled(({ loading, onClose, handleOnDelete }) => {
  return (
    <ModalHeadless small open={true} onClose={onClose}>
      <ModalConfirmWrapper>
        <p>Are you sure? Your user will be permanently deleted.</p>
        <ControlButtonsSpaceBetweenWrapper>
          <ControlButtonsWrapper>
            <Button
              loading={loading}
              customColor={'warning'}
              onClick={() => {
                handleOnDelete();
              }}
            >
              Delete
            </Button>
          </ControlButtonsWrapper>
          <Button customColor={'low'} onClick={onClose}>
            Cancel
          </Button>
        </ControlButtonsSpaceBetweenWrapper>
      </ModalConfirmWrapper>
    </ModalHeadless>
  );
})`
  font-family: 'Archivo', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;

  .body-scrollable {
    display: grid;
    grid-gap: 1rem;
    grid-auto-flow: row;

    .buttons-wrapper {
      display: grid;
      grid-gap: 1rem;
      grid-auto-flow: column;
    }
  }
`;

const General = ({ className }) => {
  const userData = useSelector(state => state.profile.customer);
  const userPermissions = useSelector(state => state.profile.permissions);
  const [modalCurd, setModalCurd] = useState(modalCrudInitData);
  const [modalDeleteConfirm, setModalDeleteConfirm] = useState(
    modalDeleteConfirmInitData,
  );
  const dispatch = useDispatch();
  const [update, setUpdate] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const permissions = useSelector(selectPermissions);

  useGetProfileData(update, setIsProcessing);

  const handleOnFinish = () => {
    setUpdate(update => update + 1);
    setIsProcessing(false);
    setModalCurd({ ...modalCrudInitData });
  };
  const handleOnStart = () => {
    setIsProcessing(true);
  };
  const handleOnFail = () => {
    setIsProcessing(false);
  };

  const accountDelete = () => {
    const url = endpoints.customer.deleteAccount;

    apiCallWrapper({
      notify: true,
      onStart: () => {
        dispatch({ type: 'ACCOUNT_DELETE_START' });
        handleOnStart();
      },
      apiCall: () => del(url),
      onFinish: resp => {
        dispatch({ type: 'ACCOUNT_DELETE_FINISH', resp: resp });
        toast.success('Account deleted!');
        dispatch(setProfileLogOut());
      },
      onFail: error => {
        dispatch({ type: 'ACCOUNT_DELETE_FAIL', error });
        handleOnFail();
      },
    });
  };

  const updatePersonalInfo = values => {
    const url = endpoints.customer.updatePersonalInfo;

    apiCallWrapper({
      notify: true,
      onStart: () => {
        dispatch({ type: 'UPDATE_PERSONAL_INFO_START', values });
        handleOnStart();
      },
      apiCall: () => post(url, values),
      onFinish: resp => {
        dispatch({ type: 'UPDATE_PERSONAL_INFO_FINISH', resp: resp });
        handleOnFinish();
        toast.success('User data changed');
      },
      onFail: error => {
        dispatch({ type: 'UPDATE_PERSONAL_INFO_FAIL', error });
        handleOnFail();
      },
    });
  };

  const updateCompanyInfo = values => {
    const url = endpoints.customer.updateCompanyInfo;

    apiCallWrapper({
      notify: true,
      onStart: () => {
        dispatch({ type: 'UPDATE_COMPANY_INFO_START', values });
        handleOnStart();
      },
      apiCall: async () => await post(url, values),
      onFinish: resp => {
        dispatch({ type: 'UPDATE_COMPANY_INFO_FINISH', resp: resp });
        handleOnFinish();
        toast.success('User data changed');
      },
      onFail: error => {
        dispatch({ type: 'UPDATE_COMPANY_INFO_FAIL', error });
        handleOnFail();
      },
    });
  };

  const updateCompanyLogo = values => { 
    const url = endpoints.customer.updateCompanyLogo;

    apiCallWrapper({
      notify: true,
      onStart: () => {
        dispatch({ type: 'UPDATE_COMPANY_LOGO_START', values });
        handleOnStart();
      },
      apiCall: async () => await postFormData(url, values),
      onFinish: resp => {
        dispatch({ type: 'UPDATE_COMPANY_LOGO_FINISH', resp: resp });
        handleOnFinish();
        toast.success('Company logo changed');
      },
      onFail: error => {
        dispatch({ type: 'UPDATE_COMPANY_LOGO_FAIL', error });
        handleOnFail();
      },
    });
  };

  const updateEmailInfo = values => {
    const url = endpoints.customer.updateEmailInfo;

    apiCallWrapper({
      notify: true,
      onStart: () => handleOnStart(),
      apiCall: () => post(url, values),
      onFinish: () => {
        handleOnFinish();
        toast.success('Email info updated');
      },
      onFail: () => handleOnFail(),
    });
  };

  if (!userData || !userPermissions) {
    return (
      <div className={className}>
        <PreloaderStyled />
      </div>
    );
  }

  const {
    email,
    first_name,
    last_name,
    phone,
    company,
    branch,
    identity_check_params,
  } = userData;
  const {
    name: companyName,
    number: companyNumber,
    sector,
    employeesNumber,
    postcode,
    building,
    consent_exp_notify_days,
    company_logo_path,
  } = company;
  const { description: role } = userPermissions;
  const { name: branchName } = branch;
  const showCompanyInfo = companyName && companyNumber;

  if (isProcessing) {
    return (
      <div className={className}>
        <PreloaderStyled />
      </div>
    );
  }

  return (
    <div className={className}>
      <div className={'reportRowsBlock'}>
        <div className={'reportRowsWrapper general'}>
          <div className="row-title">
            <h2>User info</h2>
            <Button
              onClick={() => {
                setModalCurd({
                  ...modalCrudInitData,
                  isOpen: true,
                  onSubmit: values => {
                    updatePersonalInfo(updateUserInfoMapper(values));
                  },
                  formFieldsMarkup: userFormFields,
                  data: userData,
                  title: 'Edit personal info',
                });
              }}
            >
              Edit
            </Button>
          </div>
          <div className={'reportRowsInnerWrapper'}>
            <div className={'reportSingleRow'}>
              <div className={'label'}>First Name</div>
              <div className={'value'}>{first_name}</div>
            </div>
            <div className={'reportSingleRow'}>
              <div className={'label'}>Last Name</div>
              <div className={'value'}>{last_name}</div>
            </div>
            <div className={'reportSingleRow'}>
              <div className={'label'}>Email</div>
              <div className={'value'}>{email}</div>
            </div>
            <div className={'reportSingleRow'}>
              <div className={'label'}>Phone</div>
              <div className={'value'}>{phone}</div>
            </div>
          </div>
        </div>
        {
          showCompanyInfo &&
          <div className={'reportRowsWrapper company'}>
            <div className="row-title">
              <h2>Company info</h2>
              <Button
                disabled={!permissions.edit_company_info}
                onClick={() => {
                  setModalCurd({
                    ...modalCrudInitData,
                    isOpen: true,
                    onSubmit: values => {
                      updateCompanyInfo(updateCompanyInfoMapper(values));
                    },
                    formFieldsMarkup: companyFormFields,
                    data: company,
                    title: 'Edit company info',
                  });
                }}
              >
                Edit
              </Button>
            </div>
            <div className={'reportRowsInnerWrapper'}>
              <div className={'reportSingleRow'}>
                <div className={'label'}>Company Name</div>
                <div className={'value'}>{companyName}</div>
              </div>
              <div className={'reportSingleRow'}>
                <div className={'label'}>Company Number</div>
                <div className={'value'}>{companyNumber}</div>
              </div>
              <div className={'reportSingleRow'}>
                <div className={'label'}>Company Sector</div>
                <div className={'value'}>{sector}</div>
              </div>
              <div className={'reportSingleRow'}>
                <div className={'label'}>Number of Employees</div>
                <div className={'value'}>{employeesNumber}</div>
              </div>
              <div className={'reportSingleRow'}>
                <div className={'label'}>Postcode</div>
                <div className={'value'}>{postcode}</div>
              </div>
              <div className={'reportSingleRow'}>
                <div className={'label'}>Building Number</div>
                <div className={'value'}>{building}</div>
              </div>
              <div className={'reportSingleRow'}>
                <div className={'label'}>BankLoop expiration reminder (days)</div>
                <div className={'value'}>{consent_exp_notify_days}</div>
              </div>
            </div>
          </div>
        }
        <div className={'reportRowsWrapper permissions'}>
          <h2>User branch</h2>
          <div className={'reportRowsInnerWrapper'}>
            <div className={'reportSingleRow'}>
              <div className={'label'}>Branch</div>
              <div className={'value'}>{branchName}</div>
            </div>
            <div className={'reportSingleRow'}>
              <div className={'label'}>Role</div>
              <div className={'value'}>{role}</div>
            </div>
          </div>
        </div>
        {
          showCompanyInfo &&
          <div className={'reportRowsWrapper companylogo'}>
            <div className="row-title">
              <h2>Company Logo</h2>
              <Button
                disabled={!permissions.edit_company_info}
                onClick={() => {
                  setModalCurd({
                    ...modalCrudInitData,
                    isOpen: true,
                    onSubmit: values => {
                      updateCompanyLogo(updateCompanyLogoMapper(values));
                    },
                    formFieldsMarkup: companyLogoFormFields,
                    data: company_logo_path,
                    title: 'Edit company logo',
                  });
                }}
              >
                Edit
              </Button>
            </div>
            <div className={'reportRowsInnerWrapper'}>
              {
                company_logo_path &&
                <div className={'reportSingleRow'}>
                  <div className={'label'}>Company logo</div>
                  
                  <img src={company_logo_path} alt="company logo" height={50} width={100} className={'value'} />
                  
                  </div>
              }
            </div>
          </div>
        }
        <div className={'reportRowsWrapper emailInfo'}>
          <div className="row-title">
            <h2>Email management</h2>
            <Button
              disabled={!permissions.can_edit_company_email_settings}
              onClick={() => {
                setModalCurd({
                  ...modalCrudInitData,
                  isOpen: true,
                  onSubmit: values => {
                    updateEmailInfo(updateEmailInfoMapper(values));
                  },
                  formFieldsMarkup: emailInfoFields,
                  data: identity_check_params,
                  title: 'Edit email info',
                });
              }}
            >
              Edit
            </Button>
          </div>
          <div className={'reportRowsInnerWrapper'}>
            <div className={'reportTwoRows'}>
              <div className={'label'}>Email subject</div>
              <p>{identity_check_params.email_subject}</p>
            </div>
            <div className={'reportTwoRows'}>
              <div className={'label'}>Reason of request</div>
              <p>{identity_check_params.reason_of_request}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={'deleteButtonWrapper'}>
        <Button
          disabled={!permissions.delete_account}
          customColor={'veryLow'}
          onClick={() => {
            setModalDeleteConfirm(modalDeleteConfirm => ({
              ...modalDeleteConfirm,
              isOpen: true,
              title: 'Delete account',
            }));
          }}
        >
          Delete account
        </Button>
      </div>
      {modalCurd.isOpen && (
        <ModalFormCrud
          loading={isProcessing}
          {...modalCurd}
          onClose={() => setModalCurd({ isOpen: false })}
        />
      )}
      {modalDeleteConfirm.isOpen && (
        <ModalDeleteConfirm
          loading={isProcessing}
          {...modalDeleteConfirm}
          handleOnDelete={accountDelete}
          onClose={() => setModalDeleteConfirm({ isOpen: false })}
        />
      )}
    </div>
  );
};

const GeneralStyled = styled(General)`
  position: relative;
  min-height: 500px;

  .deleteButtonWrapper {
    margin-top: 2rem;
  }
  .reportRowsBlock {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: min-content;
  }

  h2 {
    font-size: 1.5rem;
    text-align: left;
    font-weight: 300;
  }

  .reportRowsWrapper {
    margin: 0 0 1rem;
    max-width: 500px;
    display: grid;
    grid-auto-flow: row;
    grid-gap: 0.5rem;
    grid-auto-rows: min-content;

    .row-title {
      align-items: center;
      display: grid;
      width: 100%;
      height: min-content;
      justify-content: space-between;
      grid-auto-flow: column;
      grid-auto-columns: max-content max-content;
    }

    .reportRowsInnerWrapper {
      display: grid;
      grid-auto-flow: row;
      grid-auto-rows: min-content;
      grid-gap: 0.5rem;
    }

    .reportSingleRow {
      border-bottom: 1px dotted #b8b4b2;
      height: min-content;
      display: grid;
      grid-auto-flow: column;
      justify-content: space-between;
      grid-auto-columns: min-content;
      .label {
        white-space: nowrap;
        color: #b8b4b2;
        background-color: white;
        position: relative;
        top: 1px;
      }
      .value {
        background-color: white;
        white-space: nowrap;
        position: relative;
        top: 1px;
      }
    }

    .reportTwoRows {
      margin-bottom: 1rem;

      .label {
        margin-bottom: 0.5rem;
        color: #b8b4b2;
      }
    }
  }
`;

export default GeneralStyled;
