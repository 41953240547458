import React from 'react';
// import { useEffect, useState } from 'react';
// import {
//   Switch,
//   useHistory,
//   useLocation,
//   useRouteMatch,
// } from 'react-router-dom';
// import clsx from 'clsx';
// import { apiCallWrapper, get } from '@src/utils/fetch';
// import endpoints from '@src/config/endpoints';
// import { parseRoutes } from '../../../utils/routes';
// import { useDispatch, useSelector } from 'react-redux';
// import BillingPrepare from './BillingPrepare';
import LayoutAccountStyled from '../../../layouts/Account';
// import CoolTable from '../../../../components/Table';
// import { BuyButton } from '../../../../components/Table/utils';

// const useGetPricingPlanes = setIsProcessing => {
//   const userId = useSelector(state => state.profile.customer.id);
//   const dispatch = useDispatch();
//   const history = useHistory();
//   const [state, setState] = useState();

//   useEffect(() => {
//     if (userId) {
//       apiCallWrapper({
//         apiCall: () =>
//           get(endpoints.customer.listOfPricingPlans + `/${userId}`),
//         onFinish: resp => {
//           setIsProcessing(false);
//           setState(resp.prices);
//         },
//         onFail: () => setIsProcessing(false),
//       });
//     }
//   }, [userId, setIsProcessing, history, dispatch]);

//   return state;
// };

// const tableColumns = [
//   {
//     Header: 'Pricing Plan Name',
//     accessor: 'pricing_plan_title',
//   },
//   {
//     Header: 'Credits',
//     accessor: 'credits',
//   },
//   {
//     Header: 'Amount',
//     accessor: 'amount',
//   },
//   {
//     Header: 'Currency',
//     accessor: 'currency',
//   },
//   {
//     accessor: 'buy',
//     width: 100,
//     Cell: () => (
//       <div className={'displayOnHover'}>
//         <BuyButton />
//       </div>
//     ),
//   },
// ];

// function addScript(id, src, callback) {
//   const search = document.getElementById(id);
//   if (!search) {
//     let s = document.createElement('script');
//     s.setAttribute('src', src);
//     s.setAttribute('id', id);
//     document.body.appendChild(s);
//     s.onload = callback;
//     return null;
//   }

//   if (search) {
//     callback();
//   }
// }

export const Billing = ({ className, nestedRoutes }) => {

  // Commenting as part of TL-214, we might bring
  // const { path } = useRouteMatch();
  // const location = useLocation();
  // const [modalData, setModalData] = useState({ isOpen: false, data: {} });
  // const [isProcessing, setIsProcessing] = useState(true);
  // const [isScriptProcessing, setIsScriptProcessing] = useState(true);
  // const { isOpen, data } = modalData;

  // // const tableData = useGetPricingPlanes(setIsProcessing);

  // useEffect(() => {
  //   const elId = 'stripe';

  //   addScript(elId, 'https://js.stripe.com/v3/', () => {
  //     setIsScriptProcessing(false);
  //   });

  //   return () => {};
  // }, []);

  // const isAuthSuccess = useSelector(
  //   state => state.profile.customer && state.profile.customer.status === '1',
  // );
  return (
    <div className={className}>
      <LayoutAccountStyled>
      <center><h2>Please contact <a href="mailto:info@trustloop.io">info@trustloop.io</a> to enquire about billing</h2></center>
      {/* <h2>Billing</h2>
        <div className={clsx('route-Dashboard-Billing')}>
          <CoolTable
            pageSize={5}
            loading={isProcessing || isScriptProcessing}
            data={tableData}
            columns={tableColumns}
            showPagination={false}
            getTrGroupProps={(state, rowInfo, column, instance) => {
              return {
                onClick: () => {
                  setIsProcessing(true);
                  rowInfo &&
                    rowInfo.original &&
                    setModalData({
                      data: rowInfo.original,
                      isOpen: true,
                    });
                },
              };
            }}
          />
        </div>
        <Switch location={{ ...location, params: { prevPath: path } }}>
          {parseRoutes(nestedRoutes, isAuthSuccess, path)}
        </Switch>
        {isOpen && (
          <BillingPrepare
            open={true}
            setIsProcessing={setIsProcessing}
            onClose={() =>
              setModalData(modalData => ({ ...modalData, isOpen: false }))
            }
            data={data}
          />
        )} */}
      </LayoutAccountStyled>
    </div>
  );
};

export default Billing;
