export default {
  root: {
    '& .Toastify__toast': {
      alignItems: 'center',
      padding: '1rem',
      '&.Toastify__toast--success': {
        backgroundColor: '#2EA077',
      },
      '&.Toastify__toast--error': {
        backgroundColor: '#EB5757',
      },
    },
    '& .assets_svg': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexFlow: 'row nowrap',
      height: '100%',
      width: '100%',
    },
    '& .-pseudo': {
      position: 'absolute',
      content: '""',
    },
    '&.-tCenter': {
      textAlign: 'center',
    },
    '&.-fxAiCenter': {
      alignItems: 'center',
    },
    '& .-pr1': {
      paddingRight: '10px',
    },
    '& .-pt0': {
      paddingTop: '0',
    },
    '& .-pl1': {
      paddingLeft: '10px',
    },
    '& .-dfx': {
      display: 'flex',
    },
  },
};
