export const actionTypes = {
  SET_HEADER_BUTTON: 'SET_HEADER_BUTTON',
  SET_HEADER_DATA: 'SET_HEADER_DATA',
};
export const setHeaderButton = data => {
  return {
    type: actionTypes.SET_HEADER_BUTTON,
    data,
  };
};
export const setHeaderData = data => {
  return {
    type: actionTypes.SET_HEADER_DATA,
    data,
  };
};
