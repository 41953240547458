import moment from 'moment';
import { handleMessages } from 'utils/messages';

const specialChars = '!"#$%&\'()*+,./:;<=>?@[\\]^_`{|}~\\-\\\\';

const atLeast = ({ min, propName }) => {
  let reg = new RegExp(null);

  switch (propName) {
    case 'n':
    case 'number':
      reg = /\d/g;
      break;
    case 'l':
    case 'lower':
    case 'lowercase':
      reg = /[a-z]/g;
      break;
    case 'u':
    case 'upper':
    case 'uppercase':
      reg = /[A-Z]/g;
      break;
    case 's':
    case 'special':
    case 'symbols':
    case 'special symbols':
      reg = new RegExp(`[${specialChars}]`, 'g');
      break;
    default:
      break;
  }
  return value => value.match(reg) && value.match(reg).length >= min;
};

/*
 * Validator conditions
 */
const validatorPatterns = {
  atLeast: atLeast,
  commonUserName: value =>
    /^[0-9a-zA-Z-"@',. ]+$/g.test(value) &&
    !/[#&<=>~;:[\]$^_`%()*+{|}\\?]/g.test(value),
  numbersOnly: value => /^[0-9 ]+$/g.test(value),
  lettersOnly: value => /^[\w ]+$/g.test(value),
  latinLetters: value => /^([A-Za-z0-9#&@<=>.,-~;!?:[\]$^_`'"%()*+{|}\s]*)$/g.test(value),
  phone: value => {
    return (
      !value ||
      /^\+?[0-9 ]+?\(?([0-9 ]+)\)?[-. ]?([0-9 ]+)[-. ]?([0-9 ]+)$/.test(value)
    );
  },
  checkBalance: balance => value => {
    return value === '90' ? balance >= 3 : true;
  },
  birthDate: value => {
    const year = value.slice(-4)
    const min = moment().format('YYYY')
    const max = moment().subtract(120, 'years').format('YYYY')
    return new RegExp(`^(((0[1-9]|[12][0-9]|30)[-/]?(0[13-9]|1[012])|31[-/]
?(0[13578]|1[02])|(0[1-9]|1[0-9]|2[0-8])[-/]?02)[-/]?[0-9]{4}|29[-/]
?02[-/]?([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579]
[26]|[02468][048]|0[0-9]|1[0-6])00))$` ,"g").test(value) && year <= min && year >= max
  },
  email: value =>
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value,
    ),
  withoutSpecialCharacters: value =>
    !RegExp(`[${specialChars}]`, 'g').test(value),
  minNumValue: min => value => value >= min,
  maxNumValue: max => value => value <= max,
  minLength: min => value => value.trim().length >= min,
  maxLength: max => value => value.trim().length <= max,
  exactLength: length => value => value.length === length,
  isMatched: ({ valueThatMustBeMatched }) => value => {
    return value.localeCompare(valueThatMustBeMatched);
  },
  isRequired: value => {
    return !!value;
  },
  isRequiredWithAddMsg: msg => value => {
    return !!value;
  },
  url: redirectFlow => value => {
    let matched = true;
    if (redirectFlow === 'dynamic' ) 
      matched = new RegExp(
      `^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$`,
      'g',
      ).test(value) 
    return matched
  }
};

/*
 * Used for handling proper messages. Can be using with translate
 */
export const applyValidatorPattern = (pattern, argsObj) => {
  let matchedPattern = validatorPatterns[pattern];

  if (!validatorPatterns[pattern]) {
    throw new Error(
      `There is no pattern called '${pattern}' inside $regexPattern`,
    );
  }

  if (argsObj) {
    matchedPattern = validatorPatterns[pattern](argsObj);
  }

  return value => {
    return matchedPattern(value || '')
      ? undefined
      : handleMessages(pattern, argsObj);
  };
};

const textDefaultLength = 50;
const textDefaulMintLength = 2;

/*
 * Validators grouped by types, for using with from fields for example
 */
const passMaxLength = 50;
const passMinLength = 8;

const numberMinLength = 12;

const passwordValidatorPattern = [
  applyValidatorPattern('isRequired'),
  applyValidatorPattern('atLeast', { min: 1, propName: 'number' }),
  applyValidatorPattern('atLeast', { min: 1, propName: 'lowercase' }),
  applyValidatorPattern('atLeast', { min: 1, propName: 'uppercase' }),
  applyValidatorPattern('atLeast', {
    min: 1,
    propName: 'special symbols',
  }),
  applyValidatorPattern('maxLength', passMaxLength),
  applyValidatorPattern('minLength', passMinLength),
];

export const validationByFieldType = {
  validationCode: [
    applyValidatorPattern('isRequired'),
    applyValidatorPattern('numbersOnly'),
    applyValidatorPattern('maxLength', 6),
    applyValidatorPattern('minLength', 6),
  ],
  phone: [
    applyValidatorPattern('isRequired'),
    applyValidatorPattern('phone'),
    applyValidatorPattern('minLength', numberMinLength),
  ],
  phone_notReq: [
    applyValidatorPattern('phone'),
    applyValidatorPattern('minLength', numberMinLength),
  ],
  birthDate: [
    applyValidatorPattern('isRequired'),
    applyValidatorPattern('minLength', 10),
    applyValidatorPattern('birthDate')
  ],
  email: [
    applyValidatorPattern('isRequired'),
    applyValidatorPattern('maxLength', textDefaultLength),
    applyValidatorPattern('email'),
    applyValidatorPattern('minLength', textDefaulMintLength),
  ],
  name: [
    applyValidatorPattern('isRequired'),
    applyValidatorPattern('commonUserName'),
    applyValidatorPattern('maxLength', textDefaultLength),
    applyValidatorPattern('minLength', textDefaulMintLength),
  ],
  first_name: [
    applyValidatorPattern('isRequired'),
    applyValidatorPattern('commonUserName'),
    applyValidatorPattern('maxLength', textDefaultLength),
    applyValidatorPattern('minLength', textDefaulMintLength),
  ],
  last_name: [
    applyValidatorPattern('isRequired'),
    applyValidatorPattern('commonUserName'),
    applyValidatorPattern('maxLength', textDefaultLength),
    applyValidatorPattern('minLength', textDefaulMintLength),
  ],
  company_name: [
    applyValidatorPattern('isRequired'),
    applyValidatorPattern('commonUserName'),
    applyValidatorPattern('maxLength', textDefaultLength),
    applyValidatorPattern('minLength', textDefaulMintLength),
  ],
  company_number: [
    applyValidatorPattern('isRequired'),
    applyValidatorPattern('maxLength', textDefaultLength),
    applyValidatorPattern('minLength', textDefaulMintLength),
    applyValidatorPattern('numbersOnly'),
  ],
  company_address: [
    applyValidatorPattern('isRequired'),
    applyValidatorPattern('maxLength', textDefaultLength),
    applyValidatorPattern('minLength', 1),
  ],
  company_postcode: [
    applyValidatorPattern('isRequired'),
    applyValidatorPattern('maxLength', textDefaultLength),
    applyValidatorPattern('minLength', textDefaulMintLength),
  ],
  consent_exp_notify_days: [
    applyValidatorPattern(
      'isRequiredWithAddMsg',
      'Required. Minimum value is 2 and maximum is 90 days',
    ),
    applyValidatorPattern('minNumValue', 2),
    applyValidatorPattern('maxNumValue', 90),
  ],
  title: [
    applyValidatorPattern('isRequired'),
    applyValidatorPattern('maxLength', textDefaultLength),
    applyValidatorPattern('minLength', textDefaulMintLength),
  ],
  address: [
    applyValidatorPattern('minLength', textDefaulMintLength),
    applyValidatorPattern('maxLength', textDefaultLength),
  ],
  email_subject: [
    applyValidatorPattern('isRequired'),
    applyValidatorPattern('minLength', 10),
    applyValidatorPattern('maxLength', 1000),
    applyValidatorPattern('latinLetters'),
  ],
  reason_of_request: [
    applyValidatorPattern('isRequired'),
    applyValidatorPattern('minLength', 50),
    applyValidatorPattern('maxLength', 1000),
    applyValidatorPattern('latinLetters'),
  ],
  description: [applyValidatorPattern('maxLength', 1000)],
  postcode: [applyValidatorPattern('maxLength', 50)],
  select: [applyValidatorPattern('isRequired')],
  passwordRequiredOnly: [applyValidatorPattern('isRequired')],
  password: passwordValidatorPattern,
};

export const rePasswordMatch = values => {
  const errors = {};
  if (!values.password) {
    errors.password = 'Required';
  }
  if (!values.re_password) {
    errors.re_password = 'Required';
  } else if (values.re_password !== values.password) {
    errors.re_password = 'Must match';
  }
  return errors;
};

export const composeValidators = validators => value =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export const validationCodeFromSmsRequire = value => {
  const reg = /^[0-9]{6}$/;
  return value && reg.test(value)
    ? undefined
    : 'Should be valid code from SMS (6 digits)';
};
