import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { selectUserData } from '../../../store/selectors/profile';
import { apiCallWrapper, post } from '@src/utils/fetch';
import endpoints from '../../config/endpoints';
import { useGetProfileData } from '../Account/General';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { GridRowsWrapper, SectionWrapper, Separator } from '../../components';
import Button from '../../../components/Button';

const urlValidationRegExp = new RegExp(
  `^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$`,
  'g',
);

const GridRowsWrapperStyled = styled(GridRowsWrapper)`
  max-width: 600px;
`;

const RedirectFlow = () => {
  const userData = useSelector(selectUserData);
  const [isProcessing, setIsProcessing] = useState(false);
  const [redirectFlow, setRedirectFlow] = useState(
    (userData && userData.company.redirect_flow) || 'none'
  );
  const [redirectUrl, setRedirectUrl] = useState(
    (userData && userData.company.static_redirect_url) || ''
  );
  const [validError, setValidError] = useState(null);
  const [update, setUpdate] = useState(0);

  useGetProfileData(update, setIsProcessing);

  const handleOnValid = input => {
    if (!input.match(urlValidationRegExp) || input.match(/\s/g)) {
      setValidError('Please enter valid Redirect URL');
      return false;
    }
    setValidError(null);
    return true;
  };

  const handleSubmit = () => {
    if(redirectFlow === 'static' && !redirectUrl.match(urlValidationRegExp)) {
      toast.error('Please enter a valid Redirect URL');
      setValidError('Please enter valid Redirect URL');
      return;
    }
    
    apiCallWrapper({
      onStart: () => setIsProcessing(true),
      apiCall: () => post(endpoints.dev.redirectFlow, {
        redirect_flow: redirectFlow,
        static_redirect_url: redirectUrl
      }),
      onAny: () => {
        setIsProcessing(false)
      },
      onFinish: () => {
        setIsProcessing(false);
        setUpdate(update => update + 1)
        toast.success('Redirect flow was updated successfully');
      },
      notify: true
    })
  };

  const redirectFlowOptions = [
    { 
      value: 'none',
      label: 'None',
      textContent: 'After the user completes the consent journey they will be redirected to their TrustLoop account login page. We recommend you provide a webhook URL so that we can notify you of any changes to the consent status without having to check the consent dashboard manually.'
    },
    {
      value: 'static',
      label: 'Static',
      textContent: 'After the user completes the consent journey they will be redirected to the static Redirect URL you must specify above. We will add query parameters to the Redirect URL that uniquely identify the consent request and its status.'
    },
    {
      value: 'dynamic',
      label: 'Dynamic',
      textContent: 'After the user completes the consent journey they will be redirected to the dynamic Redirect URL that you must specify in the payload our your API request. We will add query parameters to the Redirect URL that uniquely identify the consent request and its status.'
    }
  ]
  
  return (
    <>
      <Separator />
      <SectionWrapper>
        <GridRowsWrapperStyled gap='2rem'>
          <FormControl>
            <InputLabel id='redirect-select'>Redirect flow</InputLabel>
            <Select
              labelId='redirect-select'
              value={redirectFlow}
              onChange={(e) => {
                setRedirectFlow(e.target.value)
                setRedirectUrl('');
              }}
            >
              {
                redirectFlowOptions.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>{label}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
          {
            redirectFlow === 'static' &&
            <TextField 
              label='Redirect URL'
              value={redirectUrl}
              error={!!validError}
              helperText={validError}
              onChange={e => {
                handleOnValid(e.target.value);
                setRedirectUrl(e.target.value);
              }}
            />
          }
          {
            redirectFlowOptions.map(({ value, textContent }) => (
              redirectFlow === value && <p key={value}>{textContent}</p>
            ))
          }
          <Button
            loading={isProcessing}
            disabled={redirectFlow === 'static' && !redirectUrl}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </GridRowsWrapperStyled>
      </SectionWrapper>
    </>
  );
}

export default RedirectFlow;
