import React from 'react';
import clsx from 'clsx';
import styled, { css } from 'styled-components';

export const Index = ({ className, children }) => {
  return (
    <div className={clsx(className, 'component-Container')}>{children}</div>
  );
};

const ContainerStyled = styled(Index)(props => {
  const curBreakpoint = props.breakpoint || 'desktop';
  let finalStyle;

  switch (curBreakpoint) {
    case 'mobile':
      finalStyle = props.theme.layoutBreakpoints.mobile;
      break;
    case 'desktop':
    default:
      finalStyle = props.theme.layoutBreakpoints.desktop;
      break;
  }

  return css`
    ${finalStyle}
  `;
});

export default ContainerStyled;
