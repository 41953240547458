import React from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  ListItem,
  MenuItem,
  Select,
  List,
  TextField as TextFieldInput
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Field } from 'react-final-form';
import { TextField } from 'final-form-material-ui';
import InputMask from 'react-input-mask';
import Password from 'components/Form/fields/Password';
import { composeValidators, validationByFieldType } from 'utils/validators';
import Tooltip from '../Tooltip';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

const useStyle = makeStyles(theme => ({
  checkbox: {
    paddingTop: '1rem',
    '& .MuiTypography-body1': {},
    '& .MuiButtonBase-root': {},
  },
  categoryBox: {
    height: '250px',
    overflow: 'scroll'
  }
}));

export const formFieldsRenderer = (config, argsObj) => {
  return config.map((field, key) => {
    let customProps = {
      disabled: field.disabled || false,
    };
    let fieldId = `${field.id}`;
    if (field.name) {
      fieldId = `${field.name}`;
    }

    let validatorsList = [() => { }];

    const classes = useStyle();

    const gridItemParams = field.grid || {
      xs: 12,
      className: 'fieldInputCommon',
    };

    let isHidden = {
      display: field.hidden ? 'none' : 'block',
    };

    // const textAbove = <Grid item>{field.textAbove}</Grid>;

    if (field.displayIfCheckboxTrue) {
      if (argsObj.values[field.displayIfCheckboxTrue] !== true) {
        isHidden = {
          display: 'none',
        };
        customProps.validate = () => undefined;
      }
    }

    switch (field.type) {
      case 'area':
      case 'text':
      case 'number':
        if (validationByFieldType[field.name]) {
          validatorsList = [...validationByFieldType[field.name]];
        }

        if (validationByFieldType[field.validationGroup]) {
          validatorsList = [...validationByFieldType[field.validationGroup]];
        }

        if (field.validators) {
          validatorsList.push(...field.validators);
        }

        if (field.type === 'area') {
          customProps.minRows = 2;
          customProps.multiline = true;
        }

        if (field.type === 'number') {
          customProps.type = 'number';
          customProps.min = field.min;
          customProps.max = field.max;
        }

        return (
          <Grid style={isHidden} key={key} item {...gridItemParams}>
            <Field
              name={field.name}
              label={field.label}
              type={'text'}
              component={TextField}
              fullWidth={true}
              validate={composeValidators(validatorsList)}
              {...customProps}
            />
          </Grid>
        );
      case 'phone':
        return (
          <Grid key={key} item {...gridItemParams}>
            <Field
              name={field.name}
              validate={composeValidators(validationByFieldType['phone'])}
              {...customProps}
            >
              {props => {
                return (
                  <InputMask
                    mask="+44 99999 999 9999"
                    maskChar={null}
                    {...props.input}
                  >
                    {() =>
                      <TextFieldInput
                        fullWidth={true}
                        label='Phone'
                        error={!!(props.meta.touched && props.meta.error)}
                        helperText={props.meta.touched && props.meta.error}
                        {...props.input}
                      />}
                  </InputMask>
                )
              }}
            </Field>
          </Grid>
        );
      case 'birth_date':
        return (
          <Grid key={key} item {...gridItemParams}>
            <Field
              name={field.name}
              validate={composeValidators(validationByFieldType['birthDate'])}
              {...customProps}
            >
              {props => {
                return (
                  <InputMask
                    mask="99/99/9999"
                    maskChar={null}
                    {...props.input}
                  >
                    {() =>
                      <TextFieldInput
                        fullWidth={true}
                        label='Birth Date'
                        placeholder='DD/MM/YYYY'
                        error={!!(props.meta.touched && props.meta.error)}
                        helperText={props.meta.touched && props.meta.error}
                        {...props.input}
                      />}
                  </InputMask>
                )
              }}
            </Field>
          </Grid>
        );
      case 'email':
        return (
          <Grid key={key} item {...gridItemParams}>
            <Field
              name={`email`}
              label={field.label}
              type={'text'}
              component={TextField}
              fullWidth={true}
              validate={composeValidators(validationByFieldType['email'])}
              {...customProps}
            />
          </Grid>
        );
      case 'checkbox':
        return (
          <Grid
            key={key}
            style={isHidden}
            className={classes.checkbox}
            item
            {...gridItemParams}
          >
            <Field
              name={fieldId}
              type={'checkbox'}
              fullWidth={true}
              {...customProps}
            >
              {props => {
                return (
                  <FormControlLabel
                    label={field.label}
                    control={<Checkbox {...props.input} color="primary" />}
                    labelPlacement="end"
                  />
                );
              }}
            </Field>
          </Grid>
        );
      case 'password':
        return (
          <Grid key={key} item {...gridItemParams}>
            <Field
              name={`password`}
              label={`Password`}
              component={Password}
              formValues={argsObj.formValues}
              fullWidth={true}
              handlers={argsObj.handlers.password}
              isAdornment={true}
              validate={composeValidators(validationByFieldType['passwordRequiredOnly'])}
              {...customProps}
            />
          </Grid>
        );
      case 'select':
        if (field.validators) {
          validatorsList.push(...field.validators);
        }

        let renderFormField = (props) => (
          <FormControl
            {...props}
            className="form-field"
            fullWidth
            component="div"
            error={!!(props.meta.touched && props.meta.error)}
          >
            <InputLabel htmlFor={fieldId}>{field.label}</InputLabel>
            <Select {...props.input} id={fieldId} type={fieldId}>
              {field.initValue.map(v => (
                <MenuItem
                  key={v.label}
                  value={v.value}
                  data-role={`${fieldId}-item`}
                >
                  {v.label}
                </MenuItem>
              ))}
            </Select>
            {props.meta.touched && props.meta.error && (
              <FormHelperText id={`${fieldId}-error`}>
                {props.meta.error}
              </FormHelperText>
            )}
          </FormControl>
        );

        return (
          <Grid key={key} item {...gridItemParams}>
            <Field
              name={`${fieldId}`}
              fullWidth={true}
              component={Select}
              validate={composeValidators([
                ...validatorsList,
                ...validationByFieldType['select'],
              ])}
              {...customProps}
            >
              {props => {
                return (
                  field.tooltip ? (
                    <Tooltip
                      title={field.tooltip}
                      placement="top"
                    >
                      {renderFormField(props)}
                    </Tooltip>)
                    :
                    renderFormField(props)
                );
              }}
            </Field>
          </Grid>
        );
      case 'list':
        if (field.validators) {
          validatorsList.push(...field.validators);
        }

        let renderFormListField = (props) => (
          <>
            <InputLabel htmlFor={fieldId}>{field.label}</InputLabel>
            <List
              className={classes.categoryBox}
            >
              {field.initValue.map((item) => (
                <ListItem key={item.label} value={item.value}>
                  <ListItemText primary={item.label} />
                </ListItem>
              ))}
            </List>
          </>
        );
        return (
          <Grid key={key} item {...gridItemParams}>
            <Field
              name={`${fieldId}`}
              fullWidth={true}
              component={List}
              validate={composeValidators([
                ...validatorsList
              ])}
              {...customProps}
            >
              {props => {
                return (
                  field.tooltip ? (
                    <Tooltip
                      title={field.tooltip}
                      placement="top"
                    >
                      {renderFormListField(props)}
                    </Tooltip>)
                    :
                    renderFormListField(props)
                );
              }}
            </Field>
          </Grid>
        );

      case 'sublist':
        const [expandedItems, setExpandedItems] = React.useState([]);

        const handleClick = (index) => {
          const newExpandedItems = [...expandedItems];
          newExpandedItems[index] = !newExpandedItems[index];
          setExpandedItems(newExpandedItems);
        };
        if (field.validators) {
          validatorsList.push(...field.validators);
        }

        let renderFormSubListField = (props) => (
          <>
            <InputLabel htmlFor={fieldId}>{field.label}</InputLabel>
              <>
              {field.initValue.map((item, index) => (
                <List
                  component="nav"
                  aria-labelledby="nested-list-header"
                >
                  <ListItemButton onClick={() => handleClick(index)}>
                    <ListItemText primary={item.label} key={item.label}/>
                    {expandedItems[index] ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  {item && item.nestedValues &&
                  <Collapse in={expandedItems[index]} timeout="auto" unmountOnExit>
                    {item.nestedValues.map((nestedValue) => (
                      <ListItemButton key={nestedValue.group + nestedValue.key} sx={{ pl: 8 }}>
                        <ListItemText primary={nestedValue.key.replace("-","_")}/>
                      </ListItemButton>
                    ))}
                  </Collapse>
                  } 
                </List>
                       ))}
              </>
         
          </>
        );
        return (
          <Grid key={key} item {...gridItemParams}>
            <Field
              name={`${fieldId}`}
              fullWidth={true}
              component={List}
              validate={composeValidators([
                ...validatorsList
              ])}
              {...customProps}
            >
              {props => {
                return (
                  field.tooltip ? (
                    <Tooltip
                      title={field.tooltip}
                      placement="top"
                    >
                      {renderFormSubListField(props)}
                    </Tooltip>)
                    :
                    renderFormSubListField(props)
                );
              }}
            </Field>
          </Grid>
        );
      case 'password_re':
        return (
          <Grid key={key} container spacing={1}>
            <Grid item xs={12}>
              <Field
                name={`password`}
                label={`Password`}
                component={Password}
                formValues={argsObj.formValues}
                fullWidth={true}
                handlers={argsObj.handlers.password}
                validate={composeValidators(validationByFieldType['password'])}
                {...customProps}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name={`re_password`}
                label={`Repeat password`}
                component={Password}
                formValues={argsObj.formValues}
                handlers={argsObj.handlers.password}
                isAdornment={true}
                fullWidth={true}
                {...customProps}
              />
            </Grid>
          </Grid>
        );
      case 'image':
        return (
          <Grid key={key} item {...gridItemParams}>
            <Field name={field.name}>
              {
                ({ input: { value, onChange, ...input } }) => (
                  <input
                    {...input}
                    type="file"
                    id="file"
                    onChange={({ target }) => onChange(target.files)}
                  />
                )}
            </Field>
          </Grid>
        );
      case 'empty':
        return (
          <Grid key={key} item {...gridItemParams} />
        )
      default:
        throw new Error('renderFormFields: Type is not recognized');
    }
  });
};

export default formFieldsRenderer;
