export const actionTypes = {
  SET_PROFILE_SIGN_IN: 'SET_PROFILE_SIGN_IN',
  SET_PROFILE_SIGN_UP: 'SET_PROFILE_SIGN_UP',
  SET_PROFILE_IS_LOADING: 'SET_PROFILE_IS_LOADING',
  SET_PROFILE_IS_PROCESSING: 'SET_PROFILE_IS_PROCESSING',
  SET_PROFILE_AUTO_SIGN_IN: 'SET_PROFILE_AUTO_SIGN_IN',
  SET_PROFILE_USER_DATA: 'SET_PROFILE_USER_DATA',
  SET_PROFILE_LOG_OUT: 'SET_PROFILE_LOG_OUT',
  SET_PROFILE_VALIDATION_CODE_FROM_SMS: 'SET_PROFILE_VALIDATION_CODE_FROM_SMS',
  SET_PROFILE_FORGOT_PASSWORD: 'SET_PROFILE_FORGOT_PASSWORD',
  SET_PROFILE_RESET_PASSWORD: 'SET_PROFILE_RESET_PASSWORD',
  SET_PROFILE_BALANCE: 'SET_PROFILE_BALANCE',
  ON_PROFILE_BALANCE_UPDATE: 'ON_PROFILE_BALANCE_UPDATE',
  ON_PROFILE_PROVIDERS_FETCH: 'ON_PROFILE_PROVIDERS_FETCH',
  SET_PROFILE_PROVIDERS: 'SET_PROFILE_PROVIDERS',
};

export const setProfileIsLoading = isLoading => {
  return {
    type: actionTypes.SET_PROFILE_IS_LOADING,
    isLoading,
  };
};
export const setProfileIsProcessing = isProcessing => {
  return {
    type: actionTypes.SET_PROFILE_IS_PROCESSING,
    isProcessing,
  };
};

export const setProfileValidationCodeFromSms = data => {
  return {
    type: actionTypes.SET_PROFILE_VALIDATION_CODE_FROM_SMS,
    data,
  };
};
export const setProfileAutoSignIn = data => {
  return {
    type: actionTypes.SET_PROFILE_AUTO_SIGN_IN,
    data,
  };
};
export const setProfileSignIn = data => {
  return {
    type: actionTypes.SET_PROFILE_SIGN_IN,
    data,
  };
};
export const setProfileForgotPassword = data => {
  return {
    type: actionTypes.SET_PROFILE_FORGOT_PASSWORD,
    data,
  };
};
export const setProfileResetPassword = data => {
  return {
    type: actionTypes.SET_PROFILE_RESET_PASSWORD,
    data,
  };
};
export const setProfileSignUp = data => {
  return {
    type: actionTypes.SET_PROFILE_SIGN_UP,
    data,
  };
};

export const setProfileUserData = data => {
  return {
    type: actionTypes.SET_PROFILE_USER_DATA,
    data: data,
  };
};
export const setProfileBalance = data => {
  return {
    type: actionTypes.SET_PROFILE_BALANCE,
    data,
  };
};

export const onProfileBalanceUpdate = () => {
  return {
    type: actionTypes.ON_PROFILE_BALANCE_UPDATE,
  };
};

export const setProfileLogOut = () => {
  return {
    type: actionTypes.SET_PROFILE_LOG_OUT,
  };
};

export const setProfileProviders = data => {
  return {
    type: actionTypes.SET_PROFILE_PROVIDERS,
    data,
  };
};

export const onProfileProvidersFetch = () => {
  return {
    type: actionTypes.ON_PROFILE_PROVIDERS_FETCH,
  };
};
