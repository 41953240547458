import React from 'react';
import clsx from 'clsx';
import styled from 'styled-components';
import Container from '../../../components/Container';

const Tabs = React.memo(
  styled(
    ({
      className,
      routeUrlPrefix,
      type = 'container',
      defTab = 'general',
      children,
      history,
      curTab: argCurTab,
    }) => {
      const curTab = argCurTab || defTab;

      const tabs = React.Children.map(
        children,
        child =>
          child && (
            <child.type
              className={clsx(curTab === child.props.tabName && 'active')}
              onClick={() => {
                history.push(routeUrlPrefix + child.props.path, {
                  curTab: child.props.label,
                });
              }}
            >
              <div className={'tabInnerWrapper'}> {child.props.label}</div>
            </child.type>
          ),
      );

      return (
        <Container>
          <div className={clsx(className, 'tabsWrapper')}>{tabs}</div>
        </Container>
      );
    },
  )`
    margin-bottom: 0;
    margin-top: 1rem;
    display: flex;
    flex-flow: row nowrap;
    width: min-content;
    height: min-content;
    background-color: #f3f2f1;
  `,
  (prevProps, curProps) => {
    return prevProps.curTab === curProps.curTab;
  },
);

const TabsStyled = styled(Tabs)``;

export default TabsStyled;
