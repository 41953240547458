import React from 'react';
import clsx from 'clsx';
import styled from 'styled-components';
import Logo from '@src/assets/svg/Logo/Logo';

const LogoClickable = ({ className, history, width }) => (
  <button
    onClick={() => history.push('/')}
    className={clsx(className, 'assets-svg-LogoClickable')}
  >
    <Logo width={width} />
  </button>
);

const LogoClickableStyled = styled(LogoClickable)`
  background-color: initial;
  border: none;
  padding: 0;
  margin: 0;
`;

export default LogoClickableStyled;
