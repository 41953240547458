import palette from './palette';

export default {
  MuiSvgIcon: {
    colorAction: {
      color: 'white',
    },
  },
  MuiButtonBase: {
    root: {},
  },
  MuiButton: {
    disabled: {},
  },
  MuiContainer: {
    root: {},
  },
  MuiPaper: {
    root: {
      borderBottom: '1px solid rgba(224, 224, 224, .5)',
    },
    elevation1: {
      boxShadow: '0 10px 10px rgba(184, 180, 179, 0.15)',
    },
  },
  MuiInput: {
    root: {},
    underline: {
      '&:hover:not(.Mui-disabled):before': {
        borderBottomColor: '#5ABDD7',
      },
    },
  },
  MuiFormHelperText: {
    root: {
      position: 'absolute',
      bottom: 0,
      transform: 'translateY(100%)',
      right: 0,
      width: '100%',
      textAlign: 'right',
    },
  },
  MuiFormLabel: {
    root: {
      '&.Mui-focused': {
        color: `${palette.brand.blue}`,
      },
    },
  },
  MuiAppBar: {
    root: {
      boxShadow: '0px 10px 20px rgba(184, 180, 179, 0.15);',
    },
  },
  MuiTypography: {},
  MuiToolbar: {
    root: {},
    gutters: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
};
