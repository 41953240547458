import React from 'react';
import crossIcon from '../../assets/images/cross.png';
import { makeStyles, Modal as MuiModal } from '@material-ui/core';
import styles from './styles';
import clsx from 'clsx';
import styled, { css } from 'styled-components';

const useStyles = makeStyles(styles);

const Modal = ({ open, onClose, title, children, className, ...rest }) => {
  const classes = useStyles();

  return (
    <MuiModal
      aria-labelledby="modal-title"
      aria-describedby="modal-body"
      open={open}
      onClose={onClose}
    >
      <ModalStyled {...rest}>
        <div id="modal-root" className={clsx(classes.modal, className)}>
          <div className={'row-title'}>
            <div className={'close-icon'} onClick={onClose}>
              <img
                alt="close-icon"
                src={crossIcon}
                className={classes.closeBtn}
              />
            </div>
            <h2 className={classes.modalTitle} id="modal-title">
              <span className={classes.innerText}>{title}</span>
            </h2>
          </div>
          <div className={classes.modalBody} id="modal-body">
            {children}
          </div>
        </div>
      </ModalStyled>
    </MuiModal>
  );
};

const ModalStyled = styled.div`
  & #modal-root {
    ${({ small }) =>
      small &&
      css`
        min-width: 500px;
        max-width: 500px;
        min-height: 150px;
      `};

    & #modal-body {
      ${({ small }) =>
        small &&
        css`
          padding: 50px;
        `};
    }
  }
`;

export default Modal;
