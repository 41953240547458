import { useDispatch } from 'react-redux';
import { useRef, useState } from 'react';
import { apiCallWrapper, post } from '../../../utils/fetch';
import endpoints from '../../../config/endpoints';

export const useWebhook = (extSetData, extSetError, extSetIsProcessing) => {
  const dispatch = useDispatch();
  const thisRef = useRef({ isApiProcessing: false });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const handleApiCall = (reqBody = {}) => () =>
    post(endpoints.dev.webhook, reqBody);

  const handleIsLoading = isLoading => {
    dispatch({ type: 'START_WEBHOOK' });
    thisRef.current.isApiProcessing = isLoading;
    setLoading(isLoading);
    extSetIsProcessing && extSetIsProcessing(isLoading);
  };

  const handleIsError = error => {
    dispatch({ type: 'FAIL_WEBHOOK', error });
    handleIsLoading(false);
    setError(error);
    extSetError && extSetError(error);
    return error;
  };

  const handleSetData = data => {
    dispatch({ type: 'FINISH_WEBHOOK', data });
    handleIsLoading(false);
    setData(data);
    extSetData && extSetData(data);
    return data;
  };

  const triggerApiCall = reqBody => {
    if (!thisRef.current.isApiProcessing) {
      return apiCallWrapper({
        apiCall: handleApiCall(reqBody),
        onStart: handleIsLoading,
        onFinish: handleSetData,
        onFail: handleIsError,
      });
    }
  };

  return [triggerApiCall, data, loading, error];
};
