import React from 'react';
import ReactTable from 'react-table';
import { makeStyles } from '@material-ui/styles';
import styles from './styles';
import PreloaderStyled from '../Preloader';
import { nextText, prevText } from './utils';
import clsx from 'clsx';

const useStyles = makeStyles(styles);

const CoolTable = ({ pageSize = 10, showPagination = true, ...rest }) => {
  const classes = useStyles();
  const defPageSize = pageSize || 10;

  return (
    <ReactTable
      {...rest}
      manual={true}
      className={clsx(classes.root, 'table')}
      minRow={defPageSize}
      showPageSizeOptions={false}
      defaultPageSize={defPageSize}
      loadingText={<PreloaderStyled />}
      nextText={nextText()}
      previousText={prevText()}
      showPagination={showPagination}
    />
  );
};

export default CoolTable;
