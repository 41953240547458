import React, { useContext, useEffect } from 'react';
import {
  layoutSetPageIsProcessing,
  layoutSetPageParams,
  LayoutsTemplatesContext,
} from 'layouts';
import LayoutsAppDesktop from 'layouts/AppDesktop';

const Layout = ({ children, ...props }) => {
  const { dispatch } = useContext(LayoutsTemplatesContext);

  const { title, pageType } = props;

  useEffect(() => {
    dispatch(layoutSetPageParams({ pageTitle: title, pageType }));
    setTimeout(() => {
      dispatch(layoutSetPageIsProcessing(false));
    }, 500);
  }, [title, pageType, dispatch]);

  return <LayoutsAppDesktop>{children}</LayoutsAppDesktop>;
};

export default Layout;
