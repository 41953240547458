import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

const ControlledTooltip = ({ title, placement, children }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = bool => {
    setOpen(bool);
  };
  return (
    <Tooltip title={title} open={open} placement={placement}>
      <div
        style={{ width: '100%' }}
        onMouseEnter={() => handleOpen(true)}
        onMouseLeave={() => handleOpen(false)}
        onClick={() => handleOpen(false)}
      >
        {children}
      </div>
    </Tooltip>
  );
};

export default ControlledTooltip;
