import React from 'react';
import Container from '../../components/Container';
import styled from 'styled-components';
import Button from '../../components/Button';
import clsx from 'clsx';

export const ControlButtonsSpaceBetweenWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;
  width: 100%;
  justify-content: space-between;
  grid-auto-columns: max-content;
  padding-top: 2rem;
`;

export const ControlButtonsWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;
  grid-auto-columns: max-content;
`;

export const LayoutAccountButtonCreate = ({ setModalCrud, disabled }) => {
  return (
    <Button disabled={disabled} onClick={() => setModalCrud()}>
      + Add
    </Button>
  );
};

export const RowTitle = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  align-items: center;
  grid-gap: 1rem;
`;

export const RowsWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  h2 {
    margin: 1rem 0;
  }
`;

export const GridColumnsWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;
`;

export const Separator = styled.div`
  height: 1px;
  border-top: 1px solid #cccc;
  width: 100%;
`;

export const LinesWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 3rem;
  grid-auto-columns: 100%;
  grid-auto-rows: max-content;
`;

export const SectionWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 1rem;
  grid-auto-columns: minmax(max-content, 600px);
  grid-auto-rows: max-content;
`;

export const GridRowsWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-auto-columns: 100%;
  grid-gap: ${({ gap }) => gap || '1rem'};
  width: 100%;
`;

export const H1 = styled.h2`
  font-weight: 300;
  font-size: 2rem;
  width: max-content;
  font-family: 'Archivo', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
`;

export const MainAreaWrapper = ({ children, className }) => (
  <div className={clsx('MainAreaWrapper', className)}>
    <Container>{children}</Container>
  </div>
);

export const MainAreaWrapperStyled = styled(MainAreaWrapper)`
  background-color: transparent;
  padding-top: 2rem;
`;

export const TabsWrapperStyled = styled.div`
  background-color: #fafafa;
  border-bottom: 1px solid #cccccc;
  position: relative;
  z-index: 50;
`;

export const HeaderWrapperStyled = styled.div`
  background-color: #fafafa;
`;

export const RowWrapper = styled('div')`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: minmax(max-content, 600px);
  gap: 2rem;
`;

export const ModalConfirmWrapper = styled('div')`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1rem;
`;

export const ErrorWrapper = styled('div')`
  display: grid;
  grid-auto-flow: row;
  justify-content: center;
  align-items: center;
  grid-auto-columns: max-content;
  background-color: #ed6c78;
  color: white;
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
  margin: 5rem auto;
  max-width: max-content;
  padding: 2rem 5rem;
`;

export const FieldsWrapper = styled('div')`
  display: grid;
  grid-template: max-content max-content / minmax(max-content, 600px) max-content;
  grid-gap: 2rem;
`;

export const Content = styled('div')`
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: max-content;
  min-height: 300px;
  grid-gap: 3rem;
  position: relative;
`;

export const ContentCenter = styled.div`
  display: flex;
  width: max-content;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
`;

export const LinkHref = styled.a`
  &,
  &:visited,
  &:focus {
    color: ${({ color, active, theme }) => {
      if (active) {
        return theme.colors.primary;
      }

      if (color) {
        return theme.colors[color];
      }

      return theme.colors.primary;
    }};
    text-decoration: none;
    cursor: pointer;
    transition: all 300ms;
    font-weight: bold;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
    text-decoration: none;
  }
`;