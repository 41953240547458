import React from 'react';
import { Content } from '../../components';
import config from '../../config';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 0.5rem;
  max-width: 800px;

  ul {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 1rem;
    li {
      line-height: 150%;
    }
    padding-top: 0.5rem;
  }

  code {
    display: inline-block;
    background-color: #f7f7f7;
    padding: 0.25rem 1rem;
    font-size: 0.8rem;
    color: #333;
    border-radius: 0.25rem;
    margin: 0.25rem 0;
  }

  em {
    text-decoration: underline;
    font-style: normal;
  }

  a {
    text-decoration: underline;
  }

  strong {
    font-weight: bold;
    text-decoration: underline;
  }
`;

const VeryStrong = styled.strong`
  color: #ed6c78 !important;
`;

const GetStarted = () => {
  return (
    <Content>
      <Wrapper>
        <code>Sandbox API URL: https://sandbox.trustloop.io</code>
        <code>Production API URL: https://external.trustloop.io</code>
        <code><b>NOTE: Sandbox environment has the static data to get you familiarize with API. Once you are familiar with our API navigate to{' '}
            <Link to={'production'}>Developer → Production</Link> tab and create
            production <em>Client Id</em> and <em>Client Secret</em> 👩‍💻</b></code>
        <h2>Getting Started</h2>
        <ul style={{ paddingTop: 0 }}>
          <li>
          <u><b>Step 1: Generate client id & secret:</b></u><br/><br/>We provide Sandbox API environment for develoeprs to get familiar
            with our API. Navigate to{' '}
            <Link to={'sandbox'}>Developer → Sandbox</Link> tab and click{' '}
            <strong>Generate</strong> button to create <em>Client Id</em> and{' '}
            <em>Client Secret</em>. Please bear in mind that{' '}
            <VeryStrong>
              you will be able to see Secret value only once
            </VeryStrong>
            . Store it in a secure location with your <em>Client Id</em>.
          </li>
          <li>
            <u><b>Step 2: Obtain token:</b></u><br/> In order to do that, run <code>POST</code> request to{' '}
            <code>/token</code>
            endpoint with <em>Authorization header</em>{' '}
            <code>Basic + " " + base 64 encoded api_key:secret</code>
            <br />
            <em>Examples:</em>
            <ul>
              <li>
                Obtain token request:
                <code>
                  curl -X "POST" "https://sandbox.trustloop.io/token" \ -u ...
                </code>
              </li>
              <li>
                Response: <code>"access_token": "...", "exp": 1583919895</code>
              </li>
            </ul>
          </li>
          <li>
          <u><b>Step 3: Perform API calls:</b></u><br/>To perform calls to our API please specify <em>Authorization</em>{' '}
            header with the following <em>Authorization header value</em>:{' '}
            <code>Bearer + " " + token obtained from /token</code> call.
            <br />
            <em>Examples:</em>
            <ul>
              <li>
                Create consent request:
                <code>
                  curl -X "POST" "https://sandbox.trustloop.io/consent/add" \ -H
                  'Authorization: Bearer ... {'>'} \ -H 'Content-Type: text/plain;
                  charset=utf-8' \ -d $'{'{'}
                  "psu_email": "artem.kurginian@eteam.io", "exp_notification":
                  true
                  {'}'}'
                </code>
              </li>
              <li>
                Response:{' '}
                <code>
                  "consent_id": "75fc3ebc-60d2-4975-a2ed-d5054535e415"
                </code>
              </li>
              <li>
                Get current balance request:
                <code>
                  curl "https://sandbox.trustloop.io/consent/balance" \ -H
                  'Authorization: Bearer ...
                </code>
              </li>
              <li>
                Response: <code>"free": 0, "prepaid": 9996</code>
              </li>
            </ul>
          </li>
          <li>
          <u><b>Step 4: Swagger API page:</b></u><br/><br/>Now you are able to make API calls to create BankLoop and get data
            on them. For detailed information on each API endpoint please use
            our <a href={config.swaggerUiUrl}>Swagger UI</a> page.
          </li>
          <li>
          <u><b>Step 5: Production API calls:</b></u><br/><br/>Once you are familiar with our API navigate to{' '}
            <Link to={'production'}>Developer → Production</Link> tab and create
            production <em>Client Id</em> and <em>Client Secret</em> 👩‍💻 and follow the similar steps.
          </li>
        </ul>
      </Wrapper>
    </Content>
  );
};

export default GetStarted;
