import React from 'react';
import { useHistory } from 'react-router-dom';
import 'components/Table/initStyles.css';
import { ROUTES_PATHS } from 'config/constants';
import { handlePagination, onPageChange } from 'components/Table/utils';
import { actions } from 'components/Table/reducer';
import CoolTable from '../../../../components/Table';
import { ENDPOINTS } from 'config/constants';
import { apiCallWrapper, post } from '@src/utils/fetch';
import { toast } from 'react-toastify';
import { handleMessages } from 'utils/messages';
import appConfig from 'config';
import Modal from '../Modal/Modal';
import { modalInitData } from 'components/Modal/reducer';
import moment from 'moment';
import {
  cellCheckbox,
  expiredTimeFormat,
  renderTHeaderTitle,
} from 'components/Table/utils';
import clsx from 'clsx';
import { mapConsentStatus } from '../../../../utils/common';
import { isNil } from 'lodash';

export const defPageSize = 10;
const ReactTableComponent = ({ dispatch, state }) => {
  const history = useHistory();
  const { offset, isProcessing, data, total, sortOrder, sortBy } = state;
  const { setSort, setOffset, setIsProcessing, setForceUpdate } = actions;
  const [open, setOpen] = React.useState(false);
  const [modaldata, setModalData] = React.useState(modalInitData);
  const handleModalOpen = isOpen => {
    setOpen(isOpen);
  };
  const format_birth_date = (birth_date) => {
    return birth_date.split('-').reverse().join('/')
  }
  const validate_phone_number = (phone_number) => {
    if (phone_number.substring(0, 3) !== '+44') {
      phone_number = '+44' + phone_number
    }
    return phone_number
  }
  const mapPsuData = values => ({
    salutation: values.psu_account.salutation,
    first_name: values.psu_account.first_name,
    last_name: values.psu_account.last_name,
    birth_date: values.psu_account.birth_date ? format_birth_date(values.psu_account.birth_date) : values.psu_account.birth_date,
    phone_number: values.psu_account.phone_number ? validate_phone_number(values.psu_account.phone_number) : values.psu_account.phone_number,
    postcode: values.psu_account.postcode,
    address_line_1: values.psu_account.address_line_1,
    address_line_2: values.psu_account.address_line_2,
    town: values.psu_account.town,
    county: values.psu_account.county,
    email: values.psu_email,
    reminder: values.exp_notification,
  });

  function Action({
    row,
    columnProps: {
      rest: { onResendClick, onDuplicateClick, onReportClick }
    }
  }) {
    if (row.status === 'consent-data-shared') {
      return <span className="viewReport" onClick={onReportClick}>View Report</span>
    }
    if (row.status === 'pending') {
      return <span className="resend" onClick={onResendClick}>Resend</span>
    }
    if (row.status === 'request-expired' || row.status === 'consent-expired') {
      return <span className="duplicate" onClick={onDuplicateClick}>Duplicate</span>
    }
    return isNil
  }

  const columns = [
    {
      Header: () => renderTHeaderTitle({ title: 'PSU' }),
      accessor: 'psu',
      sortable: false,
      Cell: ({ value }) => {
        return <span className={'textCell'}>{value}</span>;
      },
    },
    {
      Header: () => renderTHeaderTitle({ title: 'NAME' }),
      accessor: 'name',
      sortable: false,
      Cell: ({ value }) => {
        return <span className={'textCell'}>{value}</span>;
      },
    },
    {
      Header: () => renderTHeaderTitle({ title: 'BANK' }),
      accessor: 'bank',
      width: 200,
      sortable: false,
      Cell: ({ row: { status }, value }) => {
        return (
          <span className={clsx('textCell', 'textCellBank')}>
            {status === 'consent-data-shared' ? value : '—'}
          </span>
        );
      },
    },
    {
      Header: () =>
        renderTHeaderTitle({
          title: 'STATUS',
          sort: true,
          myClassName: '-alignCenter',
          isText: false,
        }),
      accessor: 'status',
      Cell: ({ value: status }) => {
        return (
          <div className={'status_wrapper'}>
            <div className={`status -${mapConsentStatus(status)}`}>
              {mapConsentStatus(status)}
            </div>
          </div>
        );
      },
      width: 160,
    },
    {
      Header: () => renderTHeaderTitle({ title: 'REQUESTED', sort: true }),
      accessor: 'requested',
      Cell: ({ value }) => (
        <span className={'textCell'}>{moment(value).format('DD/MM/YYYY')}</span>
      ),
      width: 160,
    },
    {
      Header: () => renderTHeaderTitle({ title: 'EXPIRES AT', sort: true }),
      accessor: 'expires_at',
      Cell: ({ row: { status }, value }) => {
        return (
          <span className={'textCell'}>
            {value && status === 'consent-data-shared'
              ? expiredTimeFormat(value)
              : '—'}
          </span>
        );
      },
      width: 120,
    },
    {
      Header: () =>
        renderTHeaderTitle({
          title: 'Notify?',
          sort: false,
        }),
      accessor: 'exp_notification',
      Cell: cellCheckbox,
      width: 120,
      sortable: false,
    },
    {
      accessor: 'action',
      Cell: Action,
      getProps: (state, rowInfo, column, instance) => ({
        onResendClick: () =>
          apiCallWrapper({
            apiCall: () =>
              post([appConfig.apiUrl, ENDPOINTS.CONSENTS]
                .join('/')
                .concat([`/${rowInfo.original.consent_id}/` + ENDPOINTS.CONSENT_RESEND])
              ),
            onFinish: () => {
              toast.success(handleMessages('identityCheckResendSuccess'));
            },
            onFail: e => {
              console.error(
                'identityCheckResendFail',
              );
            }
          }),
        onDuplicateClick: () =>
          apiCallWrapper({
            apiCall: () =>
              post([appConfig.apiUrl, ENDPOINTS.CONSENT].join('/'), {
                psu_email: `${rowInfo.original.psu}`
              }),
            onStart: () => dispatch(setIsProcessing(true)),
            onFinish: resp => {
              if (resp) {
                setModalData(resp.consents[0])
                handleModalOpen(!open)
              }
              dispatch(actions.setIsProcessing(false));
            },
            onFail: () => {
              dispatch(actions.setIsProcessing(false));
              console.error('Error while trying to fetch consent data');
            },
          }),
        onReportClick: () =>
          history.push(
            `${ROUTES_PATHS.DASHBOARD.IDENTITY_CHECK_REPORT}/${rowInfo.original.consent_id}/summary`,
          )
      }),
      width: 120,
      sortable: false,
    },
  ];

  return (
    <div>
      <CoolTable
        pageSize={defPageSize}
        defaultSorted={[
          {
            id: 'requested',
            desc: true,
          },
        ]}
        loading={isProcessing}
        data={data}
        columns={columns}
        onPageChange={onPageChange({
          defPageSize,
          setOffset: offset => dispatch(setOffset(offset)),
        })}
        getPaginationProps={handlePagination({
          offset,
          sortOrder,
          defPageSize,
          sortBy,
          setSort: sort => dispatch(setSort(sort)),
          total,
        })}
      />
      <Modal
        setIsTableProcessing={() => dispatch(setIsProcessing(true))}
        setTableForceUpdate={() => dispatch(setForceUpdate())}
        data={mapPsuData(modaldata)}
        title="Duplicate BankLoop"
        open={open}
        handleClose={() => handleModalOpen(false)}
      />
    </div>
  );
};

export default React.memo(ReactTableComponent);
