import React from 'react';
import styled from 'styled-components';
import Wrapper from '../Wrapper';
import { LinkHref } from '../../../components';

const List = styled.ol`
  li {
    margin-bottom: 0.5em;
  }
`;

const Text = styled.p`
  margin: 0 0 1em;
`;

const TermsOfUse = () => {
  return (
    <Wrapper title={'Terms of Use for Partners'}>
      <Text>
        These are terms of use for TrustLoop, a trading name of OPEN B GATEWAY
        LIMITED (“us”, “we”, “our”), company number 11910674, a registered
        Account Information Service Provider FCA registration number 913211,
        registered office address: Langdon House C/O Bevan Buckland Llp, Langdon
        Road, Swansea, Wales, SA1 8QY.
      </Text>
      <Text>
        TrustLoop’s services (the “Services”) consists of the “Website” and “Web
        Application” hosted at trustloop.io made available by us, and any other
        related content and materials, however you access them, whether via the
        Site or via mobile or tablet app.
      </Text>
      <Text>
        If you have any questions, please email us at{' '}
        <LinkHref href="mailto:info@trustloop.io">info@trustloop.io</LinkHref>.
      </Text>
      <h2>An overview of our Service</h2>
      <Text>
        TrustLoop enables our customers (“Partners”) to gain read access to data
        made available under the UK’s Open Banking initiative
        (openbanking.org.uk) along with supplementary analysis data for a
        specific use case (together called “End User Data”). To this end,
        TrustLoop provides Partners with a “Partner Account” through which the
        Partner Services are delivered and administered.
      </Text>
      <Text>
        In addition, our Service enables the data subject (the “End User”) of
        any End User Data we hold to view the data and withdraw the consent they
        have previously granted us to access their data. To this end, TrustLoop
        provides End Users with an “End User Account” through which the End User
        Services are delivered and administered.
      </Text>
      <Text>
        The Service can be accessed either through our Web Portals or via our
        Application Programming Interface (the “API Service”).
      </Text>
      <Text>
        If you intend to become a Partner you must carefully read and accept
        these terms as part of the registration process.
      </Text>
      <Text>
        These Terms of Use, together with the Privacy Policy (collectively “the
        Terms”) form our legally binding agreement with our Partner (“you”) in
        relation to the Service (“the Agreement”). The Agreement sets out all
        the conditions on which we will provide our Service to you and applies
        to you whether you are visiting the Website or have registered to be a
        user of the Service.{' '}
      </Text>
      <List>
        <li>
          Partner agrees to maintain a Partner Account by registering at
          Trustloop’s website. By completing the registration, you confirm that
          you are authorised to represent Partner and accept the Terms on behalf
          of Partner and that you certify that all information you provide in
          the registration is accurate. You must have a valid email address
          registered with us at all times. We process information about you in
          accordance with our Privacy Policy. By using the Service, you consent
          to such processing and you warrant that all data provided by you is
          accurate and up to date.
        </li>
        <li>
          Partner shall implement processes and procedures to facilitate the
          prevention of unauthorised access to and use of the Service and shall
          notify TrustLoop as soon as possible after Partner becomes aware of
          any such unauthorized access and use. Partner will create a username
          and a password for the initial access to the Service, which thereafter
          will be required for any further use of the Service; Partner shall
          keep the username and the password confidential and prevent any
          unauthorised access thereto.
        </li>
        <li>
          We have the right to disable any Partner Account at any time and
          withhold provisions of the service if TrustLoop believes that the
          Services are being used in violation of these Terms.
        </li>
        <li>
          Partner is granted a non-exclusive, non-sublicensable license to the
          Service made available by TrustLoop following registration.
        </li>
        <li>
          You must not use any part of the Service for commercial purposes
          without obtaining a licence to do so from us.
        </li>
        <li>
          Fees for chargeable elements of the Service must be fully paid in
          advance of Services being delivered.
        </li>
        <li>
          Partner agrees to ensure that End Users shall receive a clear
          statement of the End User acknowledging and agreeing to the specific
          End User Data being retrieved by TrustLoop and the specific purpose
          for which the End User Data shall be used.
        </li>
        <li>
          Partner acknowledges it being the Data Controller under GDPR for its
          processing of any and all personal data of the End Users of the
          Service, and it being solely liable to fulfil any and all regulatory
          obligations in respect thereof. These obligations include, but are not
          limited to, End User Data, upon such data being delivered to Partner
          by TrustLoop.
        </li>
        <li>
          Partner agrees to it being solely responsible for the provision of the
          Service and undertakes to ensure that Partner always operates in
          compliance with applicable laws and regulations.
        </li>
        <li>
          In order to comply with applicable anti-money laundering rules, we may
          request that you provide us with certain information. By requesting a
          Service, you undertake to provide all such information as may be
          deemed necessary for such purposes, and you understand that failure to
          provide requested information may result that a requested Service
          cannot be performed or other consequences, depending on the situation.
          TrustLoop may come to monitor your usage of the Services for the
          purpose of complying with current anti-money laundering rules. The
          principles for such monitoring may vary from time to time and depend
          on the circumstances relating to the user, such as transaction
          patterns and similar.
        </li>
        <li>
          The Services are provided on an “as is” basis, without warranties of
          any kind. Partner is aware that TrustLoop does not have any control
          over the data maintained by financial institutions and acknowledges
          that TrustLoop makes no warranty in respect of the data quality of the
          End User Data, nor of it being accurate or complete.
        </li>
        <li>
          Partner assumes sole responsibility for results obtained from the use
          of the Service, and for conclusions drawn from such use. We shall have
          no liability for any damage caused by errors or omissions in any
          information, instructions or scripts provided to us by you or by Third
          Party Account providers in connection with the Service.
        </li>
        <li>
          TrustLoop shall use commercially reasonable endeavours to make the
          Services available 24 hours a day, seven days a week, except for
          routine and emergency maintenance. However, access to the Service is
          not guaranteed. We agree to provide the Service with reasonable skill
          and care and in accordance with all applicable laws and regulations.
          TrustLoop cannot be held accountable if the Services are unavailable
          or if any information or functionality does not work as expected,
          besides what follows on TrustLoop’s liability under applicable law.
          The performance of the Services can be dependent on services provided
          by a third party (e.g. mobile phone operator or internet provider). We
          are not responsible for such services provided by a third party,
        </li>
        <li>
          TrustLoop assumes no responsibility for any financial or other type of
          decision you take based on your use of the Services.
        </li>
        <li>
          The Service, in particular the API Service, is aimed at enabling a
          professional IT engineer to integrate with the Service. Where such
          qualified person encounters difficulties, Partner may contact
          TrustLoop and TrustLoop undertakes to use reasonable efforts to
          provide such technical support. Technical support may be provided at
          various service levels that may be subject to payment.
        </li>
        <li>
          Partner shall use industry standard firewall and virus protection
          programs to ensure that no malicious code, such as viruses, worms,
          time bombs, Trojan horses, are uploaded to the Service
        </li>
        <li>
          Partner agrees:
          <ul>
            <li>
              not to re-sell, license, rent, modify, distribute, copy,
              reproduce, transmit, publicly display, publicly perform, publish,
              adapt, edit or create derivative works from any part of the
              Service;
            </li>
            <li>
              not to access without authority, interfere with, damage or disrupt
              any part of the Service, equipment or network on which the Service
              is stored, software used in the provision of the Service, or
              equipment or network or software owned or used by any third party;
            </li>
            <li>
              not to reverse engineer or reverse compile any of the technology
              used to provide you with the Service, including but not limited
              to, any applications associated with the Service;
            </li>
            <li>
              not to do or say anything that would bring the Service or
              TrustLoop into disrepute.
            </li>
          </ul>
        </li>
        <li>
          Except for the rights granted to Partner in these Terms, all rights in
          and to the Services including but not limited to patents, trademarks,
          service marks, copyright, domain names, database rights, trade secrets
          and all rights or forms of protection of a similar effect to any of
          them which may subsist anywhere in the world, are reserved and shall
          remain with TrustLoop, and/or its licensors, as applicable.
        </li>
        <li>
          Partner undertakes to defend, indemnify, and hold harmless TrustLoop,
          its employees, contractors, directors, suppliers and representatives
          from all damages, losses, liabilities, claims, and costs and expenses,
          including all attorneys' fees, that arise from or relate to: (i)
          Partner’s use or misuse of, or access to, the Service; (ii) Partner’s
          violation of these Terms; (iii) any content, information or materials
          provided by Partner or Partner’s end users; (iv) disputes or issues
          Partner’s end users may have with respect to Partner or any of
          Partner’s products or services or content or with respect to any End
          User Data (including but not limited to the accuracy or completeness
          thereof); or (vi) infringement by Partner, or any third party using
          Partner’s account or identity in the Services, of any intellectual
          property or other right of any person or entity. TrustLoop reserves
          the right to assume the exclusive defense and control of any matter
          otherwise subject to indemnification by Partner, in which event
          Partner will assist and cooperate with us in asserting any available
          defenses.
        </li>
        <li>
          TrustLoop’s liability hereunder shall be limited to a total sum equal
          to the fees paid by Partner during the calendar year of the damaging
          event. Under no circumstances shall TrustLoop be liable for loss of
          profit or any other indirect damages or loss, including any liability
          of the other party to compensate a third party.
        </li>
        <li>
          Each party undertakes to maintain in absolute confidence any
          confidential information disclosed by the other party in connection
          with these Terms, use such confidential information solely for the
          purposes hereof and not disclose such confidential information to any
          third party. “Confidential information” shall mean any and all
          information, regardless of form of disclosure, including without
          limitation information on a party’s business or operations, the
          Services, financial information, trade secrets, personal data and
          other information. The receiving party shall not under any
          circumstances use a lesser degree of care than it uses for its own
          confidential information.
        </li>
        <li>
          TrustLoop reserves the right, in its sole discretion, to modify or
          replace these Terms of Use or the Privacy Policy, or change, suspend,
          or discontinue an aspect of the Services at any time by providing
          notice on our website, via e-mail or by another means of electronic
          communication. TrustLoop may also impose limits on certain features
          and services or restrict Partner’s access to parts or all of the
          Services without notice or liability. While TrustLoop will aim at
          providing appropriate notice of modifications, it is Partner’s
          responsibility to check these Terms periodically for changes.
          Partner’s continued use of the Services following notification of any
          changes to these Terms constitutes acceptance of those changes. If you
          continue to use the Services after an amendment of the Terms has come
          into force, you are deemed to have accepted the amended Terms.
        </li>
      </List>
    </Wrapper>
  );
};

export default TermsOfUse;
