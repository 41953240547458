import React from 'react';
import clsx from 'clsx';
import Logo from '@src/components/Logo/Logo';
import styled from 'styled-components';
import Container from '@src/components/Container';
import { useHistory } from 'react-router-dom';
import ProfileMenu from 'components/ProfileMenu';
import { useSelector } from 'react-redux';
import {
  selectIsAuthSuccess,
  selectIsBalanceZero,
} from '../../../store/selectors/profile';

const NotEnoughtMoneyPlate = styled.div`
  background-color: #ed6c78;
  color: white;
  height: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const AppBar = ({ className, navLinks = [], logoWidth }) => {
  const history = useHistory();
  const isAuthSuccess = useSelector(selectIsAuthSuccess);
  const isBalanceZero = useSelector(selectIsBalanceZero);

  return (
    <div className={clsx(className, 'component-AppBar')}>
      <Container className="inner-grid">
        <div className={clsx('logo-wrapper')}>
          <Logo history={history} width={logoWidth || 180} />
        </div>
        <div className={clsx('navLinks-wrapper')}></div>
        <div className={clsx('userDropdownMenu-wrapper')}>
          {isAuthSuccess && <ProfileMenu />}
        </div>
      </Container>
      {isBalanceZero && (
        <NotEnoughtMoneyPlate>
          Your balance is low. You can’t send new requests
        </NotEnoughtMoneyPlate>
      )}
    </div>
  );
};

const AppBarStyled = styled(AppBar)`
  background-color: ${props => props.theme.components.AppBar.bg};
  box-shadow: 0px 10px 20px rgba(184, 180, 179, 0.15);
  position: relative;
  z-index: 100;
  .inner-grid {
    display: grid;
    align-items: center;
    grid-template: 100px / minmax(min-content, 200px) 1fr minmax(
        min-content,
        200px
      );
  }
  .logo-wrapper {
    display: flex;
    justify-content: flex-start;
  }
  .navLinks-wrapper {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
  }
  .userDropdownMenu-wrapper {
    display: flex;
    justify-content: flex-end;
    text-align: right;
  }
`;

export default AppBarStyled;
