import moment from 'moment';

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const isFunction = functionToCheck => {
  return (
    functionToCheck && {}.toString.call(functionToCheck) === '[object Function]'
  );
};

export const getDatePeriodFromDateTime = dateTime => {
  return moment.duration(moment().diff(dateTime)).asHours();
};

export function domCopyInputContent(elem) {
  const copyText = elem.current.children.input;
  copyText.select();
  document.execCommand('copy');
}

export const mapConsentStatus = status => {
  switch (status) {
    case 'consent-data-shared':
      return 'confirmed';
    case 'consent-waiting-for-data':
      return 'data-waiting';
    case 'rejected':
      return 'rejected';
    case 'pending':
      return 'pending';
    case 'consent-expired':
      return 'consent-expired';
    case 'request-expired':
      return 'request-expired';
    case 'revoked':
      return 'revoked';
    default:
      return 'pending';
  }
};
