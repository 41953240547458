import React, { useMemo } from 'react';
import { routesPathDeveloper } from '../../config/routesPath';
import { Switch, useHistory, useParams } from 'react-router-dom';
import Container from '../../components/Container';
import ButtonBack from '../../components/ButtonBack';
import LayoutsDashboard from '../../layouts/Dashboard';
import {
  H1,
  HeaderWrapperStyled,
  MainAreaWrapperStyled,
  RowsWrapper,
  RowTitle,
  TabsWrapperStyled,
} from '../../components';
import Tabs from '../../components/Tabs/Tabs';
import Tab from '../../components/Tabs/Tab';
import { useSelector } from 'react-redux';
import {
  selectIsAuthSuccess,
  selectPermissions,
} from '../../../store/selectors/profile';
import { parseRoutes } from '../../utils/routes';
import config from '../../config';
import ErrorBoundary from '../../../components/ErrorBoundary';

const Developer = ({ nestedRoutes, path }) => {
  const { tab } = useParams();
  const history = useHistory();
  const isAuthSuccess = useSelector(selectIsAuthSuccess);
  const permissions = useSelector(selectPermissions);

  const routes = useMemo(() => parseRoutes(nestedRoutes, isAuthSuccess, path), [
    nestedRoutes,
    path,
    isAuthSuccess,
  ]);

  return (
    <ErrorBoundary>
      <LayoutsDashboard appBarNavLinks={routesPathDeveloper}>
        <HeaderWrapperStyled>
          <Container>
            <RowsWrapper>
              <RowTitle>
                <ButtonBack
                  handleOnBack={() => {
                    history.push('/');
                  }}
                  variant={'round'}
                />
                <H1>Developer Page</H1>
              </RowTitle>
              <div>
                <a href={config.swaggerUiUrl}>Go to API Documentation</a>
              </div>
            </RowsWrapper>
          </Container>
        </HeaderWrapperStyled>
        <TabsWrapperStyled>
          <Container>
            <Tabs
              curTab={tab}
              history={history}
              routeUrlPrefix={path}
              type="route"
              defTab="getStarted"
            >
              <Tab
                label="Getting Started"
                tabName="getStarted"
                path="/getStarted"
              />
              {permissions.crud_sandbox_keys && (
                <Tab label="Sandbox" tabName="sandbox" path="/sandbox" />
              )}
              {permissions.crud_real_data_keys && (
                <Tab
                  label="Production"
                  tabName="production"
                  path="/production"
                />
              )}
            </Tabs>
          </Container>
        </TabsWrapperStyled>
        <MainAreaWrapperStyled>
          <Switch>{routes}</Switch>
        </MainAreaWrapperStyled>
      </LayoutsDashboard>
    </ErrorBoundary>
  );
};

export default Developer;
