import React from 'react';
import RoutePrivate from '@src/components/RoutePrivate';
import { Redirect, Route } from 'react-router-dom';
import shortid from 'shortid';

const combinePath = (pathes, curPath) => {
  if (Array.isArray(pathes)) {
    return pathes.map(path => curPath + path);
  }

  return curPath + pathes;
};

export const parseRoutes = (routes, isAuthSuccess, curPath) => {
  let redirect = [];
  let renderedRoutes = routes.map((route, index) => {
    let RouteWrapper;
    const RouteComponent = route.component;

    switch (route.type) {
      case 'private':
        RouteWrapper = RoutePrivate;
        break;
      default:
        RouteWrapper = Route;
    }

    if (route.isRedirectToThisByDef) {
      redirect.push(
        <Redirect
          key={shortid.generate()}
          from={curPath}
          to={curPath + route.path}
          exact={true}
        />,
      );
    }

    return (
      <RouteWrapper
        key={shortid.generate()}
        isAuthSuccess={isAuthSuccess}
        title={route.title}
        component={props => (
          <RouteComponent
            {...props}
            path={curPath + route.path}
            title={route.title}
            nestedRoutes={route.nestedRoutes}
          />
        )}
        exact={route.exact}
        path={combinePath(route.matchPath || route.path, curPath)}
      />
    );
  });

  return [...renderedRoutes, ...redirect];
};
