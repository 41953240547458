export const getInitValuesByFieldType = fieldsMarkup =>
  fieldsMarkup.reduce((initValue, { name, type }) => {
    switch (type) {
      case 'text':
        return { ...initValue, [name]: '' };
      case 'number':
        return { ...initValue, [name]: 0 };
      case 'checkbox':
        return { ...initValue, [name]: false };
      default:
        return { ...initValue, [name]: '' };
    }
  }, {});
