import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import ModalFormCrud, {
  modalCrudInitData,
} from '../../components/ModalFormCrud';
import {
  GridColumnsWrapper,
  LayoutAccountButtonCreate,
} from '../../components';
import LayoutAccountStyled from '../../layouts/Account';
import CoolTable from '../../../components/Table';
import { CrudButton } from '../../../components/Table/utils';
import { apiCallWrapper, get, post } from '../../utils/fetch';
import { useDispatch, useSelector } from 'react-redux';
import endpoints from '../../config/endpoints';
import { toast } from 'react-toastify';
import Button from '../../../components/Button';
import ModalHeadless from '../../../components/Modal/ModalHeadless';
import Form from '../../../components/Form';
import { onProfileBalanceUpdate } from '../../../store/actions/profile';
import { selectPermissions } from '../../../store/selectors/profile';

const tableColumns = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Address',
    accessor: 'address',
  },
  {
    Header: 'Postcode',
    accessor: 'postcode',
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: 'Phone',
    accessor: 'phone',
  },
  {
    Header: 'Prepaid balance',
    accessor: 'prepaid_balance',
  },
  {
    Header: 'Free balance',
    accessor: 'free_balance',
  },
  {
    width: 120,
    Cell: () => (
      <div className={'displayOnHover'}>
        <CrudButton />
      </div>
    ),
  },
];

const branchesFormFields = [
  {
    name: 'name',
    type: 'text',
    label: 'Name',
  },
  {
    name: 'address',
    type: 'text',
    label: 'Address',
  },
  {
    name: 'postcode',
    type: 'text',
    label: 'Postcode',
  },
  {
    name: 'description',
    type: 'area',
    label: 'Description',
  },
  {
    name: 'phone',
    validationGroup: 'phone_notReq',
    type: 'phone',
    label: 'Phone',
  },
];

const getHqBranchId = branches => {
  const result = branches.find(branch => branch.name === 'hq');
  return result.id;
};

const mapBranchesForInputSelect = branches =>
  branches.map(branch => ({
    value: branch.id,
    label: branch.name,
  }));

export const useGetBranches = (update, setIsProcessing) => {
  const [branches, setBranches] = useState([undefined, undefined]);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const url = endpoints.customer.branchesRead;

  useEffect(() => {
    apiCallWrapper({
      onStart: () => {
        dispatch({ type: 'GET_BRANCHES_START' });
        setIsProcessing(true);
      },
      apiCall: () => get(url),
      onFinish: resp => {
        dispatch({ type: 'GET_BRANCHES_FINISH', payload: resp });
        setBranches([resp.branches, getHqBranchId(resp.branches)]);
        setIsProcessing(false);
      },
      onFail: error => {
        dispatch({ type: 'GET_BRANCHES_FAIL', error });
        setIsProcessing(false);
        setError(error);
      },
    });
  }, [update, dispatch, setIsProcessing, url]);

  return [branches, error];
};

const addBranch = ({
  values,
  handleOnFinish,
  handleOnFail,
  handleOnStart,
  dispatch,
}) => {
  const url = endpoints.customer.branchesAdd;

  return apiCallWrapper({
    onStart: () => {
      dispatch({ type: 'ADD_BRANCHES_START' });
      handleOnStart();
    },
    apiCall: () => post(url, values),
    onFinish: resp => {
      dispatch({ type: 'ADD_BRANCHES_FINISH', payload: resp });
      toast.success('New branch added successfully');
      handleOnFinish(resp);
    },
    notify: true,
    onFail: error => {
      dispatch({ type: 'ADD_BRANCHES_FAIL', error });
      handleOnFail();
    },
  });
};

const deleteBranch = ({
  values,
  handleOnFinish,
  handleOnFail,
  handleOnStart,
  dispatch,
}) => {
  const url = endpoints.customer.branchesDelete;

  return apiCallWrapper({
    onStart: () => {
      dispatch({ type: 'DELETE_BRANCHES_START' });
      handleOnStart();
    },
    apiCall: () => post(url, values),
    onFinish: resp => {
      dispatch({ type: 'DELETE_BRANCHES_FINISH', payload: resp });
      toast.success('Branch deleted successfully');
      handleOnFinish(resp);
    },
    notify: true,
    onFail: error => {
      dispatch({ type: 'DELETE_BRANCHES_FAIL', error });
      handleOnFail();
    },
  });
};

const valuesMapper = values => {
  return {
    id: values._original.id,
    postcode: values.postcode,
    name: values.name,
    description: values.description,
    phone: values.phone,
    address: values.address,
  };
};

const updateBranch = ({
  values,
  handleOnFinish,
  handleOnFail,
  handleOnStart,
  dispatch,
}) => {
  const url = endpoints.customer.branchesUpdate;

  return apiCallWrapper({
    onStart: () => {
      dispatch({ type: 'UPDATE_BRANCHES_START' });
      handleOnStart();
    },
    apiCall: () => post(url, values),
    onFinish: resp => {
      dispatch({ type: 'UPDATE_BRANCHES_FINISH', payload: resp });
      toast.success('Branch updated successfully');
      handleOnFinish(resp);
    },
    onFail: error => {
      dispatch({ type: 'UPDATE_BRANCHES_FAIL', error });
      handleOnFail();
    },
  });
};

const TransferCreditsModal = ({
  triggerBranchesUpdate,
  isModalOpen,
  setIsModalOpen,
  branches,
}) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const dispatch = useDispatch();

  const onTransferSuccess = () => {
    setIsProcessing(false);
    setIsModalOpen(false);
    toast.success('Credits were transferred');
    triggerBranchesUpdate();
    dispatch(onProfileBalanceUpdate());
  };

  const onTransferFail = () => {
    setIsProcessing(false);
  };

  const apiRequestBody = values => ({
    src_branch_id: values.branchFrom,
    dst_branch_id: values.branchTo,
    amount: parseInt(values.amount),
  });

  const handleTransferCredits = values => {
    apiCallWrapper({
      apiCall: () =>
        post(
          endpoints.customer.branchesTransferCredits,
          apiRequestBody(values),
        ),
      onStart: () => {
        dispatch({ type: 'TRANSFER_CREDITS_START', values });
        setIsProcessing(true);
      },
      onFail: onTransferFail,
      onFinish: onTransferSuccess,
    });
  };

  const selectBranches =
    (branches && mapBranchesForInputSelect(branches)) || [];

  const formFields = [
    {
      name: 'branchFrom',
      type: 'select',
      label: 'Branch from',
      initValue: selectBranches,
    },
    {
      name: 'branchTo',
      type: 'select',
      label: 'Branch to',
      initValue: selectBranches,
    },
    {
      name: 'amount',
      type: 'number',
      label: 'Amount',
    },
  ];

  return (
    <ModalHeadless
      title={'Title'}
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
    >
      <Form
        submitBtnLabel={'Send'}
        handleOnSubmit={handleTransferCredits}
        loading={isProcessing}
        fieldsMarkup={formFields}
        validate={values => {
          const error = {};
          if (values.branchFrom === values.branchTo) {
            error.branchFrom = 'Must not be the same as Branch To';
          }
          return error;
        }}
      />
    </ModalHeadless>
  );
};

const Branches = ({ className }) => {
  const [isTransferCreditsModalOpen, setIsTransferCreditsModalOpen] = useState(
    false,
  );
  const [modalCurd, setModalCurd] = useState(modalCrudInitData);
  const [update, setUpdate] = useState(0);
  const [isProcessing, setIsProcessing] = useState(true);
  const dispatch = useDispatch();
  const [[branches]] = useGetBranches(update, setIsProcessing);
  const permissions = useSelector(selectPermissions);

  const handleOnFinish = () => {
    setIsProcessing(false);
    setUpdate(update => update + 1);
    setModalCurd({ ...modalCrudInitData });
  };
  const handleOnStart = () => {
    setIsProcessing(true);
  };
  const handleOnFail = () => {
    setIsProcessing(false);
  };

  return (
    <div className={className}>
      <LayoutAccountStyled>
        <div className={clsx('row', 'row-top')}>
          <h2>Branches</h2>
          <GridColumnsWrapper>
            <Button
              disabled={!permissions.can_transfer_balance}
              onClick={() => setIsTransferCreditsModalOpen(true)}
            >
              Transfer Credits
            </Button>
            <LayoutAccountButtonCreate
              disabled={!permissions.edit_branches}
              setModalCrud={() =>
                setModalCurd({
                  ...modalCrudInitData,
                  isOpen: true,
                  onSubmit: values =>
                    addBranch({
                      values,
                      dispatch,
                      handleOnFail,
                      handleOnFinish,
                      handleOnStart,
                    }),
                  formFieldsMarkup: branchesFormFields,
                  title: 'Add new branch',
                })
              }
            />
          </GridColumnsWrapper>
        </div>
        <CoolTable
          pageSize={5}
          data={branches}
          loading={!branches || isProcessing}
          columns={tableColumns}
          showPagination={false}
          getTrGroupProps={(state, rowInfo, column, instance) => {
            if (!permissions.edit_branches) {
              return {
                onClick: () => {
                  toast.error('You do not have such permission');
                  return null;
                },
              };
            }

            return {
              onClick: () => {
                if (!rowInfo) return null;

                const { prepaid_balance: prepaidBalance } = rowInfo.row;
                const modalProps = {
                  deleteConfirmMessage: undefined,
                  isDeleteButtonActive: true,
                };

                if (prepaidBalance && prepaidBalance !== 0) {
                  modalProps.deleteConfirmMessage =
                    'You can’t delete this branch if its balance greater than 0';
                  modalProps.isDeleteButtonActive = false;
                }

                setModalCurd({
                  isOpen: true,
                  onSubmit: values =>
                    updateBranch({
                      values: valuesMapper(values),
                      dispatch,
                      handleOnFail,
                      handleOnFinish,
                      handleOnStart,
                    }),
                  onDelete: values => {
                    return deleteBranch({
                      values: { id: values._original.id },
                      dispatch,
                      handleOnFail,
                      handleOnFinish,
                      handleOnStart,
                    });
                  },
                  data: rowInfo.row,
                  formFieldsMarkup: branchesFormFields,
                  title: rowInfo.row.name,
                  isDelete: true,
                  ...modalProps,
                });
              },
            };
          }}
        />
        {modalCurd.isOpen && (
          <ModalFormCrud
            loading={isProcessing}
            onClose={() => setModalCurd({ isOpen: false })}
            {...modalCurd}
          />
        )}
        <TransferCreditsModal
          branches={branches}
          triggerBranchesUpdate={() => setUpdate(update => update + 1)}
          setIsModalOpen={setIsTransferCreditsModalOpen}
          isModalOpen={isTransferCreditsModalOpen}
        />
      </LayoutAccountStyled>
    </div>
  );
};

export default Branches;
