export default {
  modal: {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    position: 'absolute',
    maxWidth: '900px',
    minWidth: '500px',
    backgroundColor: '#fcfcfc',
    padding: 0,
    outline: 'none',
    '& #modal-title': {
      width: '100%',
      paddingLeft: '3rem',
      paddingRight: '3rem',
      '& .innerText': {
        padding: '3rem',
        fontSize: '1.5rem',
        fontWeight: 300,
      },
      paddingBottom: '2rem',
      paddingTop: '1rem',
      borderBottom: '1px solid #ccc',
    },
    '& #modal-body': {
      padding: '24px 100px  80px 100px ',
    },

    '& .closeBtn': {
      cursor: 'pointer',
      float: 'right',
      margin: '1rem',
      width: '20px',
      height: '20px',
    },
  },
};
