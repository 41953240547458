import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import { FormControlLabel, MenuItem, TextField } from '@material-ui/core';
import ModalFormCrud, {
  modalCrudInitData,
} from '../../components/ModalFormCrud';
import { ErrorWrapper, LayoutAccountButtonCreate } from '../../components';
import LayoutAccountStyled from '../../layouts/Account';
import CoolTable from '../../../components/Table';
import { CrudButton } from '../../../components/Table/utils';
import { useGetBranches } from './Branches';
import endpoints from '../../config/endpoints';
import { apiCallWrapper, post } from '../../utils/fetch';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useGetRoles } from './Roles';
import _ from 'lodash';
import { selectPermissions } from '../../../store/selectors/profile';

const FilterBranches = styled(({ className, value, branches, onChange }) => (
  <FormControlLabel
    className={clsx(className, 'filter', 'filter-branches')}
    label="Branch"
    value={value}
    onChange={e => onChange(e.target.value)}
    control={
      <TextField id="select_branch" select margin="none">
        {branches &&
          branches.map(branch => (
            <MenuItem key={branch.id} value={branch.id}>
              {branch.name}
            </MenuItem>
          ))}
        <MenuItem key={'def'} value={'def'} disabled>
          Please, select branch
        </MenuItem>
      </TextField>
    }
    labelPlacement="start"
  />
))`
  margin: 0;

  .MuiFormControl-root {
    min-width: 15rem;
    padding-left: 1rem;
  }
`;

const tableColumns = (roles, branches, isEditable) => [
  {
    Header: 'First Name',
    accessor: 'first_name',
  },
  {
    Header: 'Last Name',
    accessor: 'last_name',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Phone',
    accessor: 'phone',
  },
  {
    Header: 'Branch',
    accessor: 'branch',
    Cell: ({ value }) => (
      <div>{branches && getBranchDescById(branches, value.id)}</div>
    ),
  },
  {
    Header: 'Role',
    accessor: 'role_id',
    Cell: ({ value }) => <div>{!!roles.length ? getRoleDescById(roles, value) : '—'}</div>,
  },
  {
    width: 120,
    Cell: () => (
      isEditable &&
        <div className={'displayOnHover'}>
          <CrudButton />
        </div>
    ),
  },
];

const employeeFormFields = (branch, role) => [
  {
    name: 'first_name',
    type: 'text',
    label: 'First Name',
    validationGroup: 'first_name',
  },
  {
    name: 'last_name',
    type: 'text',
    label: 'Last Name',
    validationGroup: 'last_name',
  },
  {
    name: 'email',
    type: 'email',
    label: 'Email',
  },
  {
    name: 'phone',
    type: 'phone',
    validationGroup: 'phone_notReq',
    label: 'Phone',
  },
  {
    label: 'Branch',
    name: 'branch',
    type: 'select',
    initValue: branch,
  },
  {
    label: 'Role',
    name: 'role_id',
    type: 'select',
    initValue: role,
  },
];

const viewEmployeeFormFields = (branches, roles, rolesAllowed, branchesAllowed) => {
  return [
    {
      name: 'first_name',
      type: 'text',
      label: 'First Name',
    },
    {
      name: 'last_name',
      type: 'text',
      label: 'Last Name',
    },
    {
      name: 'email',
      type: 'email',
      label: 'Email',
      disabled: true,
    },
    {
      name: 'phone',
      type: 'phone',
      validationGroup: 'phone_notReq',
      label: 'Phone',
    },
    {
      label: 'Branch',
      name: 'branch',
      type: 'select',
      initValue: branches,
      disabled: !branchesAllowed,
    },
    {
      label: 'Role',
      name: 'role_id',
      type: 'select',
      initValue: roles,
      disabled: !rolesAllowed,
    },
  ];
};

const addEmployee = ({
  values,
  handleOnFinish,
  handleOnFail,
  handleOnStart,
  dispatch,
}) => {
  const url = endpoints.customer.employeeAdd;

  return apiCallWrapper({
    onStart: () => {
      dispatch({ type: 'ADD_EMPLOYEE_START' });
      handleOnStart();
    },
    apiCall: () => post(url, values),
    onFinish: resp => {
      dispatch({ type: 'ADD_EMPLOYEE_FINISH', payload: resp });
      toast.success('New employee added successfully');
      handleOnFinish(resp);
    },
    notify: true,
    onFail: error => {
      dispatch({ type: 'ADD_EMPLOYEE_FAIL', error });
      handleOnFail();
    },
  });
};

const addReqValuesMapper = (values, company_id) => {
  return {
    first_name: values.first_name,
    last_name: values.last_name,
    phone: values.phone,
    email: values.email,
    company: { id: company_id },
    branch: { id: values.branch },
    role_id: values.role_id,
  };
};

const employeePersonalDataMapper = values => ({
  first_name: values.first_name || '',
  last_name: values.last_name || '',
  phone: values.phone || '',
  email: values.email || '',
});

const formValuesMapper = (values, company_id) => {
  return {
    first_name: values.first_name,
    last_name: values.last_name,
    phone: values.phone,
    email: values.email,
    branch: values.branch.id,
    pricing_plan_id: values.pricing_plan_id,
    company_id,
    role_id: values.role_id,
  };
};

export const useGetEmployees = (update, setIsProcessing, curBranch) => {
  const [data, setData] = useState();
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const url = endpoints.customer.employeeRead;
  useEffect(() => {
    if (curBranch && curBranch !== 'def') {
      const reqBody = {
        branch_id: curBranch,
      };

      apiCallWrapper({
        notify: true,
        onStart: () => {
          dispatch({ type: 'GET_EMPLOYEES_START', curBranch });
          setIsProcessing(true);
        },
        apiCall: () => post(url, reqBody),
        onFinish: resp => {
          dispatch({ type: 'GET_EMPLOYEES_FINISH', payload: resp });
          setData(resp.employees);
          setIsProcessing(false);
        },
        onFail: error => {
          dispatch({ type: 'GET_EMPLOYEES_FAIL', error });
          setError(error);
          setIsProcessing(false);
        },
      });
    }
  }, [update, dispatch, setIsProcessing, url, curBranch]);

  return [data, error];
};

const mapSelectBranches = branches =>
  branches.map(branch => ({ value: branch.id, label: branch.name }));

const mapSelectRoles = roles =>
  roles.map(role => ({ value: role.id, label: role.description }));

export const getRoleDescById = (roles, id) => {
  const result = roles.find(role => role.id === id);
  return result.description;
};

const getBranchDescById = (branches, id) => {
  const result = branches.find(branch => branch.id === id);
  return result.name;
};

const Employees = ({ className }) => {
  const company_id = useSelector(state => state.profile.customer.company.id);
  const permissions = useSelector(selectPermissions);
  const rolesAllowed =
    permissions.edit_branch_admins &&
    permissions.see_branch_admins &&
    permissions.edit_company_users &&
    permissions.see_branches &&
    permissions.edit_branches;
  const [modalCurd, setModalCurd] = useState(modalCrudInitData);
  const dispatch = useDispatch();
  const [update, setUpdate] = useState(0);
  const [isProcessing, setIsProcessing] = useState();
  const [curBranch, setCurBranch] = useState();
  const [[branches, hqBranch], branchesError] = useGetBranches(
    0,
    setIsProcessing,
  );
  const [roles, roleError] = useGetRoles(0, setIsProcessing, rolesAllowed);
  const [error, setError] = useState(null);

  const finalCurBranch = curBranch || hqBranch || 'def';

  const [employees, employeesError] = useGetEmployees(
    update,
    setIsProcessing,
    finalCurBranch,
  );

  useEffect(() => {
    if (employeesError || branchesError || roleError) {
      setError('You do not have such permission');
    }
  }, [setError, employeesError, branchesError, roleError]);

  const handleOnFinish = () => {
    setIsProcessing(false);
    setUpdate(update => update + 1);
    setModalCurd({ ...modalCrudInitData });
  };
  const handleOnStart = () => {
    setIsProcessing(true);
  };
  const handleOnFail = () => {
    setIsProcessing(false);
  };

  const handleOnDelete = employee_id => () => {
    const reqBody = {
      user_id: employee_id,
    };

    const url = endpoints.customer.employeeDelete;

    apiCallWrapper({
      notify: true,
      onStart: () => {
        dispatch({ type: 'EMPLOYEE_DELETE_START', employee_id });
        handleOnStart();
      },
      apiCall: () => post(url, reqBody),
      onFinish: resp => {
        dispatch({ type: 'EMPLOYEE_DELETE_FINISH', resp: resp });
        handleOnFinish();
      },
      onFail: error => {
        dispatch({ type: 'EMPLOYEE_DELETE_FAIL', error });
        handleOnFail();
      },
    });
  };

  const handleOnRoleChange = (employee_id, role_id) => {
    const reqBody = {
      employee_id,
      role_id,
    };

    const url = endpoints.customer.employeeChangeRole;

    apiCallWrapper({
      notify: true,
      onStart: () => {
        dispatch({ type: 'CHANGE_ROLE_START', employee_id, role_id });
        handleOnStart();
      },
      apiCall: () => post(url, reqBody),
      onFinish: resp => {
        dispatch({ type: 'CHANGE_ROLE_FINISH', resp: resp });
        handleOnFinish();
        toast.success('Employee role changed');
      },
      onFail: error => {
        dispatch({ type: 'CHANGE_ROLE_FAIL', error });
        handleOnFail();
      },
    });
  };

  const handleOnBranchChange = (employee_id, branch_id) => {
    const reqBody = {
      employee_id,
      branch_id,
    };

    const url = endpoints.customer.employeeChangeBranch;

    apiCallWrapper({
      notify: true,
      onStart: () => {
        dispatch({ type: 'CHANGE_BRANCH_START', employee_id, branch_id });
        handleOnStart();
      },
      apiCall: () => post(url, reqBody),
      onFinish: resp => {
        dispatch({ type: 'CHANGE_BRANCH_FINISH', resp: resp });
        handleOnFinish();
        toast.success('Employee branch changed');
      },
      onFail: error => {
        dispatch({ type: 'CHANGE_BRANCH_FAIL', error });
        handleOnFail();
      },
    });
  };

  const handleOnEmployeeDataChange = (employeeId, initValues, values) => {
    const reqBody = employeePersonalDataMapper(values);

    if (_.isEqual(employeePersonalDataMapper(initValues), reqBody)) {
      return;
    }

    const url = endpoints.customer.employeeChangeData;

    apiCallWrapper({
      notify: true,
      onStart: () => {
        dispatch({ type: 'CHANGE_EMPLOYEE_DATA_START', employeeId });
        handleOnStart();
      },
      apiCall: () => post(url, { id: employeeId, ...reqBody }),
      onFinish: resp => {
        dispatch({ type: 'CHANGE_EMPLOYEE_DATA_FINISH', resp: resp });
        handleOnFinish();
        toast.success('Employee data changed');
      },
      onFail: error => {
        dispatch({ type: 'CHANGE_EMPLOYEE_DATA_FAIL', error });
        handleOnFail();
      },
    });
  };

  const handleOnChangeSubmit = ({ initialValues, employeeId }) => values => {
    handleOnEmployeeDataChange(employeeId, initialValues, values);

    if (initialValues['branch'] !== values['branch']) {
      handleOnBranchChange(employeeId, values['branch']);
    }

    if (initialValues['role_id'] !== values['role_id']) {
      handleOnRoleChange(employeeId, values['role_id']);
    }
  };

  return (
    <div className={className}>
      <LayoutAccountStyled>
        <div className={clsx('row', 'row-top')}>
          <h2>Employees</h2>
          <LayoutAccountButtonCreate
            disabled={error || !rolesAllowed}
            setModalCrud={() =>
              roles &&
              branches &&
              setModalCurd({
                ...modalCrudInitData,
                isOpen: true,
                onSubmit: values =>
                  addEmployee({
                    values: addReqValuesMapper(values, company_id),
                    dispatch,
                    handleOnFail,
                    handleOnFinish,
                    handleOnStart,
                  }),
                formFieldsMarkup: employeeFormFields(
                  mapSelectBranches(branches),
                  mapSelectRoles(roles),
                ),
                title: 'Add new employee',
              })
            }
          />
        </div>
        {error ? (
          <ErrorWrapper>{error}</ErrorWrapper>
        ) : (
          <>
            <div className={clsx('row', 'row-sec')}>
              <FilterBranches
                value={finalCurBranch}
                branches={branches}
                onChange={value => setCurBranch(value)}
              />{' '}
            </div>
            <CoolTable
              pageSize={5}
              loading={(!employees || isProcessing) && !error}
              data={employees}
              showPagination={false}
              columns={tableColumns(roles, branches, permissions.edit_company_users)}
              getTrGroupProps={(state, rowInfo, column, instance) => {
                let title = 'Employee';

                if (rowInfo && rowInfo.row) {
                  title = `${rowInfo.row.first_name} ${rowInfo.row.last_name}`;
                }

                if (rowInfo) {
                }
                return {
                  onClick: () => {
                    rowInfo &&
                      branches &&
                      permissions.edit_company_users &&
                      rowInfo.row &&
                      setModalCurd({
                        ...modalCrudInitData,
                        isOpen: true,
                        onSubmit: handleOnChangeSubmit({
                          initialValues: formValuesMapper(
                            rowInfo.row,
                            company_id,
                            branches,
                          ),
                          employeeId: rowInfo.row._original.id,
                          dispatch,
                        }),
                        formFieldsMarkup: viewEmployeeFormFields(
                          mapSelectBranches(branches),
                          mapSelectRoles(roles),
                          rolesAllowed,
                          permissions.edit_branches
                        ),
                        onDelete: handleOnDelete(rowInfo.row._original.id),
                        isDelete: true,
                        data: formValuesMapper(
                          rowInfo.row,
                          company_id,
                          branches,
                        ),
                        title: title,
                      });
                  },
                };
              }}
            />
          </>
        )}
        {modalCurd.isOpen && (
          <ModalFormCrud
            loading={isProcessing}
            onClose={() => setModalCurd({ isOpen: false })}
            {...modalCurd}
          />
        )}
      </LayoutAccountStyled>
    </div>
  );
};

export default Employees;
