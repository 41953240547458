import React, { useLayoutEffect, useRef, useState } from 'react';
import LayoutAccountStyled from '../../layouts/Account';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import Button from 'components/Button';
import { toast } from 'react-toastify';
import { FileCopy as FileCopyIcon } from '@material-ui/icons';
import { apiCallWrapper, post } from '../../utils/fetch';
import { useDispatch } from 'react-redux';
import {
  Content,
  ControlButtonsSpaceBetweenWrapper,
  ControlButtonsWrapper,
  ErrorWrapper,
  FieldsWrapper,
  ModalConfirmWrapper,
  RowWrapper,
} from '../../components';
import ModalHeadless from 'components/Modal/ModalHeadless';
import { domCopyInputContent } from '../../../utils/common';
import useFetchKeys from './api-hooks/use-fetch-keys';
import Webhooks from './Webhooks';
import RedirectFlow from './RedirectFlow';

const Common = ({ apiUrl, type }) => {
  const clientSecretRef = useRef();
  const clientIdRef = useRef();
  const [isProcessing, setIsProcessing] = useState(true);
  const dispatch = useDispatch();
  const [clientSecret, setClientSecret] = useState();
  const [clientId, setClientId] = useState({ id: null, enabled: false });
  const [error, setError] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [handleOnActionConfirm, setHandleOnActionConfirm] = useState();

  const toggleConfirmDialog = () => {
    setIsConfirmDialogOpen(isConfirmDialogOpen => !isConfirmDialogOpen);
  };

  const { apiGenerate, apiDetails, apiDelete } = apiUrl;

  useFetchKeys(
    apiDetails,
    setClientId,
    setError,
    setIsProcessing,
    !!error,
    isProcessing,
  );

  useLayoutEffect(() => {});

  function handleOnGenerate() {
    const url = apiGenerate;

    apiCallWrapper({
      apiCall: () => post(url, ''),
      onStart: () => {
        setIsProcessing(true);
        dispatch({ type: 'START_GENERATE_KEYS' });
      },
      onFinish: resp => {
        dispatch({ type: 'FINISH_GENERATE_KEYS', resp });
        setIsProcessing(false);
        setClientId({
          id: resp.client_id,
          enabled: resp.enabled,
        });
        setClientSecret(resp.client_secret);
        toast.success('API Keys created successfully');
      },
      onFail: e => {
        dispatch({ type: 'FAIL_GENERATE_KEYS', e });
        setIsProcessing(false);
        setError(e);
      },
    });
  }

  function handleOnDelete() {
    const url = apiDelete;

    apiCallWrapper({
      apiCall: () => post(url, ''),
      onStart: () => {
        setIsProcessing(true);
        dispatch({ type: 'START_DELETE_KEYS' });
      },
      onFinish: resp => {
        dispatch({ type: 'FINISH_DELETE_KEYS', resp });
        setIsProcessing(false);
        setClientId({
          id: null,
          enabled: false,
        });
        toast.success('API Keys removed successfully');
      },
      onFail: e => {
        dispatch({ type: 'FAIL_DELETE_KEYS', e });
        setIsProcessing(false);
        setError(e);
      },
    });
  }

  if (error) {
    return (
      <Content>
        <ErrorWrapper>
          <p>Connection error</p>
          <pre>{error.toString()}</pre>
          <Button variant={'contained'} onClick={() => setError(null)}>
            Retry
          </Button>
        </ErrorWrapper>
      </Content>
    );
  }

  return (
    <Content>
      <RowWrapper>
        <Button
          loading={isProcessing}
          onClick={() => {
            if (clientId.id) {
              toggleConfirmDialog();
              setHandleOnActionConfirm(() => handleOnGenerate);
              return;
            }

            handleOnGenerate();
          }}
        >
          {(clientId.id && 'Re-Generate Keys') || 'Generate Keys'}
        </Button>
      </RowWrapper>
      <FieldsWrapper>
        {clientId.id && (
          <>
            {' '}
            <TextField
              id="clientId"
              label="Client ID"
              InputProps={{
                name: 'input',
                readOnly: true,
                ref: clientIdRef,
              }}
              value={clientId.id}
            />
            <Button
              variant="contained"
              onClick={() => {
                domCopyInputContent(clientIdRef);
                toast.info('API Key copied!');
              }}
            >
              <FileCopyIcon />
            </Button>
          </>
        )}
        {clientId.id && clientSecret && (
          <>
            <TextField
              id="clientSecret"
              label="Client Secret"
              InputProps={{
                name: 'input',
                readOnly: true,
                ref: clientSecretRef,
              }}
              value={clientSecret}
            />
            <Button
              variant="contained"
              onClick={() => {
                domCopyInputContent(clientSecretRef);
                toast.info('Secret Key copied!');
              }}
            >
              <FileCopyIcon />
            </Button>
          </>
        )}
      </FieldsWrapper>
      <RowWrapper>
        {clientId.id && (
          <Button
            customColor={'warning'}
            loading={isProcessing}
            onClick={() => {
              toggleConfirmDialog();
              setHandleOnActionConfirm(() => handleOnDelete);
            }}
          >
            Delete Keys
          </Button>
        )}
      </RowWrapper>
      {type === 'prod' && 
          <>
            <RedirectFlow />
            <Webhooks />
          </>
      }

      <ModalHeadless
        small
        open={isConfirmDialogOpen}
        onClose={toggleConfirmDialog}
      >
        <ModalConfirmWrapper>
          <p>
            Are you sure? Your previous generated keys will be deleted and your
            application probably will not work properly.
          </p>
          <ControlButtonsSpaceBetweenWrapper>
            <ControlButtonsWrapper>
              <Button
                loading={isProcessing}
                customColor={'warning'}
                onClick={() => {
                  handleOnActionConfirm();
                  toggleConfirmDialog();
                }}
              >
                Continue
              </Button>
            </ControlButtonsWrapper>
            <Button customColor={'low'} onClick={toggleConfirmDialog}>
              Cancel
            </Button>
          </ControlButtonsSpaceBetweenWrapper>
        </ModalConfirmWrapper>
      </ModalHeadless>
    </Content>
  );
};

const CommonWrapper = ({ type, className, apiUrl, title }) => (
  <div className={className}>
    <LayoutAccountStyled>
      <div className={clsx('row', 'row-top')}>
        <h2>{title}</h2>
      </div>
      <Common apiUrl={apiUrl} type={type} />
    </LayoutAccountStyled>
  </div>
);

export default CommonWrapper;
