import appConfig from '../../config';
export const fetchSettings = {
  timeoutBeforeAutoReject: 60 * 1000 /* 1 min */,
  common: {
    cache: 'no-cache',
    referrer: 'no-referrer',
    credentials: 'include',
    // credentials: 'same-origin',
  },
  get: {
    method: 'GET',
  },
  post: {
    method: 'POST',
  },
  del: {
    method: 'DELETE',
  },
};

export const index = {
  siteTitle: 'trustloop',
  fetchSettings,
  swaggerUiUrl: `${appConfig.externalUrl}/swaggerui`,
};
export default index;
