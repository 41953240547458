import React from 'react';
import { Container as MuiContainer } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: '800px',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '5rem',
    paddingRight: '5rem',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '2rem',
      paddingRight: '2rem',
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '2rem',
      paddingRight: '2rem',
    },
  },
}));

const Container = ({ fixed = false, maxWidth, children, className }) => {
  const classes = useStyles();

  return (
    <MuiContainer fixed={fixed} className={clsx(className, classes.root)}>
      {children}
    </MuiContainer>
  );
};

export default Container;
