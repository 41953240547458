import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { CssBaseline } from '@material-ui/core';
import store from 'config/store';
import theme from 'config/theme';
import App from './App';
import history from 'utils/history';
import styledTheme from '@src/config/theme';
import 'assets/styles/index.css';
import 'assets/fonts/fonts.css';
import { IntlProvider } from 'react-intl';

const formats = {
  number: {
    TRY: {
      style: 'currency',
      currency: 'TRY',
    },
    USD: {
      style: 'currency',
      currency: 'USD',
    },
    GBP: {
      style: 'currency',
      currency: 'GBP',
      currencyDisplay: 'narrowSymbol',
    },
  },
};

const ProviderWrapper = () => (
  <Provider store={store}>
    <CssBaseline />
    <IntlProvider formats={formats} defaultFormats={formats} locale={'en-GB'}>
      <StyledThemeProvider theme={styledTheme}>
        <ThemeProvider theme={theme}>
          <Router history={history}>
            <App />
          </Router>
        </ThemeProvider>
      </StyledThemeProvider>
    </IntlProvider>
  </Provider>
);

ReactDOM.render(<ProviderWrapper />, document.getElementById('root'));
