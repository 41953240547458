import React from 'react';
import clsx from 'clsx';

const Logo = ({ className, width = 230 }) => {
  return (
    <div className={clsx(className, 'assets-svg-Logo')}>
      <svg
        width={width}
        viewBox={`0 0 230 48`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M66.0282 35.8291C65.0894 34.6567 64.6204 33.1768 64.6204 31.387V18.8524H61.8497V13.9265H64.7524L65.7643 7.41718H70.6462V13.9265H74.6483V18.8524H70.6462V30.7716C70.6462 32.0325 71.2762 32.6624 72.537 32.6624H74.6483V36.8849C74.1494 37.0604 73.5267 37.221 72.7793 37.3689C72.0313 37.5149 71.3352 37.5889 70.6902 37.5889C68.52 37.5893 66.9662 37.0029 66.0282 35.8291Z"
          fill="#3C3C3B"
        />
        <path
          d="M77.7489 13.927H82.8948L83.3783 17.6651H83.6862C84.1847 16.3457 84.8296 15.3058 85.6215 14.5429C86.4129 13.7805 87.4533 13.3995 88.7442 13.3995C89.3006 13.3995 89.7923 13.4508 90.2173 13.5533C90.6417 13.6562 90.9134 13.7365 91.0314 13.795V19.4249H89.3596C87.3953 19.4249 85.9735 19.9601 85.0936 21.03C84.2142 22.1008 83.7742 23.7204 83.7742 25.8902V37.0615H77.7489V13.927Z"
          fill="#3C3C3B"
        />
        <path
          d="M95.3984 35.6536C94.0351 34.3638 93.3534 32.2975 93.3534 29.4523V13.9275H99.3788V28.1774C99.3788 29.6143 99.7152 30.6552 100.39 31.3001C101.064 31.945 102.061 32.2675 103.381 32.2675C104.729 32.2675 105.851 31.7918 106.746 30.8384C107.64 29.8855 108.087 28.7049 108.087 27.298V13.928H114.112V37.0619H108.966L108.526 34.0278H108.218C106.342 36.4025 103.937 37.5899 101.006 37.5899C98.6308 37.5889 96.7618 36.944 95.3984 35.6536Z"
          fill="#3C3C3B"
        />
        <path
          d="M120.16 35.5874C118.327 34.2535 117.41 32.4729 117.41 30.2437L117.454 29.7158H123.304V30.0238C123.333 31.0506 123.795 31.7903 124.689 32.2452C125.584 32.6997 126.675 32.9264 127.965 32.9264C129.168 32.9264 130.194 32.7659 131.044 32.4425C131.894 32.1205 132.32 31.5926 132.32 30.8601C132.32 30.0392 131.887 29.4233 131.023 29.0124C130.157 28.6029 128.801 28.1924 126.954 27.7815C125.048 27.3415 123.494 26.8953 122.292 26.4399C121.089 25.9864 120.048 25.2743 119.169 24.3069C118.29 23.3399 117.85 22.0496 117.85 20.4368C117.85 18.179 118.795 16.4415 120.686 15.2246C122.578 14.0082 125.019 13.3995 128.009 13.3995C130.794 13.3995 133.095 13.9642 134.914 15.0926C136.732 16.222 137.641 17.7536 137.641 19.6889L137.597 20.7003H131.747V20.6123C131.718 19.7623 131.352 19.1247 130.648 18.6992C129.944 18.2743 128.991 18.0615 127.789 18.0615C126.586 18.0615 125.634 18.223 124.93 18.545C124.227 18.8684 123.874 19.3224 123.874 19.9088C123.874 20.6418 124.277 21.1842 125.084 21.5362C125.89 21.8881 127.188 22.2396 128.977 22.5915C130.94 23.031 132.539 23.4714 133.771 23.9109C135.002 24.3513 136.072 25.0693 136.981 26.0662C137.89 27.0631 138.345 28.4124 138.345 30.1117C138.345 35.0972 134.811 37.5894 127.745 37.5894C124.521 37.5894 121.992 36.9227 120.16 35.5874Z"
          fill="#3C3C3B"
        />
        <path
          d="M143.663 35.8291C142.725 34.6567 142.256 33.1768 142.256 31.387V18.8524H139.485V13.9265H142.388L143.4 7.41718H148.282V13.9265H152.284V18.8524H148.282V30.7716C148.282 32.0325 148.911 32.6624 150.173 32.6624H152.284V36.8849C151.785 37.0604 151.162 37.221 150.415 37.3689C149.667 37.5149 148.97 37.5889 148.326 37.5889C146.155 37.5893 144.602 37.0029 143.663 35.8291Z"
          fill="#3C3C3B"
        />
        <path
          d="M159.219 5.26196H155.348V37.0605H159.219V5.26196Z"
          fill="#B8B4B3"
        />
        <path
          d="M164.967 34.6199C163.178 32.6411 162.284 29.5992 162.284 25.4942C162.284 21.3891 163.178 18.3472 164.967 16.3679C166.755 14.3886 169.423 13.399 172.972 13.399C176.519 13.399 179.188 14.3886 180.977 16.3679C182.764 18.3472 183.66 21.3891 183.66 25.4942C183.66 29.5992 182.764 32.6411 180.977 34.6199C179.188 36.5992 176.519 37.5888 172.972 37.5888C169.423 37.5888 166.755 36.5992 164.967 34.6199ZM178.073 32.3327C179.129 30.9698 179.657 28.7483 179.657 25.6697V25.3177C179.657 22.239 179.128 20.018 178.073 18.6542C177.018 17.2908 175.317 16.6092 172.971 16.6092C170.625 16.6092 168.925 17.2908 167.87 18.6542C166.814 20.018 166.286 22.239 166.286 25.3177V25.6697C166.286 28.7483 166.814 30.9698 167.87 32.3327C168.925 33.696 170.625 34.3772 172.971 34.3772C175.317 34.3777 177.018 33.6965 178.073 32.3327Z"
          fill="#B8B4B3"
        />
        <path
          d="M188.237 34.6199C186.448 32.6411 185.554 29.5992 185.554 25.4942C185.554 21.3891 186.448 18.3472 188.237 16.3679C190.025 14.3886 192.693 13.399 196.242 13.399C199.789 13.399 202.457 14.3886 204.247 16.3679C206.035 18.3472 206.929 21.3891 206.929 25.4942C206.929 29.5992 206.035 32.6411 204.247 34.6199C202.458 36.5992 199.789 37.5888 196.242 37.5888C192.693 37.5888 190.025 36.5992 188.237 34.6199ZM201.344 32.3327C202.399 30.9698 202.927 28.7483 202.927 25.6697V25.3177C202.927 22.239 202.399 20.018 201.344 18.6542C200.288 17.2908 198.587 16.6092 196.242 16.6092C193.895 16.6092 192.196 17.2908 191.14 18.6542C190.084 20.018 189.556 22.239 189.556 25.3177V25.6697C189.556 28.7483 190.084 30.9698 191.14 32.3327C192.196 33.696 193.895 34.3772 196.242 34.3772C198.587 34.3777 200.288 33.6965 201.344 32.3327Z"
          fill="#B8B4B3"
        />
        <path
          d="M209.951 13.9269H212.854L213.338 17.0496H213.601C214.364 15.8477 215.367 14.9388 216.614 14.3229C217.86 13.707 219.245 13.399 220.771 13.399C223.702 13.399 225.961 14.3819 227.544 16.3457C229.127 18.3109 229.919 21.3596 229.919 25.4942C229.919 29.5402 229.134 32.5676 227.566 34.5764C225.997 36.5847 223.849 37.5884 221.123 37.5884C219.481 37.5884 218.088 37.3389 216.944 36.8404C215.801 36.3425 214.847 35.5361 214.086 34.4212H213.821V44.6696H209.951V13.9269ZM223.365 33.5418C224.245 32.9858 224.889 32.0769 225.301 30.8151C225.711 29.5547 225.916 27.8394 225.916 25.6692V25.4937C225.916 23.2944 225.711 21.5506 225.301 20.2598C224.889 18.9699 224.237 18.0387 223.344 17.4668C222.448 16.8949 221.269 16.6092 219.803 16.6092C217.75 16.6092 216.241 17.3571 215.273 18.8524C214.306 20.3478 213.821 22.562 213.821 25.4937V25.6692C213.821 28.5428 214.298 30.7126 215.251 32.1789C216.204 33.6448 217.721 34.3777 219.803 34.3777C221.298 34.3782 222.485 34.0997 223.365 33.5418Z"
          fill="#B8B4B3"
        />
        <path
          d="M40.0508 14.3969C36.6661 14.3969 33.9123 11.6431 33.9123 8.25843C33.9123 6.36086 34.7787 4.66199 36.1357 3.53505C32.6959 1.40106 28.6721 0.122315 24.364 0V9.18764C31.8233 9.53428 37.8288 15.5398 38.1759 22.9996H47.3626C47.2543 19.1885 46.2376 15.6027 44.5281 12.4442C43.407 13.6422 41.8174 14.3969 40.0508 14.3969Z"
          fill="#FAB833"
        />
        <path
          d="M22.9996 9.18668V0C18.691 0.121832 14.6672 1.40058 11.2274 3.53505C12.5844 4.66199 13.4508 6.36037 13.4508 8.25843C13.4508 11.6431 10.697 14.3964 7.31231 14.3964C5.54575 14.3964 3.95566 13.6422 2.835 12.4437C1.12549 15.6027 0.108295 19.188 0 22.9991H9.18717C9.53381 15.5393 15.5398 9.53332 22.9996 9.18668Z"
          fill="#5BBDD7"
        />
        <path
          d="M9.187 24.3634H0.000320435C0.108615 28.175 1.12532 31.7608 2.83483 34.9183C3.95598 33.7203 5.54559 32.9656 7.31215 32.9656C10.6968 32.9656 13.4506 35.7194 13.4506 39.1041C13.4506 41.0016 12.5843 42.7005 11.2277 43.8279C14.667 45.9619 18.6908 47.2402 22.9989 47.3625V38.1753C15.5396 37.8292 9.53364 31.8232 9.187 24.3634Z"
          fill="#ED6C77"
        />
        <path
          d="M33.9122 39.104C33.9122 35.7193 36.666 32.966 40.0507 32.966C41.8172 32.966 43.4068 33.7202 44.528 34.9187C46.2375 31.7603 47.2542 28.1745 47.3625 24.3638H38.1753C37.8287 31.8231 31.8227 37.8291 24.3634 38.1753V47.3619C28.6715 47.2396 32.6953 45.9619 36.1351 43.8274C34.7786 42.7005 33.9122 41.0016 33.9122 39.104Z"
          fill="#B8B4B3"
        />
        <path
          d="M35.2761 8.2584C35.2761 10.8908 37.4178 13.0326 40.0502 13.0326C42.6831 13.0326 44.8249 10.8908 44.8249 8.2584C44.8249 5.62597 42.6831 3.48425 40.0502 3.48425C37.4178 3.48425 35.2761 5.62597 35.2761 8.2584Z"
          fill="#FAB833"
        />
        <path
          d="M40.0508 34.3298C37.4184 34.3298 35.2767 36.472 35.2767 39.104C35.2767 41.7364 37.4184 43.8781 40.0508 43.8781C42.6833 43.8781 44.825 41.7364 44.825 39.104C44.825 36.472 42.6833 34.3298 40.0508 34.3298Z"
          fill="#B8B4B3"
        />
        <path
          d="M7.31222 13.033C9.94466 13.033 12.0864 10.8913 12.0864 8.25889C12.0864 5.62597 9.94466 3.48425 7.31222 3.48425C4.67979 3.48425 2.53807 5.62597 2.53807 8.25889C2.53807 10.8908 4.67979 13.033 7.31222 13.033Z"
          fill="#5BBDD7"
        />
        <path
          d="M7.31222 43.8781C9.94466 43.8781 12.0864 41.7364 12.0864 39.104C12.0864 36.472 9.94466 34.3298 7.31222 34.3298C4.67979 34.3298 2.53807 36.472 2.53807 39.104C2.53807 41.7369 4.67979 43.8781 7.31222 43.8781Z"
          fill="#ED6C77"
        />
      </svg>
    </div>
  );
};

export default Logo;
