import React from 'react';
import { makeStyles, withStyles } from '@material-ui/styles';
import { Button, Menu, MenuItem, Typography } from '@material-ui/core';
import UserMenuLogo from 'svg/UserMenuLogo';
import {
  Home as HomeIcon,
  ExitToApp as ExitToAppIcon,
  LaptopChromebook as LaptopChromebookIcon,
  VerifiedUser as VerifiedUserIcon,
} from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { setProfileLogOut } from 'store/actions/profile';
import styles from './styles';
import history from 'utils/history';
import styled from 'styled-components';
import {
  selectBalance,
  selectIsProcessing,
  selectUserData,
} from '../../store/selectors/profile';
import PreloaderSmall from '../PreloaderSmall';

const StyledMenu = withStyles({
  paper: {
    minWidth: '150px',
    boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
  },
})(props => (
  <Menu
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    elevation={0}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    '& .MuiGrid-container': {
      '& .MuiGrid-item:nth-child(2)': {
        textAlign: 'left',
      },
    },
    '&:focus': {
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const useStyles = makeStyles(styles);

const UserMenuWrapperStyled = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 3rem;
`;

const BalanceStatusStyled = styled.div`
  display: grid;
  position: relative;
  grid-auto-flow: row;
  min-width: 100px;
  min-height: 100%;

  .component_ui_Preloader {
    top: 0.5rem;
    .trustloop_emblem svg {
      max-height: 2rem;
      max-width: 2rem;
    }
  }

  .balance-body {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1rem;
    position: relative;
  }
  .balance-title {
    text-align: center;
    padding-bottom: 0.5rem;
    color: #5abdd7;
  }
  .balance-type-wrapper {
    display: grid;
    grid-auto-flow: row;
    .balance-label {
      color: #a2a2a2;
      padding-bottom: 0.5rem;
    }
    .balance-value {
      text-align: center;
      color: #5abdd7;
    }
  }
`;

export default function CustomizedMenus() {
  const classes = useStyles();
  const userData = useSelector(selectUserData);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [balance_free, balance_prepaid] = useSelector(selectBalance);
  const isProcessing = useSelector(selectIsProcessing);

  if (!userData) {
    return null;
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { first_name, last_name } = userData;

  return (
    <>
      <UserMenuWrapperStyled>
        <BalanceStatusStyled>
          <Typography className={'balance-title'}>Balance</Typography>
          <div className={'balance-body'}>
            {isProcessing ||
            balance_free === null ||
            balance_prepaid === null ? (
              <PreloaderSmall />
            ) : (
              <>
                <div className={'balance-type-wrapper'}>
                  <Typography className={'balance-label'}>Free</Typography>
                  <Typography className={'balance-value'}>
                    {balance_free}
                  </Typography>
                </div>
                <div className={'balance-type-wrapper'}>
                  <Typography className={'balance-label'}>Prepaid</Typography>
                  <Typography className={'balance-value'}>
                    {balance_prepaid}
                  </Typography>
                </div>
              </>
            )}
          </div>
        </BalanceStatusStyled>
        <Button
          aria-controls="customized-menu"
          aria-haspopup="true"
          className={classes.menuButton}
          onClick={handleClick}
        >
          <Typography className={classes.menuButtonText}>
            {first_name} {last_name}
          </Typography>
          <UserMenuLogo />
        </Button>
      </UserMenuWrapperStyled>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem
          onClick={() => {
            history.push('/');
          }}
        >
          <Grid
            alignItems={'center'}
            container
            direction={'row'}
            spacing={1}
            wrap={'nowrap'}
          >
            <Grid item>
              <HomeIcon className={classes.svgIcon} />
            </Grid>
            <Grid item>
              <Typography>Dashboard</Typography>
            </Grid>
          </Grid>
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            history.push('/dashboard/account');
          }}
        >
          <Grid
            alignItems={'center'}
            container
            direction={'row'}
            spacing={1}
            wrap={'nowrap'}
          >
            <Grid item>
              <VerifiedUserIcon className={classes.svgIcon} />
            </Grid>
            <Grid item>
              <Typography>Account</Typography>
            </Grid>
          </Grid>
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            history.push('/dashboard/developer');
          }}
        >
          <Grid
            container
            direction={'row'}
            spacing={1}
            wrap={'nowrap'}
            alignItems={'center'}
          >
            <Grid item>
              <LaptopChromebookIcon className={classes.svgIcon} />
            </Grid>
            <Grid item>
              <Typography>Developer</Typography>
            </Grid>
          </Grid>
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            dispatch(setProfileLogOut());
          }}
        >
          <Grid
            container
            direction={'row'}
            spacing={1}
            wrap={'nowrap'}
            alignItems={'center'}
          >
            <Grid item>
              <ExitToAppIcon className={classes.svgIcon} />
            </Grid>
            <Grid item>
              <Typography>Log Out</Typography>
            </Grid>
          </Grid>
        </StyledMenuItem>
      </StyledMenu>
    </>
  );
}
