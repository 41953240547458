import styled from 'styled-components';
import Button from '../../../components/Button';
import React from 'react';
import Modal from '../../../components/Modal/Modal';
import Form from '../../../components/Form';
import ModalHeadless from '../../../components/Modal/ModalHeadless';
import {
  ControlButtonsSpaceBetweenWrapper,
  ControlButtonsWrapper,
} from '../index';

export const modalCrudInitData = {
  isOpen: false,
  data: {},
  title: '',
  formFields: {},
  isDelete: false,
};

const FormButtons = styled(
  ({
    className,
    form,
    loading,
    pristine,
    values,
    isDelete,
    onDelete,
    onClose,
    deleteConfirmMessage,
    isDeleteButtonActive,
  }) => {
    const [
      isDeleteConfirmDialogOpen,
      setIsDeleteConfirmDialogOpen,
    ] = React.useState(false);

    return (
      <div className={className}>
        <ControlButtonsSpaceBetweenWrapper>
          <ControlButtonsWrapper>
            <Button loading={loading} disabled={pristine} type="submit">
              Save
            </Button>
            {!pristine && (
              <Button
                type="reset"
                customColor={'low'}
                onClick={() => form.reset()}
              >
                Reset
              </Button>
            )}
            {isDelete && (
              <Button
                data-role='btn-delete'
                type="button"
                loading={loading}
                onClick={() => setIsDeleteConfirmDialogOpen(true)}
                customColor={'warning'}
              >
                Delete
              </Button>
            )}
          </ControlButtonsWrapper>
          <ControlButtonsWrapper>
            <Button customColor={'low'} onClick={() => onClose()}>
              Close
            </Button>
          </ControlButtonsWrapper>
        </ControlButtonsSpaceBetweenWrapper>
        <ConfirmDeleteModalDialogStyled
          onClose={() => setIsDeleteConfirmDialogOpen(false)}
          open={isDeleteConfirmDialogOpen}
        >
          <div data-role="delete-confirm-message-body">
            {deleteConfirmMessage}
          </div>
          <ControlButtonsSpaceBetweenWrapper>
            <Button
              data-role="btn-delete-confirm"
              disabled={!isDeleteButtonActive}
              onClick={() => onDelete(values)}
              customColor={'warning'}
            >
              Delete
            </Button>
            <Button
              data-role="btn-close"
              onClick={() => setIsDeleteConfirmDialogOpen(false)}
              customColor={'low'}
            >
              Close
            </Button>
          </ControlButtonsSpaceBetweenWrapper>
        </ConfirmDeleteModalDialogStyled>
      </div>
    );
  },
)`
  padding-top: 2rem;
`;

const ModalFormCrud = ({
  onClose,
  data,
  title,
  formFieldsMarkup,
  isDelete,
  className,
  loading,
  buttons: Buttons = FormButtons,
  onSubmit = () => {},
  onDelete = () => {},
  deleteConfirmMessage = 'Are you sure?',
  isDeleteButtonActive = true,
}) => {
  const controlButtonsProps = {
    loading,
    onDelete,
    isDelete,
    deleteConfirmMessage,
    isDeleteButtonActive,
    onClose,
  };

  return (
    <Modal className={className} open={true} onClose={onClose} title={title}>
      <div className={'body-scrollable'}>
        <Form
          handleOnSubmit={onSubmit}
          loading={loading}
          fieldsMarkup={formFieldsMarkup}
          initValues={data}
          buttons={props => <Buttons {...props} {...controlButtonsProps} />}
        />
      </div>
    </Modal>
  );
};

const ConfirmDeleteModalDialogStyled = styled(ModalHeadless)`
  #modal-frame {
    min-height: auto;
    min-width: auto;

    #modal-body {
      display: grid;
      grid-auto-flow: row;
      grid-auto-rows: max-content;
      grid-gap: 2rem;
      [data-role='delete-confirm-message-body'] {
      }
      button[data-role='btn-delete'] {
      }
      button[data-role='btn-close'] {
      }
    }
  }
`;

const ModalFromCrudStyled = styled(ModalFormCrud)`
  font-family: 'Archivo', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  min-height: min-content;
  border-radius: 0.25rem;
  display: grid;
  grid-auto-flow: row;

  .fieldsWrapper {
    /* Here you can set max-height & overflow: auto; if you want middle part of modal to 
       be scrollable when screen size height small */
  }

  form {
    .fieldsWrapper {
      display: grid;
      grid-auto-flow: row;
      grid-gap: 1.5rem;

      .Mui-disabled {
        color: #3c3c3b;
      }
    }
  }
`;

export default ModalFromCrudStyled;
