import React from 'react';
import clsx from 'clsx';
import styled, { ThemeProvider } from 'styled-components';
import AppBar from '@src/components/AppBar/AppBar';
import theme from '@src/config/theme';
import Footer from '@src/components/Footer';

export const LayoutsDashboard = ({
  className,
  children,
  appBarNavLinks,
  bgColor,
}) => {
  return (
    <div className={clsx(className, 'layouts-Dashboard')}>
      <ThemeProvider theme={theme}>
        <section className={clsx('layouts-section-header')}>
          <AppBar navLinks={appBarNavLinks} />
        </section>
        <section className={clsx('layouts-section-mainContent')}>
          {children}
        </section>
        <section className={clsx('layouts-section-footer')}>
          <Footer bgColor={bgColor} />
        </section>
      </ThemeProvider>
    </div>
  );
};

const LayoutsDashboardStyled = styled(LayoutsDashboard)`
  button,
  a,
  span,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  div {
    font-family: 'Archivo', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
  }
  display: grid;
  height: 100%;
  grid-template:
    'header' max-content
    'main' 1fr
    'footer' 100px / 100%;
  .layouts-section-mainContent {
    background-color: ${({ bgColor }) => bgColor || 'white'};
    position: relative;
    display: flex;
    flex-flow: column nowrap;
  }
`;

export default LayoutsDashboardStyled;
