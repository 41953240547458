import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import ModalFormCrud, {
  modalCrudInitData,
} from '../../components/ModalFormCrud';
import { ErrorWrapper, LayoutAccountButtonCreate } from '../../components';
import LayoutAccountStyled from '../../layouts/Account';
import { cellCheckbox, CrudButton } from '../../../components/Table/utils';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import endpoints from '../../config/endpoints';
import { apiCallWrapper, get, post } from '../../utils/fetch';
import { toast } from 'react-toastify';
import Preloader from '../../../components/Preloader';

// const bgGroupHighlightColor = 'rgba(250,183,51,0.25)';
const bgGroupHighlightColor = 'rgba(255,255,255,1)';

const tableColumns = [
  {
    Header: 'Name',
    accessor: 'description',
  },
  {
    Header: 'See my ics',
    accessor: 'see_my_ics',
    Cell: cellCheckbox,
    bg: bgGroupHighlightColor,
  },
  {
    Header: 'Create ics',
    accessor: 'create_ics',
    Cell: cellCheckbox,
    bg: bgGroupHighlightColor,
  },
  {
    Header: 'See branch ics',
    accessor: 'see_branch_ics',
    Cell: cellCheckbox,
    bg: bgGroupHighlightColor,
  },
  {
    Header: 'See company ics',
    accessor: 'see_company_ics',
    Cell: cellCheckbox,
    bg: bgGroupHighlightColor,
  },
  {
    Header: 'CRUD branch users',
    accessor: 'crud_branch_users',
    Cell: cellCheckbox,
  },
  {
    Header: 'See branch admins',
    accessor: 'see_branch_admins',
    Cell: cellCheckbox,
  },
  {
    Header: 'Edit branch admins',
    accessor: 'edit_branch_admins',
    Cell: cellCheckbox,
  },
  {
    Header: 'See branches',
    accessor: 'see_branches',
    Cell: cellCheckbox,
  },
  {
    Header: 'Edit branches',
    accessor: 'edit_branches',
    Cell: cellCheckbox,
  },
  {
    Header: 'See company users',
    accessor: 'see_company_users',
    Cell: cellCheckbox,
    bg: bgGroupHighlightColor,
  },
  {
    Header: 'Edit company users',
    accessor: 'edit_company_users',
    Cell: cellCheckbox,
    bg: bgGroupHighlightColor,
  },
  {
    Header: 'Top up branch balance',
    accessor: 'top_up_branch_balance',
    Cell: cellCheckbox,
  },
  {
    Header: 'See company balance',
    accessor: 'see_company_balance',
    Cell: cellCheckbox,
  },
  {
    Header: 'Edit company info',
    accessor: 'edit_company_info',
    Cell: cellCheckbox,
    bg: bgGroupHighlightColor,
  },
  {
    Header: 'Delete account',
    accessor: 'delete_account',
    Cell: cellCheckbox,
    bg: bgGroupHighlightColor,
  },
  {
    Header: 'CRUD sandbox keys',
    accessor: 'crud_sandbox_keys',
    Cell: cellCheckbox,
  },
  {
    Header: 'CRUD real data keys',
    accessor: 'crud_real_data_keys',
    Cell: cellCheckbox,
  },
  {
    Header: 'Can set redirect flow',
    accessor: 'can_set_redirect_flow',
    Cell: cellCheckbox,
  },
  {
    Header: 'Can edit company email settings',
    accessor: 'can_edit_company_email_settings',
    Cell: cellCheckbox,
  },
  {
    Header: 'See affordabilities',
    accessor: 'can_see_affordability',
    Cell: cellCheckbox,
  },
  {
    Header: 'Edit affordabilities',
    accessor: 'can_edit_affordability',
    Cell: cellCheckbox,
  },
  
  {
    width: 120,
    Cell: () => (
      <div className={'displayOnHover'}>
        <CrudButton />
      </div>
    ),
  },
];

const formFields = [
  {
    label: 'Name',
    type: 'text',
    name: 'description',
  },
  {
    label: 'See my ics',
    type: 'checkbox',
    name: 'see_my_ics',
  },
  {
    label: 'Create ics',
    type: 'checkbox',
    name: 'create_ics',
  },
  {
    label: 'See branch ics',
    type: 'checkbox',
    name: 'see_branch_ics',
  },
  {
    label: 'See company ics',
    type: 'checkbox',
    name: 'see_company_ics',
  },
  {
    label: 'CRUD branch users',
    type: 'checkbox',
    name: 'crud_branch_users',
  },
  {
    label: 'See company users',
    type: 'checkbox',
    name: 'see_company_users',
  },
  {
    label: 'Edit company users',
    type: 'checkbox',
    name: 'edit_company_users',
  },
  {
    label: 'See branch admins',
    type: 'checkbox',
    name: 'see_branch_admins',
  },
  {
    label: 'Edit branch admins',
    type: 'checkbox',
    name: 'edit_branch_admins',
  },
  {
    label: 'See branches',
    type: 'checkbox',
    name: 'see_branches',
  },
  {
    label: 'Edit branches',
    type: 'checkbox',
    name: 'edit_branches',
  },
  {
    label: 'Top up branch balance',
    type: 'checkbox',
    name: 'top_up_branch_balance',
  },
  {
    label: 'See company balance',
    type: 'checkbox',
    name: 'see_company_balance',
  },
  {
    label: 'Edit company info',
    type: 'checkbox',
    name: 'edit_company_info',
  },
  {
    label: 'Delete account',
    type: 'checkbox',
    name: 'delete_account',
  },
  {
    label: 'CRUD sandbox keys',
    type: 'checkbox',
    name: 'crud_sandbox_keys',
  },
  {
    label: 'CRUD real data keys',
    type: 'checkbox',
    name: 'crud_real_data_keys',
  },
  {
    label: 'Can set redirect flow',
    type: 'checkbox',
    name: 'can_set_redirect_flow',
  },
  {
    label: 'Can edit company email settings',
    type: 'checkbox',
    name: 'can_edit_company_email_settings',
  },
  {
    label: 'See affordabilities',
    type: 'checkbox',
    name: 'can_see_affordability',
  },
  {
    label: 'Edit affordabilities',
    type: 'checkbox',
    name: 'can_edit_affordability',
  },
];

export const useGetRoles = (update, setIsProcessing, rolesAllowed = true) => {
  const [data, setData] = useState();
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const url = endpoints.customer.rolesList;
  useEffect(() => {
    if (rolesAllowed) {
      apiCallWrapper({
        onStart: () => {
          dispatch({ type: 'GET_ROLES_START' });
          setIsProcessing(true);
        },
        apiCall: () => get(url),
        onFinish: resp => {
          dispatch({ type: 'GET_ROLES_FINISH', payload: resp });
          setData(resp.roles);
          setIsProcessing(false);
        },
        onFail: error => {
          dispatch({ type: 'GET_ROLES_FAIL', error });
          setIsProcessing(false);
          setError(error);
        },
      });
    } else {
      setData([])
    }
    
  }, [update, dispatch, setIsProcessing, url, rolesAllowed]);

  return [data, error];
};

const Roles = ({ className }) => {
  const [modalCurd, setModalCurd] = useState(modalCrudInitData);
  const [update, setUpdate] = useState(Date.now());
  const [isProcessing, setIsProcessing] = useState();
  const dispatch = useDispatch();

  const handleOnFinish = () => {
    setIsProcessing(false);
    setUpdate(update => update + 1);
    setModalCurd({ ...modalCrudInitData });
  };
  const handleOnStart = () => {
    setIsProcessing(true);
  };
  const handleOnFail = () => {
    setIsProcessing(false);
  };

  const handleOnRoleCreate = values => {
    const reqBody = values;

    const url = endpoints.customer.roleCreate;

    apiCallWrapper({
      notify: true,
      onStart: () => {
        dispatch({ type: 'ROLE_CREATE_START', reqBody });
        handleOnStart();
      },
      apiCall: () => post(url, reqBody),
      onFinish: resp => {
        dispatch({ type: 'ROLE_CREATE_FINISH', resp: resp });
        handleOnFinish();
        toast.success('Employee role changed');
      },
      onFail: error => {
        dispatch({ type: 'ROLE_CREATE_FAIL', error });
        handleOnFail();
      },
    });
  };

  const handleOnRoleUpdate = values => {
    const reqBody = { id: values.id || values._original.id, ...values };

    const url = endpoints.customer.roleUpdate;

    apiCallWrapper({
      notify: true,
      onStart: () => {
        dispatch({ type: 'ROLE_UPDATE_START', reqBody });
        handleOnStart();
      },
      apiCall: () => post(url, reqBody),
      onFinish: resp => {
        dispatch({ type: 'ROLE_UPDATE_FINISH', resp: resp });
        handleOnFinish();
        toast.success('Employee role changed');
      },
      onFail: error => {
        dispatch({ type: 'ROLE_UPDATE_FAIL', error });
        handleOnFail();
      },
    });
  };

  const handleOnRoleDelete = role_id => {
    const reqBody = {
      role_id,
    };

    const url = endpoints.customer.roleDelete;

    apiCallWrapper({
      notify: true,
      onStart: () => {
        dispatch({ type: 'ROLE_DELETE_START', reqBody });
        handleOnStart();
      },
      apiCall: () => post(url, reqBody),
      onFinish: resp => {
        dispatch({ type: 'ROLE_DELETE_FINISH', resp: resp });
        handleOnFinish();
        toast.success('Employee role changed');
      },
      onFail: error => {
        dispatch({ type: 'ROLE_DELETE_FAIL', error });
        handleOnFail();
      },
    });
  };

  const [roles, rolesError] = useGetRoles(update, setIsProcessing);

  return (
    <div className={className}>
      <LayoutAccountStyled>
        <div className={clsx('row', 'row-top')}>
          <h2>Roles</h2>
          <LayoutAccountButtonCreate
            disabled={rolesError}
            setModalCrud={() =>
              setModalCurd({
                ...modalCrudInitData,
                isOpen: true,
                onSubmit: values => handleOnRoleCreate(values),
                formFieldsMarkup: formFields,
                title: 'Add new role',
              })
            }
          />
        </div>
        {rolesError ? (
          <ErrorWrapper>You do not have such permission</ErrorWrapper>
        ) : (
          <TableWrapper>
            <TableVertical
              data={roles}
              columns={tableColumns}
              onClickRow={values => {
                values &&
                  setModalCurd({
                    isOpen: true,
                    data: values,
                    onSubmit: handleOnRoleUpdate,
                    onDelete: () => handleOnRoleDelete(values.id),
                    formFieldsMarkup: formFields,
                    title: values.description,
                    isDelete: true,
                  });
              }}
            />
          </TableWrapper>
        )}
        {modalCurd.isOpen && (
          <ModalFormCrudStyled
            loading={isProcessing}
            onClose={() => setModalCurd({ isOpen: false })}
            {...modalCurd}
          />
        )}
      </LayoutAccountStyled>
    </div>
  );
};

const ModalFormCrudStyled = styled(ModalFormCrud)`
  form .fieldsWrapper {
    gap: 0.5rem;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr 1fr;

    .fieldInputCommon:first-child {
      grid-column: 1 / -1;
    }
  }
`;

const TableWrapper = styled.div`
  overflow-x: auto;
`;

const RolesStyled = styled(Roles)`
  position: relative;
  min-height: 500px;

  .ReactTable .rt-thead .rt-resizable-header-content {
    text-overflow: initial;
    word-break: break-word;
    white-space: normal;
    align-items: center;
    text-align: center;
  }
`;

const TableVertical = ({ data, loading, columns, onClickRow }) => {
  if (loading || !data) {
    return (
      <TableVerticalStyled>
        <Preloader />
      </TableVerticalStyled>
    );
  }

  return (
    <TableVerticalStyled>
      <tbody>
        {columns.map((header, rowIndex) => {
          const Cell = header.Cell;
          return (
            <tr key={rowIndex} style={{ backgroundColor: header.bg || 'none' }}>
              <th>{header.Header && header.Header}</th>
              {header.accessor &&
                data.map((td, colIndex) => {
                  return (
                    <td
                      style={{
                        textAlign: 'center',
                        textTransform: 'uppercase',
                      }}
                      className={clsx(rowIndex === 0 && 'col')}
                      key={colIndex}
                      onClick={() => onClickRow(data[colIndex])}
                    >
                      {td[header.accessor] && Cell ? (
                        <Cell value={td[header.accessor]} />
                      ) : (
                        td[header.accessor]
                      )}
                    </td>
                  );
                })}
            </tr>
          );
        })}
      </tbody>
    </TableVerticalStyled>
  );
};

const TableVerticalStyled = styled.table`
  position: relative;
  width: 100%;
  min-height: 400px;
  border-collapse: collapse;
  border-spacing: 0;
  overflow: hidden;
  z-index: 1;

  tbody {
    width: max-content;
    border-collapse: collapse;
    border-spacing: 0;
    overflow: hidden;
    z-index: 1;
  }

  tr {
    height: 40px;

    th {
      //color: #5abdd7;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  th,
  td {
    padding: 0 0.5rem;
    text-align: left;
    min-width: 200px;
    z-index: 1;
    position: relative;
    pointer-events: none;
  }

  .col {
    cursor: pointer;
    pointer-events: all;
  }

  .col::after {
    background-color: transparent;
    content: ' ';
    height: 10000px;
    left: 0;
    position: absolute;
    top: -5000px;
    width: 100%;
    z-index: -1;
  }

  .col:hover::after {
    background-color: rgba(90, 189, 215, 0.25);
    content: ' ';
    height: 10000px;
    left: 0;
    position: absolute;
    top: -5000px;
    width: 100%;
    z-index: -1;
  }
`;

export default RolesStyled;
