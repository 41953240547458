import _ from 'lodash';

export const initState = {
  search: '',
  period: 'all',
  status: 'all',
  category: 'all',
  sub_category: 'all',
  code: 'all',
  sub_code: 'all',
  offset: 0,
  till: 0,
  from: 0,
  data: [],
  total: 0,
  categories: [],
  sub_categories: [],
  codes: [],
  sub_codes: [],
  sortOrder: true,
  sortBy: '',
  isProcessing: false,
  updatedAt: Date.now(),
};

export const constants = {
  SET_STATUS: 'SET_STATUS',
  SET_CATEGORIES_LIST: 'SET_CATEGORIES_LIST',
  SET_CATEGORY: 'SET_CATEGORY',
  SET_SUB_CATEGORY: 'SET_SUB_CATEGORY',
  SET_CODES_LIST: 'SET_CODES_LIST',
  SET_CODE: 'SET_CODE',
  SET_SUB_CODE: 'SET_SUB_CODE',
  SET_PERIOD: 'SET_PERIOD',
  SET_DATA: 'SET_DATA',
  SET_SEARCH: 'SET_SEARCH',
  SET_SORT: 'SET_SORT',
  SET_IS_PROCESSING: 'SET_IS_PROCESSING',
  SET_OFFSET: 'SET_OFFSET',
  FORCE_UPDATE: 'FORCE_UPDATE',
};

export const actions = {
  setForceUpdate: () => ({
    type: constants.FORCE_UPDATE,
  }),
  setOffset: offset => ({
    type: constants.SET_OFFSET,
    payload: offset,
  }),
  setSort: sort => ({
    type: constants.SET_SORT,
    payload: sort,
  }),
  setData: (data, total) => ({
    type: constants.SET_DATA,
    payload: { data, total },
  }),
  setCategoriesList: (categories, sub_categories) => ({
    type: constants.SET_CATEGORIES_LIST,
    payload: { categories, sub_categories }
  }),
  setCodesList: (codes, sub_codes) => ({
    type: constants.SET_CODES_LIST,
    payload: { codes, sub_codes }
  }),
  setIsProcessing: isProcessing => ({
    type: constants.SET_IS_PROCESSING,
    payload: isProcessing,
  }),
  setStatus: payload => ({ type: constants.SET_STATUS, payload: payload }),
  setCategory: payload => ({ type: constants.SET_CATEGORY, payload: payload }),
  setSubCategory: payload => ({ type: constants.SET_SUB_CATEGORY, payload: payload }),
  setCode: payload => ({ type: constants.SET_CODE, payload: payload }),
  setSubCode: payload => ({ type: constants.SET_SUB_CODE, payload: payload }),
  setPeriod: payload => ({ type: constants.SET_PERIOD, payload: payload }),
  setSearch: payload => ({ type: constants.SET_SEARCH, payload: payload }),
};

function reducer(state = initState, action) {
  switch (action.type) {
    case constants.SET_DATA:
      return {
        ...state,
        ...action.payload,
        isProcessing: false,
      };
    case constants.SET_CATEGORIES_LIST:
      return {
        ...state,
        ...action.payload,
        isProcessing: false
      }
    case constants.SET_CODES_LIST:
      return {
        ...state,
        ...action.payload,
        isProcessing: false
      }
    case constants.SET_IS_PROCESSING:
      return {
        ...state,
        isProcessing: action.payload,
      };
    case constants.SET_OFFSET:
      return {
        ...state,
        offset: action.payload,
      };
    case constants.SET_SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    case constants.SET_STATUS:
      return {
        ...state,
        status: action.payload,
        offset: 0,
      };
    case constants.SET_CATEGORY:
        return {
          ...state,
          category: action.payload,
          offset: 0,
        };
    case constants.SET_SUB_CATEGORY:
        return {
          ...state,
          sub_category: action.payload,
          offset: 0,
        };
    case constants.SET_CODE:
          return {
            ...state,
            code: action.payload,
            offset: 0,
          };
    case constants.SET_SUB_CODE:
          return {
            ...state,
            sub_code: action.payload,
            offset: 0,
          };
    case constants.SET_PERIOD:
      return {
        ...state,
        period: _.get(action, ['payload', 'period'], 'all'),
        till: _.get(action, ['payload', 'till'], 0),
        from: _.get(action, ['payload', 'from'], 0),
        offset: 0,
      };
    case constants.SET_SORT:
      return {
        ...state,
        sortBy: _.get(action, ['payload', 'by'], ''),
        sortOrder: _.get(action, ['payload', 'order'], ''),
      };
    case constants.FORCE_UPDATE:
      return {
        ...state,
        updatedAt: Date.now(),
      };
    default:
      return state;
  }
}

export default reducer;
