import { useDispatch } from 'react-redux';
import { useEffect, useRef } from 'react';
import { apiCallWrapper, get } from '../../../utils/fetch';

const useFetchKeys = (url, setDevKey, setError, setIsProcessing, isError) => {
  const dispatch = useDispatch();
  const thisRef = useRef({ isApiProcessing: false });

  useEffect(() => {
    if (!thisRef.current.isApiProcessing) {
      apiCallWrapper({
        apiCall: () => get(url),
        onStart: () => {
          thisRef.current.isApiProcessing = true;
          setIsProcessing(true);
          dispatch({ type: 'START_FETCH_KEYS' });
        },
        onFinish: resp => {
          dispatch({ type: 'FINISH_FETCH_KEYS', resp });
          setDevKey({
            id: resp.client_id,
            enabled: resp.enabled,
          });
          setIsProcessing(false);
          thisRef.current.isApiProcessing = false;
        },
        onFail: e => {
          dispatch({ type: 'FAIL_FETCH_KEYS', e });
          setError(e);
          setIsProcessing(false);
          thisRef.current.isApiProcessing = false;
        },
      });
    }
  }, [isError, dispatch, setDevKey, setError, setIsProcessing, url]);
};

export default useFetchKeys;
