import React from 'react';

const ArrowLeft = ({ className }) => {
  return (
    <div className={className}>
      <svg
        width="6"
        height="9"
        viewBox="0 0 6 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M6 0L-1.96701e-07 4.5L6 9L6 0Z" fill="#5ABDD7" />
      </svg>
    </div>
  );
};

export default ArrowLeft;
