import React from 'react';

import { Typography } from '@material-ui/core';

import LayoutsPage from 'layouts/Page';

const PageNotFound = () => {
  const headerNavLinkParams = {
    headerNavLinkByRouteTitle: 'Sign In',
  };

  return (
    <LayoutsPage headerNavLinkParams={headerNavLinkParams}>
      <Typography align={'center'} variant={'h2'}>
        Page not found
      </Typography>
    </LayoutsPage>
  );
};

export default PageNotFound;
