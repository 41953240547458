import { css } from 'styled-components';

const components = {
  AppBar: {
    bg: 'white',
  },
};

const layoutBreakpointsShared = css`
  width: 100%;
  margin: 0 auto;
`;

export const layoutBreakpoints = {
  desktop: css`
    ${layoutBreakpointsShared};
    padding: 0 2rem;
    max-width: 1280px;
  `,
  mobile: css`
    ${layoutBreakpointsShared}
  `,
};

const colors = {
  primary: '#5BBDD7',
  secondary: '#EB5757',
  third: '#FAB833',
  fourth: '#B8B4B3',
};

const theme = {
  colors,
  components,
  layoutBreakpoints,
};

export default theme;
