import React, { useEffect } from 'react';
import config from './config';
import PageNotFound from './containers/404';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsAuthSuccess,
  selectIsLoading,
} from './store/selectors/profile';
import {
  onProfileBalanceUpdate,
  setProfileAutoSignIn,
} from './store/actions/profile';
import { parseRoutes } from './@src/utils/routes';
import {
  routesPathAccount,
  routesPathDeveloper,
  routesPathPublic,
} from './@src/config/routesPath';
import { makeRoutesPrivacy } from './utils/routes';
import Preloader from './components/Preloader';
import { routes } from './config/routes';
import { Switch, Redirect, Route } from 'react-router-dom';

const RoutesWrapper = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector(selectIsLoading);
  const isAuthSuccess = useSelector(selectIsAuthSuccess);

  useEffect(() => {
    setTimeout(() => {
      dispatch(setProfileAutoSignIn());
    }, 300);
  }, [dispatch]);

  useEffect(() => {
    if (isAuthSuccess) {
      dispatch(onProfileBalanceUpdate());
    }
  });

  const renderRoutes = React.useMemo(
    () =>
      parseRoutes(
        [...routesPathDeveloper, ...routesPathAccount],
        isAuthSuccess,
        '/dashboard',
      ),
    [isAuthSuccess],
  );

  const renderRoutesOld = React.useMemo(
    () => makeRoutesPrivacy(routes, isAuthSuccess),
    [isAuthSuccess],
  );

  const renderRoutesPublic = React.useMemo(
    () => parseRoutes(routesPathPublic, true, ''),
    [],
  );

  if (isLoading) {
    return <Preloader />;
  }

  return (
    <Switch>
      {renderRoutes}
      {renderRoutesPublic}
      {renderRoutesOld}
      {isAuthSuccess ? (
        <Redirect exact={true} to={config.defaultRedirectPrivateRoute} />
      ) : (
        <Redirect exact={true} to={config.defaultRedirectPublicRoute} />
      )}
      <Route exact={false} path="/" component={PageNotFound} />
    </Switch>
  );
};

export default RoutesWrapper;
