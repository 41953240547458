import config from '@src/config';
import { toast } from 'react-toastify';
import history from '../../utils/history';
import csrfTokenCall from './csrfTokenCall';
import systemLoginCall from './systemLoginCall';

export const apiCallWrapper = async ({
  apiCall,
  onFail = null,
  onFinish = null,
  onStart = () => {},
  onAny = () => {},
  notify = false,
  redirectTo = null,
  areSessionExpiredRedirect = true,
  addMsg = null,
  csrfToken = false,
  systemLogin = false
}) => {
  onStart();

  if (addMsg === 'apiCallWrapper: test error outside try/catch') {
    throw new Error('apiCallWrapper: test error outside try/catch ');
  }

  try {
    if (systemLogin) {
      await systemLoginCall()
    }
    if (csrfToken) {
      await csrfTokenCall();
    }
    const resp = await apiCall();
    onAny();
    if (onFinish) {
      return onFinish(resp);
    }
    return resp;
  } catch (error) {
    onAny();
    const errRet = fetchErrorsHandler({
      error,
      notify,
      redirectTo,
      areSessionExpiredRedirect,
      addMsg,
      customErrorHandler: onFail,
    });

    if (errRet) {
      console.error('text error returned from fetchErrorsHandler: ', errRet);
      // throw new Error(errRet);
      return errRet;
    }
  }
};

const doNotify = (isNeedNotify, errMsg) => {
  if (isNeedNotify) {
    toast.error(errMsg);
  }
};

export const fetchErrorsHandler = ({
  error,
  redirectTo,
  areSessionExpiredRedirect,
  notify: optNotify,
  customErrorHandler,
  addMsg,
}) => {
  console.error('fetchErrorHandler: ', error);
  let notify = optNotify;
  let errMsg = parseResponseStatusMessage(error);

  /*
  switch (error.code) {
    case 401:
    case 400:
      notify = true;
      // errMsg = 'Wrong credentials';
      break;
    case 500:
      notify = true;
      if (!errMsg) {
        errMsg = 'Network connection error';
      }
      break;
    case 440:
      errMsg = 'Session expired';
      if (areSessionExpiredRedirect) {
        history.push('/log_out');
      }
      break;
    default:
      if (!errMsg) {
        /!* errMsg = 'And I like totally told you, Ya: "probably like wrong endpoint or something totally", Ya, like, totally!';*!/
        errMsg = 'Something went wrong. Please, try again later';
      }
      break;
  }
  */

  if (error && error.err_code) {
    switch (error.err_code) {
      case 'UNKNOWN':
        notify = true;
        errMsg = 'Oops, something went wrong. Please try again.';
        break;
      case 'INVALID_JSON':
        notify = true;
        errMsg =
          'Oops, there is something wrong with your request. Please try again.';
        break;
      case 'BAD_PASSWORD_OR_USER_NOT_FOUND':
        notify = true;
        errMsg = 'Invalid email or password. Please try again.';
        break;
      case 'SOMETHING_WENT_WRONG':
        notify = true;
        errMsg = 'Oops, something went wrong. Please try again.';
        break;
      case 'INVALID_INPUT':
        notify = false;
        errMsg = 'INVALID_INPUT';
        break;
      case 'INSUFFICIENT_PERMISSIONS':
        notify = true;
        errMsg =
          'You are not allowed to perform this action. Please contact your administrator.';
        break;
      case 'CONSENT_BUSINESS_MISMATCH':
        notify = true;
        errMsg =
          "The user's email address already has an account with us and has been setup as a business/personal email address. Please use the personal/business email address instead.";
        break;
      case 'DATA_NOT_FOUND':
        notify = true;
        errMsg =
          'User has not yet given TrustLoop consent to access their bank data. Please try again later.';
        break;
      case 'CUSTOMER_NOT_FOUND':
        notify = true;
        errMsg =
          'Customer ID not found. Please try with a different Customer ID.';
        break;
      case 'CONSENT_NOT_FOUND':
        notify = true;
        errMsg =
          'Consent ID not found. Please try with a different consent ID.';
        break;
      case 'PSU_NOT_FOUND':
        notify = true;
        errMsg = 'User ID not found. Please try with a different User ID.';
        break;
      case 'CONSENT_NOT_CONFIRMED':
        notify = true;
        errMsg =
          'User has not yet given TrustLoop consent to access their bank data. Please try again later.';
        break;
      case 'BRANCH_NOT_FOUND':
        notify = true;
        errMsg = 'Branch does not exist.';
        break;
      case 'ZERO_BALANCE':
        notify = true;
        errMsg =
          "Sorry, you don't have enough credits. Please top up by going to Account > Billing.";
        break;
      case 'USER_ALREADY_CONFIRMED':
        notify = true;
        errMsg = 'This email address is already registered. Please sign in.';
        break;
      case 'INVALID_CONSENT_STATUS':
        notify = true;
        errMsg = 'Consent Status not recognised.';
        break;
      case 'USER_ALREADY_EXISTS':
        notify = true;
        errMsg = 'This email address is already registered. Please sign in.';
        break;
      case 'INVALID_CODE':
        notify = true;
        errMsg = 'Incorrect code. Please re-enter or resend to get a new code.';
        break;
      case 'TOO_MANY_REQUESTS':
        notify = true;
        errMsg = 'Oops, something went wrong. Please try again.';
        break;
      case 'INVALID_JWT':
        notify = true;
        errMsg = 'Invalid user name or password. Please try again.';
        break;
      case 'USER_NOT_CONFIRMED':
        notify = false;
        if (areSessionExpiredRedirect) {
          notify = true;
        }
        errMsg =
          'User has not finished the registration process. Please check your email or SMS for the verification code.';
        break;
      case 'MISSING_SESSION_COOKIE':
        notify = false;
        errMsg = 'Your session has expired. Please sign in again.';
        if (areSessionExpiredRedirect) {
          notify = true;
          history.push('/log_out');
        }
        break;
      default:
        break;
    }
  } else {
    switch (error.code) {
      case 401:
      case 400:
        notify = true;
        // errMsg = 'Wrong credentials';
        break;
      case 500:
        notify = true;
        if (!errMsg) {
          errMsg = 'Network connection error';
        }
        break;
      case 440:
        errMsg = 'Session expired';
        if (areSessionExpiredRedirect) {
          history.push('/log_out');
        }
        break;
      default:
        if (!errMsg) {
          /* errMsg = 'And I like totally told you, Ya: "probably like wrong endpoint or something totally", Ya, like, totally!';*/
          errMsg = 'Something went wrong. Please, try again later';
        }
        break;
    }
  }

  console.error({ errMsg, code: error.code, error, addMsg });

  doNotify(notify, errMsg);

  if (redirectTo) {
    history.push(redirectTo);
  }

  let ret = null;
  if (customErrorHandler) {
    ret = customErrorHandler(error);
  }

  // return ret || new Error(errMsg);
  return ret || errMsg;
};

export const parseResponseStatusMessage = ({ code, text }) =>
  (code && text && `${text}`) || null;

export const http = async (url, options) =>
  new Promise((resolve, reject) => {
    let response;
    fetch(url, options)
      .then(resp => {
        response = resp;
        return resp
          .json()
          .then(r => {
            return r;
          })
          .catch(e => {
            if (response.ok) {
              return null;
            }
            return e;
          });
      })
      .then(resp => {
        if (response.ok) {
          resolve((resp && resp.body) || resp || null);
          return;
        }

        reject((resp && resp.status) || resp || response);
      })
      .catch(error => {
        reject(error);
      });
  });

const httpTimeout = async (
  url,
  options,
  timeout = config.fetchSettings.timeoutBeforeAutoReject,
) =>
  Promise.race([
    http(url, options),
    new Promise((_, reject) =>
      setTimeout(() => reject('connection_timeout'), timeout),
    ),
  ]);

export const post = async (url, body, timeout) =>
  httpTimeout(
    url,
    {
      ...config.fetchSettings.common,
      ...config.fetchSettings.post,
      body: JSON.stringify(body),
    },
    timeout,
  );

  export const postFormData = async (url, body, timeout) => {
    const data = new FormData();
    for (const [name, value] of Object.entries(body)) {
      data.append(name, value);
    }
    httpTimeout(
      url,
      {
        ...config.fetchSettings.common,
        ...config.fetchSettings.post,
        body: data,
      },
      timeout,
    );
  }

export const get = async (url, timeout) =>
  httpTimeout(
    url,
    {
      ...config.fetchSettings.common,
      ...config.fetchSettings.get,
    },
    timeout,
  );

export const del = async (url, timeout) => {
  return httpTimeout(
    url,
    {
      ...config.fetchSettings.common,
      ...config.fetchSettings.del,
    },
    timeout,
  );
};
