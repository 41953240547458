import React from 'react';

const UserMenuLogo = ({ className }) => {
  return (
    <div className={className}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="20" cy="20" r="20" fill="#F4F4F4" />
        <path
          d="M19.9999 13.6843C21.7447 13.6843 23.1578 15.0975 23.1578 16.8422C23.1578 18.587 21.7447 20.0001 19.9999 20.0001C18.2552 20.0001 16.842 18.587 16.842 16.8422C16.842 15.0975 18.2552 13.6843 19.9999 13.6843ZM19.9999 26.3159C19.9999 26.3159 26.3157 26.3159 26.3157 24.737C26.3157 22.8422 23.2368 20.7896 19.9999 20.7896C16.7631 20.7896 13.6841 22.8422 13.6841 24.737C13.6841 26.3159 19.9999 26.3159 19.9999 26.3159Z"
          fill="#3C3C3B"
        />
      </svg>
    </div>
  );
};

export default UserMenuLogo;
