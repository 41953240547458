import React from 'react';

const initState = {
  pageType: 'common',
  pageTitle: 'TrustLoop',
  isProcessing: true,
};

export const LayoutsTemplatesContext = React.createContext({
  state: initState,
  dispatch: () => {},
});

export const layoutSetPageType = pageType => ({
  type: 'LAYOUT_SET_PAGE_TYPE',
  pageType,
});
export const layoutSetPageIsProcessing = isProcessing => ({
  type: 'LAYOUT_SET_PAGE_IS_PROCESSING',
  isProcessing,
});

export const layoutSetPageTitle = pageTitle => ({
  type: 'LAYOUT_SET_PAGE_TITLE',
  pageTitle,
});
export const layoutSetPageParams = pageParams => ({
  type: 'LAYOUT_SET_PAGE_PARAMS',
  pageParams,
});

const reducer = (state = initState, action) => {
  switch (action.type) {
    case 'LAYOUT_SET_PAGE_TYPE':
      return { ...state, pageType: action.pageType };
    case 'LAYOUT_SET_PAGE_TITLE':
      return { ...state, pageType: action.pageTitle };
    case 'LAYOUT_SET_PAGE_PARAMS':
      return { ...state, ...action.pageParams };
    case 'LAYOUT_SET_PAGE_IS_PROCESSING':
      return { ...state, isProcessing: action.isProcessing };
    default:
      return state;
  }
};

const LayoutsTemplates = ({ children }) => {
  const [layoutState, layoutDispatch] = React.useReducer(reducer, initState);
  const contextValue = {
    state: layoutState,
    dispatch: layoutDispatch,
  };

  return (
    <LayoutsTemplatesContext.Provider value={contextValue}>
      {children}
    </LayoutsTemplatesContext.Provider>
  );
};

export default LayoutsTemplates;
