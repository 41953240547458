import React, { Suspense } from 'react';
import Preloader from 'components/Preloader';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import LayoutsTemplates from 'layouts';
import config from 'config';
import styles from 'assets/styles_js/index';
import CustomToastContainer from './@src/components/CustomToastContainer';
import RoutesWrapper from './RoutesWrapper';
import ErrorBoundary from './components/ErrorBoundary';

const useStyles = makeStyles(styles);

function App() {
  const classes = useStyles();

  return (
    <div className={clsx('App', classes.root)}>
      <ErrorBoundary>
        <Suspense fallback={<Preloader />}>
          <LayoutsTemplates>
            <RoutesWrapper />
          </LayoutsTemplates>
        </Suspense>
      </ErrorBoundary>
      <CustomToastContainer autoClose={config.toastAutoCloseTimeout} />
    </div>
  );
}

export default App;
