import React, { useState } from 'react';
import { GridRowsWrapper, SectionWrapper, Separator } from '../../components';
import TextField from '@material-ui/core/TextField';
import Button from '../../../components/Button';
import { toast } from 'react-toastify';
import { useWebhook } from './api-hooks/use-webhook';
import { useSelector } from 'react-redux';
import { selectUserData } from '../../../store/selectors/profile';
import { useGetProfileData } from '../Account/General';

const urlValidationRegExp = new RegExp(
  `^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$`,
  'g',
);

const Webhooks = () => {
  const userData = useSelector(selectUserData);
  const [isProcessing, setIsProcessing] = useState(false);
  const [callWebhook] = useWebhook(null, null, setIsProcessing);
  const [webhookUrl, setWebhookUrl] = useState(
    (userData && userData.webhook_url) || '',
  );
  const [webhookSecret, setWebhookSecret] = useState(
    (userData && userData.webhook_secret) || '',
  );
  const [validError, setValidError] = useState(null);
  const [update, setUpdate] = useState(0);
  useGetProfileData(update, setIsProcessing);

  const handleOnValid = input => {
    if (!input.match(urlValidationRegExp) || input.match(/\s/g)) {
      setValidError('Please enter valid Webhook URL');
      return false;
    }
    setValidError(null);
    return true;
  };

  return (
    <>
      <Separator />
      <SectionWrapper>
        <GridRowsWrapper gap={'2rem'}>
          <TextField
            error={!!validError}
            value={webhookUrl}
            onChange={e => {
              handleOnValid(e.target.value);
              setWebhookUrl(e.target.value);
            }}
            name={'webhook-url'}
            label={'Webhook URL'}
            helperText={validError}
          />
          <TextField
            value={webhookSecret}
            onChange={e => setWebhookSecret(e.target.value)}
            name={'webhook-url-secret'}
            label={'Secret key'}
          />
          <Button
            disabled={!webhookUrl}
            loading={isProcessing}
            onClick={async () => {
              if (!webhookUrl.match(urlValidationRegExp)) {
                setValidError('Please enter valid Webhook URL');
                toast.error('Please enter valid Webhook URL');
                return;
              }
              try {
                await callWebhook({
                  url: webhookUrl,
                  secret: webhookSecret,
                });
                setUpdate(update => update + 1);
                setValidError(null);
                toast.success('Webhook URL saved!');
              } catch (e) {
                toast.error(
                  'Error while saving of Webhooks, please try again later',
                );
              }
            }}
          >
            Save
          </Button>
        </GridRowsWrapper>
      </SectionWrapper>
    </>
  );
};

export default Webhooks;
