import React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';

const LayoutAccount = ({ className, children }) => {
  return <div className={clsx(className, 'LayoutAccount')}>{children}</div>;
};

const LayoutAccountStyled = styled(LayoutAccount)`
  .table {
    .rt-th {
      text-align: left;
    }
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 300;
  }

  .row {
    display: flex;
    flex-flow: row nowrap;
    flex: 0 0 auto;
    padding-bottom: 1rem;

    &.row-top {
      justify-content: space-between;
    }
    &.row-sec {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: max-content;
      grid-gap: 3rem;
      max-width: max-content;
      button {
        white-space: nowrap;
        width: max-content;
      }

      .MuiTypography-root.MuiFormControlLabel-label {
        font-size: 0.9rem;
        color: #b8b4b2;
      }
    }
  }
`;

export default LayoutAccountStyled;
