import { toast } from 'react-toastify';
import {
  actionTypes,
  setProfileBalance,
  setProfileUserData,
  setProfileIsProcessing,
} from 'store/actions/profile';
import { get, post } from '@src/utils/fetch.js';
import { fetchSettings } from '@src/config';
import appConfig from '../../config';
import { handleMessages } from 'utils/messages';
import history from 'utils/history';
import { apiCallWrapper } from '@src/utils/fetch';
import endpoints from '../../@src/config/endpoints';
import { setProfileProviders } from '../actions/profile';
import config from '../../@src/config';

const { apiUrl, ROUTES_PATHS, ENDPOINTS } = appConfig;

export const mapUserData = userData => ({
  customer: userData.customer,
  permissions: userData.permissions,
});

const profile = ({ getState, dispatch }) => next => async action => {
  const autoSignIn = async () => {
    const urlFetchUserProfile = ENDPOINTS.FETCH_USER_PROFILE_DATA;
    dispatch({ type: 'ON_PROFILE_TRY_AUTO_SIGN_IN' });
    try {
      const [
        userData,
        // providers
      ] = await Promise.all([
        await apiCallWrapper({
          apiCall: () => get([apiUrl, urlFetchUserProfile].join('/'), 5000),
          onFail: e => Promise.reject(e),
          areSessionExpiredRedirect: false,
        }),
        /*
        apiCallWrapper({
          apiCall: () => get(endpoints.common.providers),
          onFail: e => Promise.reject(e),
          areSessionExpiredRedirect: false,
        }),
        */
      ]);
      dispatch(setProfileUserData(mapUserData(userData)));
      // dispatch(setProfileProviders(providers));
    } catch (e) {
      console.warn('Auto sign in failed, reason: ', e);
      dispatch(
        setProfileUserData(mapUserData({ customer: null, permissions: null })),
      );
      // dispatch(setProfileProviders(null));
      dispatch(setProfileBalance({ free: null, prepaid: null }));
    }
  };

  const validationCodeFromSms = data =>
    apiCallWrapper({
      onStart: () => dispatch(setProfileIsProcessing(true)),
      onAny: () => dispatch(setProfileIsProcessing(false)),
      apiCall: () => post([apiUrl, ENDPOINTS.VALIDATION_SMS].join('/'), data),
      onFinish: resp => {
        dispatch(setProfileUserData(mapUserData(resp)));
        toast.success(handleMessages('validationSuccess'));
      },
      systemLogin: true
    });

  const fetchProviders = data =>
    apiCallWrapper({
      apiCall: () => get(endpoints.common.providers),
      onFinish: resp => {
        dispatch(setProfileProviders(resp));
      },
    });

  const signUp = data =>
    apiCallWrapper({
      onStart: () => dispatch(setProfileIsProcessing(true)),
      onAny: () => dispatch(setProfileIsProcessing(false)),
      apiCall: () => post([apiUrl, ENDPOINTS.SIGN_UP].join('/'), data),
      onFinish: resp => {
        dispatch(setProfileUserData(mapUserData(resp)));
        toast.success(handleMessages('signUpSuccess'));
        history.push(ROUTES_PATHS.AUTH.VALIDATION);
      },
      notify: true,
      csrfToken: true
    });

  const forgotPassword = data =>
    apiCallWrapper({
      onStart: () => dispatch(setProfileIsProcessing(true)),
      onAny: () => dispatch(setProfileIsProcessing(false)),
      apiCall: () => post([apiUrl, ENDPOINTS.FORGOT_PASSWORD].join('/'), data),
      onFinish: () => {
        toast.success(handleMessages('forgotPasswordSuccess'));
        history.push(ROUTES_PATHS.AUTH.SIGN_IN);
      },
      systemLogin: true
    });

  const resetPassword = data =>
    apiCallWrapper({
      onStart: () => dispatch(setProfileIsProcessing(true)),
      onAny: () => dispatch(setProfileIsProcessing(false)),
      apiCall: () => post([apiUrl, ENDPOINTS.RESET_PASSWORD].join('/'), data),
      onFinish: () => {
        toast.success(handleMessages('resetPasswordSuccess'));
        history.push(ROUTES_PATHS.AUTH.SIGN_IN);
      },
      systemLogin: true
    });

  const signIn = data =>
    apiCallWrapper({
      onStart: () => dispatch(setProfileIsProcessing(true)),
      onAny: () => dispatch(setProfileIsProcessing(false)),
      apiCall: () => post([apiUrl, ENDPOINTS.SIGN_IN].join('/'), data),
      onFinish: resp => {
        setTimeout(() => {
          dispatch(setProfileIsProcessing(false));
          dispatch(setProfileUserData(mapUserData(resp)));
        }, 0);
      },
      csrfToken: true
    });

  const updateBalance = () => {
    apiCallWrapper({
      onStart: () => dispatch(setProfileIsProcessing(true)),
      onAny: () => dispatch(setProfileIsProcessing(false)),
      apiCall: () => get([apiUrl, ENDPOINTS.GET_BALANCE].join('/')),
      onFinish: balance => {
        dispatch(setProfileBalance(balance));
      },
    });
  };

  const logOut = () => {
    const state = getState();

    apiCallWrapper({
      apiCall: () => {
        let options = {
          ...config.fetchSettings.common,
          ...fetchSettings.post,
        };
        options.body = JSON.stringify(state.profile.customer);
        fetch([apiUrl, ENDPOINTS.LOG_OUT].join('/'), options);
      },
    });
  };

  const result = next(action);
  switch (action.type) {
    case actionTypes.SET_PROFILE_VALIDATION_CODE_FROM_SMS:
      await validationCodeFromSms(action.data);
      break;
    case actionTypes.SET_PROFILE_SIGN_IN:
      await signIn(action.data);
      break;
    case actionTypes.SET_PROFILE_SIGN_UP:
      await signUp(action.data);
      break;
    case actionTypes.SET_PROFILE_FORGOT_PASSWORD:
      await forgotPassword(action.data);
      break;
    case actionTypes.SET_PROFILE_RESET_PASSWORD:
      await resetPassword(action.data);
      break;
    case actionTypes.SET_PROFILE_LOG_OUT:
      await logOut();
      break;
    case actionTypes.SET_PROFILE_AUTO_SIGN_IN:
      await autoSignIn(action.data);
      break;
    case actionTypes.ON_PROFILE_BALANCE_UPDATE:
      updateBalance();
      break;
    case actionTypes.ON_PROFILE_PROVIDERS_FETCH:
      fetchProviders();
      break;
    default:
      break;
  }
  return Promise.resolve(result);
};

export default profile;
