import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({
  component: Component,
  isAuthSuccess,
  redirect = '/',
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      isAuthSuccess === true ? (
        <Component {...props} />
      ) : (
        <Redirect to={redirect} />
      )
    }
  />
);

export default PrivateRoute;
