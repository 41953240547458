import styled from 'styled-components';

const Tab = styled.div`
  cursor: pointer;
  text-align: center;
  background-color: #f3f2f1;
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 165px;
  font-size: 1rem;
  position: relative;
  padding: 1rem;

  &::after {
    content: ' ';
    left: 0;
    right: 1px;
    top: 50%;
    bottom: 0;
    transform: translateY(-50%);
    z-index: 100;
    background-color: #b8b4b2;
    width: 1px;
    height: 20px;
    position: absolute;
  }

  &:first-child {
    &::after {
      display: none;
    }
  }

  &.active,
  &.active:first-child {
    z-index: 200;
    border: 1px solid #cccccc;
    border-bottom-color: white;
    background-color: white;
    position: relative;

    &::after {
      display: block;
      content: ' ';
      left: calc(100% + 1px);
      top: 50%;
      bottom: 0;
      transform: translateY(-50%);
      z-index: 100;
      background-color: #f3f2f1;
      width: 1px;
      height: 22px;
      position: absolute;
    }

    &:before {
      display: block;
      content: ' ';
      bottom: -2px;
      z-index: 100;
      background-color: white;
      width: 100%;
      height: 1px;
      position: absolute;
    }
  }
`;

export default Tab;
