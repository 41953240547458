import React, { useEffect } from 'react';
import * as actions from 'store/actions/profile';
import { useHistory, useLocation } from 'react-router-dom';
import ConfirmSvg from 'svg/Confirm';
import { H2, ModalStyled } from './components';
import { useDispatch } from 'react-redux';

const BillingSuccess = () => {
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.onProfileBalanceUpdate());
  }, [dispatch]);

  return (
    <>
      <ModalStyled
        open={true}
        onClose={() => history.push(location.params.prevPath)}
      >
        <div className={'inner-wrapper'}>
          <ConfirmSvg />
          <H2>Payment processed successfully</H2>
        </div>
      </ModalStyled>
    </>
  );
};

export default BillingSuccess;
