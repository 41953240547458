import { actionTypes } from 'store/actions/redirect';
import produce from 'immer';

const initialState = {
  redirect: null,
  lastUpdate: Date.now(),
};

const setRedirect = produce((draft, { data }) => {
  draft.redirect = data;
  draft.lastUpdate = Date.now();
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_REDIRECT:
      return setRedirect(state, action);
    default:
      return state;
  }
};

export default reducer;
