import React from 'react';
import { Autorenew } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles({
  animation: {
    animation: '__spin 2s linear infinite',
  },
});

export const AutoRenewIcon = ({ className, color = 'action' }) => {
  const classes = useStyles();
  return (
    <Autorenew color={color} className={clsx(classes.animation, className)} />
  );
};

export default AutoRenewIcon;
