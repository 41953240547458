import appConfig from 'config';
import { ENDPOINTS } from 'config/constants';
import { useEffect } from 'react';
import { apiCallWrapper, post } from '@src/utils/fetch';
import { defPageSize } from './Table';
import { actions } from 'components/Table/reducer';
import { useDispatch } from 'react-redux';

export const mapTableConsentsDataRespBody = resp => {
  const mapDataFromResp = data =>
    data.map(v => ({
      psu: v.psu_email,
      bank: v.status === 'consent-data-shared' ? v.bank : '',
      status: v.status,
      requested: v.created_at,
      expires_at: v.lifetime,
      consent_id: v.id,
      exp_notification: v.exp_notification,
      business: v.business,
      name: toPascalCase(v.psu_account.salutation+" "+v.psu_account.first_name+" "+v.psu_account.last_name)
    })); 
    
  const toPascalCase = str => (str.match(/[a-zA-Z0-9]+/g) || []).map(w => `${w.charAt(0).toUpperCase()}${w.slice(1)}`).join(' ');

  if (resp && Array.isArray(resp.consents)) {
    return {
      data: mapDataFromResp(resp.consents),
      total: resp.total,
    };
  }

  return {
    data: [],
    total: 0,
  };
};

const getSortBy = sortBy => {
  switch (sortBy) {
    case 'expires_at':
      return 'expires_at';
    default:
      return sortBy || 'requested';
  }
};

export const useDataFetch = ({ customerId, state, dispatch }) => {
  const {
    offset,
    sortBy,
    sortOrder,
    status,
    search,
    till,
    from,
    period,
    updatedAt,
  } = state;

  const endpoint = search
    ? [appConfig.apiUrl, ENDPOINTS.CONSENT].join('/')
    : [appConfig.apiUrl, ENDPOINTS.CONSENTS].join('/');
  const dispatchGlobal = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const { setData, setIsProcessing } = actions;

      apiCallWrapper({
        apiCall: () =>
          post(endpoint, {
            customer_id: customerId,
            status: status === 'all' ? '' : status,
            limit: defPageSize,
            offset,
            till,
            from,
            sort_by: getSortBy(sortBy),
            sort_order: sortOrder,
            psu_email: search,
          }),
        onStart: () => dispatch(setIsProcessing(true)),
        onFinish: resp => {
          if (resp) {
            const { data, total } = mapTableConsentsDataRespBody(resp);
            if (Array.isArray(data)) dispatch(setData(data, total));
          } else {
            dispatch(actions.setIsProcessing(false));
          }
        },
        onFail: () => {
          dispatch(actions.setIsProcessing(false));
          console.error('Error while trying to fetch consents list data');
        },
      });
    };

    fetchData();
  }, [
    customerId,
    dispatchGlobal,
    dispatch,
    endpoint,
    updatedAt,
    from,
    till,
    offset,
    search,
    status,
    sortOrder,
    sortBy,
    period,
  ]);
};
