import React from 'react';
import RoutePrivate from 'components/RoutePrivate';
import RoutePublic from 'components/RoutePublic';
import config from 'config';
import { Route } from 'react-router-dom';

export const makeRoutesPrivacy = (routes = config.routes, isAuthSuccess) =>
  routes.map((r, index) => {
    let Component = null;
    switch (r.type) {
      case 'public':
        Component = RoutePublic;
        break;
      case 'private':
        Component = RoutePrivate;
        break;
      case 'common':
        Component = Route;
        break;
      default:
        Component = Route;
        break;
    }

    return (
      <Component
        key={index}
        {...r}
        exact={r.isExact}
        component={React.lazy(r.component)}
        isAuthSuccess={isAuthSuccess}
      />
    );
  });
