export default {
  common: {
    root: {
      display: 'grid',
      gridTemplateColumns: '100%',
      gridTemplateRows: `max-content 1fr 100px`,
      gridTemplateAreas: '"header" "main" "footer"',
      height: '100vh',
      maxWidth: '100vw',
      width: '100vw',
      overflowX: 'hidden',
    },
    header: {
      gridArea: 'header',
    },
    main: {
      gridArea: 'main',
      display: 'flex',
      flexFlow: 'column',
    },
    footer: {
      overflowX: 'hidden',
      backgroundColor: 'white',
      gridArea: 'footer',
      textAlign: 'center',
    },
  },
  byPageTypes: {
    dashboard: {},
    authForm: {
      root: {
        display: 'grid',
        gridTemplate: `
        "header" max-content
        "main" 1fr
        "footer" 100px / 100%`,
        height: '100vh',
        maxWidth: '100vw',
        width: '100vw',
        overflowX: 'hidden',
      },
      footer: {
        gridArea: 'footer',
        display: 'flex',
        textAlign: 'center',
        alignItems: 'flex-end',
        '& footer': {
          backgroundColor: '#fafafa',
        },
      },
      header: {
        gridArea: 'header',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
      },
      main: {
        gridArea: 'main',
        display: 'flex',
        height: 'max-content',
        minHeight: '100%',
        flexFlow: 'column nowrap',
        paddingTop: '2rem',
        justifyContent: 'center',
        alignItems: 'center',
        '& .MuiFormControl': {
          marginTop: '2rem',
        },
      },
      form: {
        textAlign: 'center',
        '& form': {
          width: 'max-content',
          marginLeft: 'auto',
          marginRight: 'auto',
        },
        '& .textUnderTheForm': {
          paddingTop: '2rem',
        },
        '& .textAboveTheForm': {
          '& h2': {
            paddingTop: '1rem',
            lineHeight: '1.17',
          },
        },
      },
      paper: {
        marginTop: '5rem',
        width: 'max-content',
        marginLeft: 'auto',
        marginRight: 'auto',
        boxShadow: '0px 10px 20px rgba(184, 180, 179, 0.15)',
        padding: '3em 5em',
        marginBottom: '3em',
      },
    },
  },
};
