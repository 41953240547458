import DashboardIdentityCheck from 'containers/Dashboard/IdentityCheck';

import Account from '@src/containers/Account/Account';
import AccountRoles from '@src/containers/Account/Roles';
import AccountAffordabilities from '@src/containers/Account/Affordabilities';
import AccountGeneral from '@src/containers/Account/General';
import AccountEmployees from '@src/containers/Account/Employees';
import AccountBranches from '@src/containers/Account/Branches';
import AccountBilling from '@src/containers/Account/Billing/Billing';
import AccountBillingSuccess from '@src/containers/Account/Billing/BillingSuccess';
import AccountBillingFail from '@src/containers/Account/Billing/BillingFail';

import Developer from '@src/containers/Developer';
import DeveloperSandbox from '@src/containers/Developer/Sandbox';
import DeveloperProduction from '@src/containers/Developer/Prod';
import DeveloperGetStarted from '@src/containers/Developer/GetStarted';

import PublicTermsOfUse from '@src/containers/Public/TermsOfUse';
import PublicPrivacyPolicy from '@src/containers/Public/PrivacyPolicy';

export const routesPath_const = {
  DEVELOPER: '/developer',
  DEVELOPER_NESTED: {
    PRODUCTION: '/production',
    SANDBOX: '/sandbox',
    GET_STARTED: '/getStarted',
  },
  ACCOUNT: '/account',
  ACCOUNT_NESTED: {
    EMPLOYEES: '/employees',
    BRANCHES: '/branches',
    GENERAL: '/general',
    ROLES: '/roles',
    BILLING: '/billing',
    BILLING_NESTED: {
      SUCCESS: '/success',
      FAIL: '/fail',
    },
    AFFORDABILITIES: '/affordabilities',
  },
  DASHBOARD: '/',
  DASHBOARD_NESTED: {
    IDENTITY_CHECK: '/identity_check',
  },
  PUBLIC_TERMS_OF_USE: '/terms-of-use',
  PUBLIC_PRIVACY_POLICY: '/privacy-policy',
};

export const routesPathPublic = [
  {
    title: 'TERMS OF USE',
    exact: true,
    type: 'public',
    path: routesPath_const.PUBLIC_TERMS_OF_USE,
    component: PublicTermsOfUse,
  },
  {
    title: 'PRIVACY POLICY',
    exact: true,
    type: 'public',
    path: routesPath_const.PUBLIC_PRIVACY_POLICY,
    component: PublicPrivacyPolicy,
  },
];

export const routesPathDeveloper = [
  {
    title: 'DEVELOPER',
    exact: false,
    type: 'private',
    matchPath: [
      routesPath_const.DEVELOPER + '/:tab',
      routesPath_const.DEVELOPER,
    ],
    path: routesPath_const.DEVELOPER,
    component: Developer,
    nestedRoutes: [
      {
        title: 'PRODUCTION',
        exact: false,
        type: 'private',
        path: routesPath_const.DEVELOPER_NESTED.PRODUCTION,
        component: DeveloperProduction,
      },
      {
        title: 'SANDBOX',
        exact: false,
        type: 'private',
        path: routesPath_const.DEVELOPER_NESTED.SANDBOX,
        component: DeveloperSandbox,
      },
      {
        title: 'GETTING STARTED',
        exact: false,
        isRedirectToThisByDef: true,
        type: 'private',
        path: routesPath_const.DEVELOPER_NESTED.GET_STARTED,
        component: DeveloperGetStarted,
      },
    ],
  },
];

export const routesPathAccount = [
  {
    title: 'ACCOUNT',
    exact: false,
    type: 'private',
    matchPath: [routesPath_const.ACCOUNT + '/:tab', routesPath_const.ACCOUNT],
    path: routesPath_const.ACCOUNT,
    component: Account,
    nestedRoutes: [
      {
        title: 'BILLING',
        exact: false,
        type: 'private',
        path: routesPath_const.ACCOUNT_NESTED.BILLING,
        component: AccountBilling,
        nestedRoutes: [
          {
            title: 'BILLING SUCCESS',
            exact: true,
            type: 'private',
            component: AccountBillingSuccess,
            path: routesPath_const.ACCOUNT_NESTED.BILLING_NESTED.SUCCESS,
          },
          {
            title: 'BILLING FAIL',
            component: AccountBillingFail,
            exact: true,
            type: 'private',
            path: routesPath_const.ACCOUNT_NESTED.BILLING_NESTED.FAIL,
          },
        ],
      },
      {
        title: 'GENERAL',
        exact: false,
        type: 'private',
        isRedirectToThisByDef: true,
        path: routesPath_const.ACCOUNT_NESTED.GENERAL,
        component: AccountGeneral,
      },
      {
        title: 'EMPLOYEES',
        exact: false,
        type: 'private',
        path: routesPath_const.ACCOUNT_NESTED.EMPLOYEES,
        component: AccountEmployees,
      },
      {
        title: 'BRANCHES',
        exact: false,
        type: 'private',
        path: routesPath_const.ACCOUNT_NESTED.BRANCHES,
        component: AccountBranches,
      },
      {
        title: 'ROLES',
        exact: false,
        type: 'private',
        path: routesPath_const.ACCOUNT_NESTED.ROLES,
        component: AccountRoles,
      },
      {
        title: 'AFFORDABILITIES',
        exact: false,
        type: 'private',
        path: routesPath_const.ACCOUNT_NESTED.AFFORDABILITIES,
        component: AccountAffordabilities,
      },
    ],
  },
];

export const routesPathDashboard = [
  {
    title: 'BankLoop',
    path: [
      routesPath_const.DASHBOARD,
      routesPath_const.DASHBOARD_NESTED.IDENTITY_CHECK,
    ],
    type: 'private',
    exact: true,
    component: DashboardIdentityCheck,
  },
];
