/* eslint-disable no-undef */
const __ENV__ = process.env['REACT_APP_ENV'] || 'qa';
const __IS_LOCAL__ = process.env['REACT_APP_IS_LOCAL'];
/* eslint-enable no-undef */

const beUrls = {
  qa: 'https://dev-backend.open-banking-gateway.com',
  stage: 'https://stage-backend.open-banking-gateway.com',
  local: 'http://localhost:8080',
  prod: 'https://prod-backend.trustloop.io',
};

const getBeUrl = () => {
  if (__IS_LOCAL__) {
    return beUrls['local'];
  }

  return beUrls[__ENV__];
};

const be = getBeUrl();

const apiv = be + '/api/v0';
const _c = apiv + '/customer';
const _ds = apiv + '/customer/dev/sandbox';
const _dp = apiv + '/customer/dev/realdata';

const endpoints = {
  common: {
    providers: apiv + '/providers',
  },
  customer: {
    getXsrfToken: _c + '/token',
    systemLogin: _c + '/system/signin',
    reSendValidationCode: _c + '/resend_confirmation_code',
    rolesList: _c + '/company/roles',
    roleCreate: _c + '/company/role/create',
    roleDelete: _c + '/company/role/delete',
    roleUpdate: _c + '/company/role/update',
    employeeChangeBranch: _c + '/company/employee/branch',
    employeeChangeData: _c + '/company/employee',
    employeeChangeRole: _c + '/company/employee/role',
    employeeAdd: _c + '/company/employee/add',
    employeeRead: _c + '/company/employee/list',
    employeeDelete: _c + '/company/employee/delete',
    branchesRead: _c + '/branches',
    branchesAdd: _c + '/branches/add',
    branchesDelete: _c + '/branches/delete',
    branchesUpdate: _c + '/branches/update',
    branchesTransferCredits: _c + '/consent/balance/transfer',
    paymentInitiate: _c + '/consent/payment/initiate',
    listOfPricingPlans: _c + '/consent/pricing-plan',
    updatePersonalInfo: _c + '/update_personal_info',
    updateCompanyInfo: _c + '/company/update_info',
    updateCompanyLogo: _c + '/company/update_company_logo',
    updateEmailInfo: _c + '/company/email-settings',
    deleteAccount: _c + '/delete_main_account',
    categoriesRead: _c + '/analytics/consents/txs/categories',
    affordabilities: _c + '/analytics/consent/affordability',
    affordabilitiesUpdate: _c + '/analytics/consent/affordability/update',
    affordabilitiesDelete: _c + '/analytics/consent/affordability/delete',
  },
  dev: {
    webhook: _c + '/webhook',
    redirectFlow: _c + '/company/redirect',
    sandbox: {
      generate: _ds + '/generate_client',
      details: _ds + '/client_details',
      delete: _ds + '/delete_client',
    },
    production: {
      generate: _dp + '/generate_client',
      details: _dp + '/client_details',
      delete: _dp + '/delete_client',
    },
  },
};

export default endpoints;
