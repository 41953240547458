export default {
  menuButton: {
    padding: 0,
    minWidth: 'auto',
    backgroundColor: 'white',
  },
  svgIcon: {
    padding: '0',
    display: 'flex',
    height: '1rem',
    width: '1rem',
  },
  menuButtonText: {
    textAlign: 'right',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingRight: '1rem',
    textTransform: 'none',
    maxWidth: '200px',
    whiteSpace: 'nowrap',
  },
};
