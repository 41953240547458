import clsx from 'clsx';
import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { ArrowRight } from '@material-ui/icons';
import { Checkbox } from '@material-ui/core';

export const BuyButton = styled(({ className }) => (
  <div className={className}>
    Buy <ArrowRight />
  </div>
))`
  width: 100%;
  color: #5abdd7;
  font-weight: 500;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
`;

export const CellDeletedAt = ({ value }) => {
  const isDeleted =
    moment(value).valueOf() > 0
      ? moment(value).format('DD/MM/YYYY HH:mm')
      : false;

  return (
    <div className={clsx('isDeleted', (isDeleted && '--deleted') || '--ok')}>
      {isDeleted ? '✖' : `✔`}
    </div>
  );
};

export const CrudButton = styled(({ className }) => (
  <div className={className}>
    <span className={'edit'}>Edit</span>
    <span className={'delete'}>Delete</span>
    <ArrowRight />
  </div>
))`
  width: 100%;
  color: #5abdd7;
  font-weight: 500;
  grid-gap: 0.5rem;
  display: grid;
  align-items: center;
  grid-auto-flow: column;

  .delete {
    color: #ed6c78;
  }
`;

export const defSortIcons = () => (
  <span>
    <i className="sortIcon -up fa fa-chevron-up" aria-hidden="true" />
    <i className="sortIcon -down fa fa-chevron-down" aria-hidden="true" />
  </span>
);

export const paginationBtnPrev = () => (
  <span>
    <i className="fa fa-chevron-left" aria-hidden="true" />
  </span>
);

export const paginationBtnNext = () => (
  <span>
    <i className="fa fa-chevron-right" aria-hidden="true" />
  </span>
);

export const renderTHeaderTitle = ({
  title,
  sort = false,
  myClassName = '-tCenter',
  sortIcons = defSortIcons(),
  isText = true,
}) => (
  <div className={clsx(isText && 'textCell', myClassName)}>
    <span>{title}</span>
    {sort && sortIcons}
  </div>
);

export const cellRender = ({ title, isText = true }) => props => (
  <div className={clsx(isText && 'textCell', title)}>{props.value}</div>
);
export const cellRenderDate = ({ title }) => props => (
  <div className={title}>
    {props.value && moment(props.value).format('DD-MMM-YY')}
  </div>
);

export const cellCheckbox = ({ value }) => {
  return (
    <div className={''}>
      <Checkbox
        checked={value}
        color="primary"
        inputProps={{ 'aria-label': 'secondary checkbox' }}
      />
    </div>
  );
};

export const onPageChange = ({ defPageSize, setOffset }) => props => {
  setOffset(props * defPageSize);
  return props;
};

export const handlePagination = ({
  sortOrder,
  sortBy,
  setSort,
  defPageSize,
  offset,
  total,
}) => props => {
  const { id, desc } = props.sorted[0];

  if (sortBy !== id || sortOrder !== desc) {
    setSort({
      by: id,
      order: desc,
    });
  }

  if (total > offset + defPageSize) {
    props.canNext = true;
  }
  if (offset > 0) {
    props.canPrevious = true;
  }

  if (total >= defPageSize) {
    props.page = offset / defPageSize;
  } else {
    props.page = 0;
  }

  if (total) {
    props.pages = Math.floor(total / defPageSize);
    if (total % defPageSize > 0) {
      props.pages = props.pages + 1;
    }
  }

  return props;
};

export const nextText = () => (
  <span>
    <i className="fa fa-chevron-right" aria-hidden="true" />
  </span>
);

export const prevText = () => (
  <span>
    <i className="fa fa-chevron-left" aria-hidden="true" />
  </span>
);

export const expiredTimeFormat = (expiredInRaw, days = true) => {
  return expiredInRaw > 23
    ? `${Math.floor(expiredInRaw / (24 * 3600))}${days ? 'd' : ''}`
    : `${expiredInRaw}h`;
};

export const formatPeriod = period => {
  let till = 0;
  let from = 0;

  switch (period) {
    case 'current_month':
      till = moment(Date.now()).unix();
      from = moment(Date.now())
        .startOf('months')
        .unix();
      break;
    case 'current_week':
      till = moment(Date.now()).unix();
      from = moment(Date.now())
        .startOf('week')
        .unix();
      break;
    case 'past_month':
      till = moment(Date.now())
        .subtract(1, 'month')
        .endOf('month')
        .unix();
      from = moment(Date.now())
        .subtract(1, 'month')
        .startOf('month')
        .unix();
      break;
    case 'all':
    default:
      till = 0;
      from = 0;
  }
  return { till, from, period };
};

export default {
  cellRender,
  cellRenderDate,
  renderTHeaderTitle,
  handlePagination,
  onPageChange,
  formatPeriod,
};
