import React, { useCallback, useContext } from 'react';
import Dashboard from './DashboardNew.jsx';
import history from 'utils/history';
import { LayoutsTemplatesContext } from 'layouts';
import styled from 'styled-components';

const HeaderDummy = styled(({ className }) => (
  <div className={className}></div>
))`
  grid-area: header;
  height: 0;
`;

function Header() {
  let HeaderComponent;
  const { state } = useContext(LayoutsTemplatesContext);
  const { pageType, pageTitle } = state;

  if (pageType) {
    switch (pageType) {
      case 'authForm':
        HeaderComponent = HeaderDummy;
        break;
      case 'dashboard':
        HeaderComponent = Dashboard;
        break;
      default:
        HeaderComponent = HeaderDummy;
        break;
    }
  }

  const handleLogoOnClick = useCallback(() => history.push('/'), []);

  const handleNavLinkClick = useCallback(path => history.push(path), []);

  return HeaderComponent ? (
    <HeaderComponent
      title={pageTitle}
      handleNavLinkClick={handleNavLinkClick}
      handleLogoOnClick={handleLogoOnClick}
    />
  ) : (
    []
  );
}

export default Header;
