import { createTheme } from '@material-ui/core/styles';

import typography from './typography';
import overrides from './overrides';
import palette from './palette';

const shape = {
  borderRadius: 0,
};

export default createTheme({
  typography,
  overrides,
  shape,
  palette,
});
