import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import ModalFormCrud, {
  modalCrudInitData,
} from '../../components/ModalFormCrud';
import {
  GridColumnsWrapper,
  LayoutAccountButtonCreate,
} from '../../components';
import LayoutAccountStyled from '../../layouts/Account';
import CoolTable from '../../../components/Table';
import { CrudButton } from '../../../components/Table/utils';
import { apiCallWrapper, get, post } from '../../utils/fetch';
import { useDispatch, useSelector } from 'react-redux';
import endpoints from '../../config/endpoints';
import { toast } from 'react-toastify';
import { selectPermissions } from '../../../store/selectors/profile';
import * as math from 'mathjs'

const tableColumns = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Period',
    accessor: 'period',
  },
  {
    Header: 'Formula',
    accessor: 'formula',
  },
  {
    width: 120,
    Cell: () => (
      <div className={'displayOnHover'}>
        <CrudButton />
      </div>
    ),
  },
];
const period = [
  { label: 'Monthly', value: 'monthly' }
]

const affordabilitiesFormFields = (categories, subCategories) => [
  {
    name: 'name',
    type: 'text',
    label: 'Name',
  },
  {
    name: 'period',
    type: 'select',
    label: 'Period',
    initValue: period
  },
  {
    name: 'formula',
    type: 'area',
    label: "Construct your custom affordability calculation using categories below & standard mathematical operators.",
    tooltip: "Allowed operators includes +, -, *, /, (, )"
  },
  {
    name: 'categories',
    type: 'sublist',
    label: 'Categories & SubCategories',
    initValue: categories
  }
];
// const mapSelectCategories = categories => {
//   let categoryList = [];
//   if (categories) {
//     Object.keys(categories).forEach(element => {
//       let result = categories[element]
//       categoryList.push({ value: result, label: result })
//     })
//   }
//   return categoryList;
// }

// const mapSelectSubCategories = (categories, subCategories) => {
//   let subCategoryList = [];
//   if (categories) {
//     Object.keys(categories).forEach(element => {
//       let result = categories[element]
//       subCategories[element].forEach(item => {
//         console.log("value: " +item.key +" label: " +result +"." +item.key)
//         subCategoryList.push({ value: item.key, label: item.key })
//       })
//     })
//   }
//   return subCategoryList;
// }

const mapCategoriesSubCategories = (categories, subCategories) => {
  let categoryList = [];
  if (categories) {
    Object.keys(categories).forEach(element => {
      let result = categories[element]
      categoryList.push({ value: result, label: result, nestedValues: subCategories[element]})
    })
  }
  return categoryList;
}

export const useGetCategories = (update, setIsProcessing) => {
  const [categories, setCategories] = useState({});
  const [subCategories, setSubCategories] = useState({});
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const url = endpoints.customer.categoriesRead;

  useEffect(() => {
    apiCallWrapper({
      onStart: () => {
        dispatch({ type: 'GET_CATEGORIES_START' });
        setIsProcessing(true);
      },
      apiCall: () => get(url),
      onFinish: resp => {
        dispatch({ type: 'GET_CATEGORIES_FINISH', payload: resp });
        setCategories({ ...resp.categories });
        setSubCategories({ ...resp.sub_categories });
        setIsProcessing(false);
      },
      onFail: error => {
        dispatch({ type: 'GET_CATEGORIES_FAIL', error });
        setIsProcessing(false);
        setError(error);
      },
    });
  }, [update, dispatch, setIsProcessing, url]);

  return [categories, subCategories, error];
};
export const useGetAffordabilities = (update, setIsProcessing) => {
  const [affordabilities, setAffordabilities] = useState([undefined]);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const url = endpoints.customer.affordabilities;

  useEffect(() => {
    apiCallWrapper({
      onStart: () => {
        dispatch({ type: 'GET_AFFORDABILITIES_START' });
        setIsProcessing(true);
      },
      apiCall: () => get(url),
      onFinish: resp => {
        dispatch({ type: 'GET_AFFORDABILITIES_FINISH', payload: resp.affordabilities });
        setAffordabilities([resp.affordabilities]);
        setIsProcessing(false);
      },
      onFail: error => {
        dispatch({ type: 'GET_AFFORDABILITIES_FAIL', error });
        setIsProcessing(false);
        setError(error);
      },
    });
  }, [update, dispatch, setIsProcessing, url]);

  return [affordabilities, error];
};

const validateFormula = (expression, categories, subCategories) => {
  let scope = {};
  let count = 0;
  let message = "";
  if (categories) {
    Object.keys(categories).forEach(element => {
      scope[categories[element]]= count++ 
    })
  }
  if (categories && subCategories) {
    Object.keys(categories).forEach(element => {
      subCategories[element].forEach(item => {
        scope[categories[element]+item.key] = count++
      })
    })
  }
  try {
    const code = math.compile(expression.replaceAll("__",""))
    code.evaluate(scope)
    message = "success"
  }
  catch (error) {
    message = error.message
  }
  finally {
    return message
  }
}

const addAffordability = ({
  values,
  categories,
  subCategories,
  handleOnFinish,
  handleOnFail,
  handleOnStart,
  dispatch,
}) => {
  const url = endpoints.customer.affordabilities;
  const message = validateFormula(values.formula, categories, subCategories)
  if (message === "success") {
    return apiCallWrapper({
      onStart: () => {
        dispatch({ type: 'ADD_AFFORDABILITIES_START' });
        handleOnStart();
      },
      apiCall: () => post(url, values),
      onFinish: resp => {
        dispatch({ type: 'ADD_AFFORDABILITIES_FINISH', payload: resp });
        toast.success('New affordability added successfully');
        handleOnFinish(resp);
      },
      notify: true,
      onFail: error => {
        dispatch({ type: 'ADD_AFFORDABILITIES_FAIL', error });
        handleOnFail();
      },
    });
  }
  else
    return toast.error(message)
};

const deleteAffordability = ({
  values,
  handleOnFinish,
  handleOnFail,
  handleOnStart,
  dispatch,
}) => {
  const url = endpoints.customer.affordabilitiesDelete;
  return apiCallWrapper({
    onStart: () => {
      dispatch({ type: 'DELETE_AFFORDABILITIES_START' });
      handleOnStart();
    },
    apiCall: () => post(url, values),
    onFinish: resp => {
      dispatch({ type: 'DELETE_AFFORDABILITIES_FINISH', payload: resp });
      toast.success('Affordability deleted successfully');
      handleOnFinish(resp);
    },
    notify: true,
    onFail: error => {
      dispatch({ type: 'DELETE_AFFORDABILITIES_FAIL', error });
      handleOnFail();
    },
  });
};

const valuesMapper = values => {
  return {
    id: values.id,
    period: values.period,
    name: values.name,
    formula: values.formula,
    categories: values.categories
  };
};

const updateAffordability = ({
  values,
  categories,
  subCategories,
  handleOnFinish,
  handleOnFail,
  handleOnStart,
  dispatch,
}) => {
  const url = endpoints.customer.affordabilitiesUpdate;
  const message = validateFormula(values.formula, categories, subCategories)
  if (message === "success") {
    return apiCallWrapper({
      onStart: () => {

        dispatch({ type: 'UPDATE_AFFORDABILITIES_START' });
        handleOnStart();

      },
      apiCall: () => post(url, values),
      onFinish: resp => {
        dispatch({ type: 'UPDATE_AFFORDABILITIES_FINISH', payload: resp });
        toast.success('Affordability updated successfully');
        handleOnFinish(resp);
      },
      onFail: error => {
        dispatch({ type: 'UPDATE_AFFORDABILITIES_FAIL', error });
        handleOnFail();
      },
    });
  }
  else
    toast.error('Enter the valid formula')
};


const Affordabilities = ({ className }) => {
  const [modalCurd, setModalCurd] = useState(modalCrudInitData);
  const [update, setUpdate] = useState(0);
  const [isProcessing, setIsProcessing] = useState(true);
  const dispatch = useDispatch();
  const [categories, subCategories] = useGetCategories(update, setIsProcessing);
  const [[affordabilities]] = useGetAffordabilities(update, setIsProcessing);
  const permissions = useSelector(selectPermissions);

  const handleOnFinish = () => {
    setIsProcessing(false);
    setUpdate(update => update + 1);
    setModalCurd({ ...modalCrudInitData });
  };
  const handleOnStart = () => {
    setIsProcessing(true);
  };
  const handleOnFail = () => {
    setIsProcessing(false);
  };
  return (
    <div className={className}>
      <LayoutAccountStyled>
        <div className={clsx('row', 'row-top')}>
          <h2>Calculations</h2>
          <GridColumnsWrapper>
            <LayoutAccountButtonCreate
              disabled={!permissions.can_edit_affordability}
              setModalCrud={() =>
                setModalCurd({
                  ...modalCrudInitData,
                  isOpen: true,
                  onSubmit: values =>
                    addAffordability({
                      values,
                      categories,
                      subCategories,
                      dispatch,
                      handleOnFail,
                      handleOnFinish,
                      handleOnStart,
                    }),
                  formFieldsMarkup: affordabilitiesFormFields(
                    mapCategoriesSubCategories(categories, subCategories)
                  ),
                  title: 'Add',
                })
              }
            />
          </GridColumnsWrapper>
        </div>
        <CoolTable
          pageSize={5}
          data={affordabilities}
          loading={!affordabilities || isProcessing}
          columns={tableColumns}
          showPagination={false}
          getTrGroupProps={(state, rowInfo, column, instance) => {
            if (!permissions.can_edit_affordability) {
              return {
                onClick: () => {
                  toast.error('You do not have such permission');
                  return null;
                },
              };
            }

            return {
              onClick: () => {
                if (!rowInfo) return null;
                const modalProps = {
                  deleteConfirmMessage: undefined,
                  isDeleteButtonActive: true,
                };
                setModalCurd({
                  isOpen: true,
                  onSubmit: values =>
                    updateAffordability({
                      values: valuesMapper(values),
                      categories,
                      subCategories,
                      dispatch,
                      handleOnFail,
                      handleOnFinish,
                      handleOnStart,
                    }),
                  onDelete: values => {
                    return deleteAffordability({
                      values: { id: values.id },
                      dispatch,
                      handleOnFail,
                      handleOnFinish,
                      handleOnStart,
                    });
                  },
                  data: rowInfo.original,
                  formFieldsMarkup: affordabilitiesFormFields(
                    mapCategoriesSubCategories(categories, subCategories)
                  ),
                  title: rowInfo.original.name,
                  isDelete: true,
                  ...modalProps,
                });
              },
            };
          }}
        />
        {modalCurd.isOpen && (
          <ModalFormCrud
            loading={isProcessing}
            onClose={() => setModalCurd({ isOpen: false })}
            {...modalCurd}
          />
        )}
      </LayoutAccountStyled>
    </div>
  );
};

export default Affordabilities;
