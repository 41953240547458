import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import Header from './Header';
import Footer from '@src/components/Footer';
import styles from './styles';
import { LayoutsTemplatesContext } from 'layouts';
import Preloader from '../../components/Preloader';

const useStylesLayout = makeStyles(styles.common);
const useStylesAuthForm = makeStyles(styles.byPageTypes.authForm);

const AppDesktop = ({ children }) => {
  const classesLayout = useStylesLayout();
  const classesAuthForm = useStylesAuthForm();
  const { state } = useContext(LayoutsTemplatesContext);
  const { pageType, isProcessing } = state;

  let classesOverride = {};

  if (pageType) {
    switch (pageType) {
      case 'authFormSimple':
      case 'authForm':
        classesOverride = {
          root: classesAuthForm.root,
          header: classesAuthForm.header,
          footer: classesAuthForm.footer,
          main: classesAuthForm.main,
        };
        break;
      case 'dashboard':
        classesOverride = {};
        break;
      default:
        classesOverride = {};
        break;
    }
  }

  return isProcessing ? (
    <Preloader />
  ) : (
    <div
      className={clsx(
        classesOverride.root || classesLayout.root,
        'layout_desktop_root',
      )}
    >
      <Header
        className={clsx(
          classesOverride.header || classesLayout.header,
          'layout_desktop_header',
        )}
      />
      <div
        className={clsx(
          classesOverride.main || classesLayout.main,
          'layout_desktop_main',
        )}
      >
        {children}
      </div>
      <div
        className={clsx(
          classesOverride.footer || classesLayout.footer,
          'layout_desktop_footer',
        )}
      >
        <Footer />
      </div>
    </div>
  );
};

export default AppDesktop;
