import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiModal from '@material-ui/core/Modal';
import { Grid } from '@material-ui/core';
import { apiCallWrapper, post } from '@src/utils/fetch';
import Form from 'components/Form';
import { useDispatch, useSelector } from 'react-redux';
import appConfig from 'config';
import crossIcon from 'assets/images/cross.png';
import { toast } from 'react-toastify';
import { handleMessages } from 'utils/messages';
import styles from './styles';
import { onProfileBalanceUpdate } from '../../../../store/actions/profile';
import { selectBalance } from '../../../../store/selectors/profile';
import { applyValidatorPattern } from '../../../../utils/validators';
import styled from 'styled-components';
import { selectUserData } from '../../../../store/selectors/profile';

const FormStyled = styled(Form)`
  form {
    .fieldsWrapper {
      display: grid;
      grid-auto-flow: row;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1.5rem;
      margin-bottom: 3rem;
    }
  }
`;

const defValidators = [applyValidatorPattern('maxLength', 50)];

const fieldsMarkup = (balance, redirectFlow) => ([
  {
    name: 'type',
    type: 'select',
    label: 'Authentication Period',
    initValue: [
      {
        value: '30',
        label: '30 days',
      },
      {
        value: '90',
        label: '90 days',
      },
    ],
    validators: [applyValidatorPattern('checkBalance', balance)],
    tooltip: '30 days - 1 credit; 90 days - 3 credits;',
  },
  {
    name: 'salutation',
    type: 'select',
    label: 'Salutation',
    initValue: [
      {
        value: 'mr',
        label: 'Mr.',
      },
      {
        value: 'mrs',
        label: 'Mrs.',
      },
      {
        value: 'miss',
        label: 'Miss.',
      },
      {
        value: 'ms',
        label: 'Ms.',
      },
      {
        value: 'dr',
        label: 'Dr.',
      },
      {
        value: 'prof',
        label: 'Prof.',
      },
      {
        value: 'rev',
        label: 'Rev.',
      },
      {
        value: 'mx',
        label: 'Mx.',
      }
    ],
  },
  {
    name: 'email',
    type: 'email',
    label: 'Enter PSU Email',
  },
  {
    name: 'first_name',
    type: 'text',
    label: 'First Name',
  },
  {
    name: 'last_name',
    type: 'text',
    label: 'Last Name',
  },
  {
    name: 'birth_date',
    type: 'birth_date',
    label: 'Birth Date',
  },
  {
    name: 'phone_number',
    type: 'phone',
    label: 'Phone',
  },
  {
    name: 'postcode',
    type: 'text',
    label: 'Postcode',
    validators: [
      applyValidatorPattern('isRequired'),
      applyValidatorPattern('minLength', 1),
    ],
  },
  {
    name: 'address_line_1',
    type: 'text',
    label: 'Address Line 1',
    validators: [
      ...defValidators,
      applyValidatorPattern('isRequired'),
      applyValidatorPattern('minLength', 1),
    ],
  },
  {
    name: 'address_line_2',
    type: 'text',
    label: 'Address Line 2',
    validators: [...defValidators],
  },
  {
    name: 'town',
    type: 'text',
    label: 'Town',
    validators: [
      ...defValidators,
      applyValidatorPattern('isRequired'),
      applyValidatorPattern('minLength', 1),
    ],
  },
  {
    name: 'county',
    type: 'text',
    label: 'County',
    validators: [...defValidators],
  },
  // {
  //   displayIfCheckboxTrue: 'business',
  //   name: 'company_name',
  //   type: 'text',
  //   label: 'Company Name',
  //   validationGroup: 'company_name',
  // },
  // {
  //   displayIfCheckboxTrue: 'business',
  //   name: 'company_number',
  //   type: 'text',
  //   label: 'Company Number',
  //   validationGroup: 'company_number',
  // },
  // {
  //   name: 'business',
  //   type: 'checkbox',
  //   label: 'Is business',
  // },
    {
      hidden: redirectFlow === 'dynamic' ? false : true,
      name: 'redirect_url',
      type: 'text',
      label: 'Redirect Url',
      validators: [applyValidatorPattern('url', redirectFlow)],
      default:''
    },
    {
      name: 'reminder',
      type: 'checkbox',
      label: 'Send reminder before consent is expired',
    }
]);

const endpoint = [appConfig.apiUrl, appConfig.ENDPOINTS.CONSENT_ADD].join('/');

const useStyles = makeStyles(styles);

function Modal({
  setIsTableProcessing,
  setTableForceUpdate,
  open,
  data,
  title,
  handleClose,
}) {
  const classes = useStyles();
  const userData = useSelector(selectUserData);
  const redirectFlow = userData && userData.company.redirect_flow;
  const { customer } = useSelector(state => state.profile);
  const [free, prepaid] = useSelector(selectBalance);
  const [isProcessing, setIsProcessing] = useState(false);
  const dispatch = useDispatch();
  const balance = free + prepaid;

  const handleOnSubmit = values => {
    const formattedBirthDate = values.birth_date.split('/').reverse().join('-');

    const fetch = async () => {
      try {
        apiCallWrapper({
          onStart: () => setIsProcessing(true),
          onAny: () => setIsProcessing(false),
          apiCall: () =>
            post(endpoint, {
              customer_id: customer.id,
              psu_email: values.email,
              exp_notification: values.reminder,
              business: values.business,
              data_visibility_lifetime_days: parseInt(values.type),
              psu_info: {
                salutation: values.salutation,
                first_name: values.first_name,
                last_name: values.last_name,
                postcode: values.postcode,
                address_line_1: values.address_line_1,
                address_line_2: values.address_line_2,
                town: values.town,
                county: values.county,
                birth_date: formattedBirthDate,
                phone_number: values.phone_number,
                company_name: values.company_name,
                company_number: values.company_number,
              },
              redirect_url: values.redirect_url,
            }),
          onFinish: () => {
            toast.success(handleMessages('identityCheckSendSuccess'));
            dispatch(onProfileBalanceUpdate());
          },
          onFail: e => {
            console.error(
              `Error while trying to send new consent request to the PSU: ${values.email}`,
            );
          },
        });
      } catch (e) {
        toast.error(e);
      }

      handleClose();
      setIsTableProcessing();
      setTimeout(() => {
        setTableForceUpdate();
      }, 2000);
    };

    fetch().then(() => {});
  };

  return (
    <MuiModal
      aria-labelledby="modal-title"
      aria-describedby="modal-body"
      open={open}
      title = {title}
      onClose={handleClose}
    >
      <div className={classes.modal}>
        <div onClick={handleClose}>
          <img alt="close-icon" src={crossIcon} className={'closeBtn'} />
        </div>
        <h2 id="modal-title">
          <span className={'innerText'}>{title}</span>
        </h2>
        <div id="modal-body">
          <Grid item xs={12}>
            <FormStyled
              loading={isProcessing}
              submitBtnLabel={'Send Request'}
              handleOnSubmit={handleOnSubmit}
              initValues={data}
              fieldsMarkup={fieldsMarkup(balance, redirectFlow)}
            />
          </Grid>
        </div>
      </div>
    </MuiModal>
  );
}

export default Modal;
