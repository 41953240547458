import React from 'react';
import CommonWrapper from './Common';
import endpoints from '../../config/endpoints';

const Prod = () => {
  return (
    <CommonWrapper
      type={'prod'}
      title={'Production'}
      apiUrl={{
        apiGenerate: endpoints.dev.production.generate,
        apiDetails: endpoints.dev.production.details,
        apiDelete: endpoints.dev.production.delete,
      }}
    />
  );
};

export default Prod;
